import { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/id";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import css from '../../../assets/css/miniCalendar.module.css'
import { Grid, Card, Button } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";

const DatePickerSchedule = ({ picked, setPicked }) => {
    const theme = useSelector(state => state.theme.myTheme)

    const now = dayjs().locale({
        ...locale,
    });

    dayjs.extend(weekdayPlugin);
    dayjs.extend(objectPlugin);
    dayjs.extend(isTodayPlugin);

    const [currentMonth, setCurrentMonth] = useState(now);
    const [arrayOfDays, setArrayOfDays] = useState([]);

    const nextMonth = () => {
        const plus = currentMonth.add(1, "month");

        setCurrentMonth(plus);
    };

    const prevMonth = () => {
        const minus = currentMonth.subtract(1, "month");

        setCurrentMonth(minus);
    };

    const renderHeader = () => {
        const dateFormat = "MMMM YYYY";

        return (
            <div className="d-flex align-center justify-space-between mb-3">
                <p className='small_txt font-weight-bold mx-2'>{currentMonth.format(dateFormat)}</p>
                <div className="d-flex align-center">
                    <Button className='capitalize pa-1 mr-2' variant="outlined" style={{ minWidth: '5px' }} onClick={() => prevMonth()}>
                        <ChevronLeft fontSize="small" color='primary' />
                    </Button>
                    <Button className='capitalize pa-1' variant="outlined" style={{ minWidth: '5px' }} onClick={() => nextMonth()}>
                        <ChevronRight fontSize="small" color='primary' />
                    </Button>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const dateFormat = "ddd";
        const days = [];

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className={`${css.col} ${css['col-center']}`} key={i}>
                    {now.weekday(i).format(dateFormat)}
                </div>
            );
        }
        return <div className={`${css.days} ${css.row} small_txt font-weight-bold`} >{days}</div>;
    };


    const getAllDays = () => {
        let currentDate = currentMonth.startOf("month").weekday(0);
        const nextMonth = currentMonth.add(1, "month").month();

        let allDates = [];
        let weekDates = [];

        let weekCounter = 1;

        while (currentDate.weekday(0).toObject().months !== nextMonth) {
            const formated = formateDateObject(currentDate);

            weekDates.push(formated);
            if (weekCounter === 7) {

                allDates.push({ dates: weekDates });
                weekDates = [];
                weekCounter = 0;
            }

            weekCounter++;
            currentDate = currentDate.add(1, "day");
        }
        setArrayOfDays(allDates);
    };

    useEffect(() => {
        getAllDays();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonth]);

    const renderCells = () => {
        const rows = [];
        let days = [];

        arrayOfDays.forEach((week, index) => {
            week.dates.forEach((d, i) => {
                let pickedDate = picked.includes(d.date)
                days.push(
                    <div
                        className={`${css.col} ${css.cell} ${!d.isCurrentMonth ? css.disabled : ''}`}
                        style={{ background: pickedDate ? theme.primary : '#fff', color: pickedDate ? '#fff' : theme.primary }}
                        key={i}
                        onClick={() => selectDate(d)}
                    >
                        <div className={css.number} style={{ borderBottom: d.isCurrentDay ? `3px solid ${theme.lighten}` : 'none' }}>{d.day}</div>
                    </div>
                );
            });
            rows.push(
                <div className={css.row} key={index}>
                    {days}
                </div>
            );
            days = [];
        });

        return <div className={css.body}>{rows}</div>;
    };
    // const [picked, setPicked] = useState([])

    const selectDate = (day) => {
        let date = day.date
        if (picked.includes(date)) {
            let spliced = [...picked]
            const index = spliced.indexOf(date);
            if (index > -1) {
                spliced.splice(index, 1);
            }
            setPicked(spliced)
        } else {
            setPicked([
                ...picked, date
            ])
        }
    }

    const formateDateObject = date => {
        const temp = { ...date.toObject() };
        const formatedObject = {
            day: temp.date,
            month: temp.months,
            year: temp.years,
            isCurrentMonth: temp.months === currentMonth.month(),
            isCurrentDay: date.isToday(),
            date: `${temp.years}-${temp.months < 9 ? `0${temp.months + 1}` : temp.months + 1}-${temp.date < 10 ? `0${temp.date}` : temp.date}`
        };

        return formatedObject;
    };

    return (
        <div>
            {renderHeader()}
            {renderDays()}
            <div className={css.calendar}>
                {renderCells()}
            </div>
        </div>
    );
};

export default DatePickerSchedule;