/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getApi } from '../../../../../store/apis'
import { CHANGE_TITLE } from '../../../../../store/theming'
import InfoTrainingPage from './InfoTrainingPage'
import ListPesertaTraining from './listPesertaTraining'

const DetailTrainingPage = () => {
    const theme = useSelector((state) => state.theme.myTheme);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tab, setTab] = useState(1)
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [emptyTxt, setEmptyTxt] = useState("");
    const [dataSet, setData] = useState(null);
    const fetchData = () => {
        setLoading(true);
        let data = {
            path: `admin/training?filter[id]=${id}`,
        };
        dispatch(getApi(data))
            .then((data) => {
                setLoading(false);
                if (data.data.length) {
                    let raw = JSON.parse(JSON.stringify(data.data[0]))
                    raw.kategori_peserta.forEach((peserta) => {
                        raw.harga_detail.forEach((harga) => {
                            if (harga.training_kategori_peserta_id == peserta.training_kategori_peserta_id) {
                                harga['nama'] = peserta.kategori.nama
                            }
                        })
                    })
                    setData(raw);
                }
            })
            .catch((err) => {
                if (err.status == 400) {
                    setEmptyTxt(`No Data Available`);
                } else {
                    setEmptyTxt(`${err.status}: ${err.data.message}`);
                }
                setLoading(false);
                setData(null);
            });
    };

    useEffect(() => {
        dispatch(CHANGE_TITLE('Detail Training'))
        fetchData()
    }, [])
    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg  mb-3"
                onClick={() => navigate('/training-activity')}
            >
                <ChevronLeft /> Kembali
            </Button>
            <div className="d-flex mt-2"
            >
                <Button variant="contained" className='mr-3 rounded-lg'
                    color={tab == 1 ? 'primary' : 'white'}
                    disableElevation
                    onClick={() => setTab(1)}
                >
                    <p className="font-weight-bold capitalize" style={{ color: tab == 1 ? 'white' : theme.primary }}>
                        Informasi Training
                    </p>
                </Button>
                <Button variant="contained" className='mr-3 rounded-lg'
                    color={tab == 2 ? 'primary' : 'white'}
                    disableElevation
                    onClick={() => setTab(2)}
                >
                    <p className="font-weight-bold capitalize" style={{ color: tab == 2 ? 'white' : theme.primary }}>
                        List Peserta
                    </p>
                </Button>
            </div>
            <div className="mt-3">
                {tab == 1 ? <InfoTrainingPage dataSet={dataSet} theme={theme} loading={loading} />
                    : <ListPesertaTraining id={id} />}
            </div>
        </div>
    )
}

export default DetailTrainingPage
