/* eslint-disable eqeqeq */
import {
  Button,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Checkbox,
  Switch,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import "react-datepicker/dist/react-datepicker.css";
import { Close as CloseIcon } from "@mui/icons-material";
import day from "dayjs";
import { postApi, getApi } from "../../../../store/apis";
import { useSnackbar } from "notistack";
import FlatInput from "../../../../components/base/flatInput";
import FlatArea from "../../../../components/base/flatArea";
import FlatDateTimePicker from "../../../../components/base/flatDateTimePicker";

import {
  kriteria,
  buttonVisible,
  defaultValueButton,
  action,
  customStyles,
  repeatType,
  arrDay,
} from "./constant";

const FormCreatePopup = ({ setLoading, tabActive, setTabActive }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dp, setDp] = useState("");
  const [arrDate, setArrDate] = useState([]);
  const [dataKategori, setDataKategori] = useState([]);
  const [detailFitur, setDetailFitur] = useState({
    0: [],
    1: [],
  });
  const [idPopup, setIdPopup] = useState("");
  const theme = useSelector((state) => state.theme.myTheme);
  const [form, setForm] = useState({
    title: "",
    gambar: "",
    caption: "",
    keterangan: "",
    kriteria: [],
    button_close: 0,
    type: "",
    button_visibility: "",
    promo_button: [],
  });

  const [formPub, setFormPub] = useState({
    start_promo: "",
    exp_promo: "",
    repeat_type: "",
    repeat_every: "",
    repeat_on: [],
  });

  const grid = { left: 3.5, right: 8.5 };

  const renderImg = (e) => {
    const file = e.target.files[0];
    const fr = new FileReader();
    fr.onload = (f) => {
      setDp(f.target.result);
    };
    setForm({ ...form, gambar: file });
    fr.readAsDataURL(file);
  };

  const onChangeValue = (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;
    if (tabActive === "Detail Pop Up") {
      setForm({ ...form, [prop]: value });
    } else {
      setFormPub({ ...formPub, [prop]: value });
    }
  };

  const requestButton = (prop, value) => {
    if (prop === "button_visibility") {
      const initial =
        value === "single"
          ? [defaultValueButton]
          : value === "vertical_double" || value === "horizontal_double"
          ? [defaultValueButton, defaultValueButton]
          : [];

      onChangeValue("promo_button")(initial);
    }
  };

  const handleCheck = (prop, item) => (event) => {
    const { checked } = event.target;
    if (tabActive === "Detail Pop Up") {
      if (checked) {
        const value = [...form[prop], item];
        setForm({ ...form, [prop]: value });
      } else {
        let value = [...form[prop]];
        value = value.filter((val) => val !== item);
        setForm({ ...form, [prop]: value });
      }
    } else {
      if (checked) {
        const value = [...formPub[prop], item];
        setFormPub({ ...formPub, [prop]: value });
      } else {
        let value = [...formPub[prop]];
        value = value.filter((val) => val !== item);
        setFormPub({ ...formPub, [prop]: value });
      }
    }
  };

  const changeCustomizeButton = (index) => (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;

    let state = [...form.promo_button];
    state[index] = { ...state[index], [prop]: value };

    if (prop === "kategori") {
      getDetailFitur(value.value, index);
    }

    onChangeValue("promo_button")(state);
  };

  const labelFormatter = (str) => {
    const name = str.charAt(0).toUpperCase() + str.slice(1);
    return name.replace(/_/g, " ");
  };

  const store = () => {
    setLoading(true);
    const body = new FormData();
    body.append("title", form.title);
    body.append("gambar", form.gambar);
    body.append("caption", form.caption);
    body.append("keterangan", form.keterangan);
    body.append("kriteria", JSON.stringify(form.kriteria));
    body.append("button_close", form.button_close);
    body.append("button_visibility", form.button_visibility);

    let button = [...form.promo_button];
    let customButton = [];
    if (button.length > 0) {
      button.forEach((el) => {
        let value = {
          ...el,
          action: el.action.value,
        };
        if (value.action === "feature") {
          value = {
            ...value,
            id_ref: el.kategori.value,
            type_ref: el.detailFitur.value,
          };
          delete value.kategori;
          delete value.detailFitur;
        }
        customButton.push(value);
      });
    }

    body.append("promo_button", JSON.stringify(customButton));

    let data = {
      path: "admin/advertising/popup/store",
      body: body,
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
        setIdPopup(res.data.id);
        setTabActive("Jadwal Publish");
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
        setLoading(false);
      });
  };

  const getAllDaysInMonth = () => {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();

    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(day(new Date(date)).format("DD"));
      date.setDate(date.getDate() + 1);
    }

    setArrDate(dates);
  };

  const getKategori = () => {
    let data = {
      path: `admin/advertising/fitur/kategori`,
    };
    dispatch(getApi(data)).then((data) => {
      let kategori = [];
      data.forEach((el) => {
        kategori.push({
          value: el.type,
          label: el.title,
        });
      });
      setDataKategori(kategori);
    });
  };

  const getDetailFitur = (type, index) => {
    let data = {
      path: `admin/advertising/fitur/detail-kategori?type=${type}`,
    };
    dispatch(getApi(data)).then((data) => {
      let detail = [];
      data.forEach((el) => {
        detail.push({
          value: el.id,
          label:
            el.nama_psikotes ??
            el.nama_konsultasi ??
            el.nama_kelas ??
            el.judul ??
            el.modul ??
            el.game ??
            el.poin,
        });
      });

      const body = {
        ...detailFitur,
        [index]: detail,
      };

      setDetailFitur(body);
    });
  };

  const createPublish = () => {
    setLoading(true);

    let data = {
      path: "admin/advertising/popup/jadwal/store",
      body: {
        ...formPub,
        id: idPopup,
        repeat_type: formPub.repeat_type?.value,
        repeat_on: JSON.stringify(formPub.repeat_on),
      },
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
        navigate(-1);
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
        setLoading(false);
      });
  };

  useEffect(() => {
    getKategori();
    getAllDaysInMonth();
    if (form.button_visibility) {
      requestButton("button_visibility", form.button_visibility);
    }
    if (formPub.repeat_type) {
      setFormPub({ ...formPub, repeat_on: [] });
    }
  }, [form.button_visibility, formPub.repeat_type]);

  return (
    <div>
      {tabActive === "Detail Pop Up" ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper elevation={0} className="pa-5 rounded-lg mb-3">
              <div className="mb-3">
                <p
                  className="mb-3 font-weight-500"
                  style={{ color: theme.primary }}
                >
                  Detail Pop Up
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Judul Pop Up
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatInput
                      value={form.title}
                      handleChange={onChangeValue}
                      property="title"
                      placeholder="Tuliskan judul pop up"
                    />
                  </Grid>

                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Gambar Pop Up
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    {form.gambar && (
                      <p className="small_txt">{form.gambar.name}</p>
                    )}
                    <Button
                      variant="outlined"
                      className="capitalize"
                      component="label"
                    >
                      <p className="font-weight-bold">
                        {dp ? "Perbaharui" : "Unggah Gambar"}
                      </p>
                      <input
                        hidden
                        onChange={renderImg}
                        accept="image/*"
                        multiple
                        type="file"
                      />
                    </Button>
                    <p className="small_txt">
                      Unggah gambar dengan dimensi 720 x 890 px
                    </p>
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Caption Pop Up
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatArea
                      value={form.caption}
                      handleChange={onChangeValue}
                      property="caption"
                      placeholder="Tuliskan caption pop up"
                    />
                    <p className="small_txt">
                      Deskripsi ini akan muncul di bawah pop up
                    </p>
                  </Grid>

                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Keterangan Pop Up
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatInput
                      value={form.keterangan}
                      handleChange={onChangeValue}
                      property="keterangan"
                      placeholder="Tuliskan keterangan pop up"
                    />
                    <p className="small_txt">
                      Catatan atau keterangan terkait pop up
                    </p>
                  </Grid>

                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Kriteria User
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    {kriteria.map((name, i) => (
                      <div className="d-flex flexy" key={i}>
                        <FormControlLabel
                          label={
                            <p className="small_txt">{labelFormatter(name)}</p>
                          }
                          control={
                            <Checkbox
                              checked={form.kriteria.includes(name)}
                              onChange={handleCheck("kriteria", name)}
                            />
                          }
                        />
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </div>
              <div className="mb-3">
                <p
                  className="mb-3 font-weight-500"
                  style={{ color: theme.primary }}
                >
                  Button Customize
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Close Button
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <Switch
                      checked={form.button_close ? true : false}
                      onChange={(event) =>
                        onChangeValue("button_close")(
                          event.target.checked ? 1 : 0
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Pop Up Button
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <RadioGroup
                      name="rb-button_visibility"
                      value={form.button_visibility}
                      onChange={onChangeValue("button_visibility")}
                    >
                      {buttonVisible.map((item, i) => (
                        <FormControlLabel
                          key={i}
                          value={item.value}
                          control={<Radio />}
                          label={<p className="small_txt">{item.label}</p>}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                </Grid>

                {form.promo_button.map((item, i) => {
                  return (
                    <Grid container spacing={2} key={i} className="mb-3">
                      <Grid item xs={grid.left}>
                        <p
                          className="small_txt font-weight-bold"
                          style={{ color: theme.primary }}
                        >
                          Button Label
                        </p>
                      </Grid>
                      <Grid item xs={grid.right}>
                        <FlatInput
                          value={item.label}
                          handleChange={changeCustomizeButton(i)}
                          property="label"
                          placeholder="Tuliskan label button"
                        />
                      </Grid>
                      <Grid item xs={grid.left}>
                        <p
                          className="small_txt font-weight-bold"
                          style={{ color: theme.primary }}
                        >
                          Button Label Color
                        </p>
                      </Grid>

                      <Grid
                        item
                        xs={grid.right}
                        className="d-flex flex-row align-center"
                      >
                        <IconButton
                          onClick={() =>
                            changeCustomizeButton(i)("label_color")("#320071")
                          }
                        >
                          <div
                            className={`rounded-icon ${
                              item.label_color === "#320071"
                                ? "border-solid"
                                : "border-solid-light"
                            }`}
                            style={{
                              backgroundColor: "#320071",
                            }}
                          ></div>
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            changeCustomizeButton(i)("label_color")("#FFFFFF")
                          }
                        >
                          <div
                            className={`rounded-icon ${
                              item.label_color === theme.white
                                ? "border-solid"
                                : "border-solid-light"
                            }`}
                            style={{
                              backgroundColor: theme.white,
                            }}
                          ></div>
                        </IconButton>
                        <FlatInput
                          value={item.label_color}
                          handleChange={() =>
                            changeCustomizeButton(i)("label_color")
                          }
                          property="label_color"
                          placeholder="#320071"
                        />
                      </Grid>

                      <Grid item xs={grid.left}>
                        <p
                          className="small_txt font-weight-bold"
                          style={{ color: theme.primary }}
                        >
                          Button Background Color
                        </p>
                      </Grid>

                      <Grid
                        item
                        xs={grid.right}
                        className="d-flex flex-row align-center"
                      >
                        <IconButton
                          onClick={() =>
                            changeCustomizeButton(i)("bg_color")("#320071")
                          }
                        >
                          <div
                            className={`rounded-icon ${
                              item.bg_color === "#320071"
                                ? "border-solid"
                                : "border-solid-light"
                            }`}
                            style={{
                              backgroundColor: "#320071",
                            }}
                          ></div>
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            changeCustomizeButton(i)("bg_color")("#FFFFFF")
                          }
                        >
                          <div
                            className={`rounded-icon ${
                              item.bg_color === theme.white
                                ? "border-solid"
                                : "border-solid-light"
                            }`}
                            style={{
                              backgroundColor: theme.white,
                            }}
                          ></div>
                        </IconButton>
                        <FlatInput
                          value={item.bg_color}
                          handleChange={() =>
                            changeCustomizeButton(i)("bg_color")
                          }
                          property="bg_color"
                          placeholder="#320071"
                        />
                      </Grid>

                      <Grid item xs={grid.left}>
                        <p
                          className="small_txt font-weight-bold"
                          style={{ color: theme.primary }}
                        >
                          Click Action
                        </p>
                      </Grid>
                      <Grid item xs={grid.right}>
                        <Select
                          menuPlacement="auto"
                          styles={customStyles}
                          value={item.action}
                          onChange={(value) =>
                            changeCustomizeButton(i)("action")(value)
                          }
                          options={action}
                          placeholder="Pilih 1"
                          className="small_txt"
                        />
                      </Grid>
                      {form.promo_button[i].action &&
                        form.promo_button[i].action.value == "feature" && (
                          <>
                            <Grid item xs={grid.left}>
                              <p
                                className="small_txt font-weight-bold"
                                style={{ color: theme.primary }}
                              >
                                Kategori
                              </p>
                            </Grid>
                            <Grid item xs={grid.right}>
                              <Select
                                menuPlacement="auto"
                                styles={customStyles}
                                value={item.kategori}
                                onChange={(value) =>
                                  changeCustomizeButton(i)("kategori")(value)
                                }
                                options={dataKategori}
                                placeholder="Pilih 1"
                                className="small_txt"
                              />
                            </Grid>
                            <Grid item xs={grid.left}>
                              <p
                                className="small_txt font-weight-bold"
                                style={{ color: theme.primary }}
                              >
                                Detail {form.promo_button[i].kategori?.label}
                              </p>
                            </Grid>
                            <Grid item xs={grid.right}>
                              <Select
                                menuPlacement="auto"
                                styles={customStyles}
                                value={item.detailFitur}
                                onChange={(value) =>
                                  changeCustomizeButton(i)("detailFitur")(value)
                                }
                                options={detailFitur[i]}
                                placeholder="Pilih 1"
                                className="small_txt"
                              />
                            </Grid>
                          </>
                        )}
                      {form.promo_button[i].action &&
                        form.promo_button[i].action.value == "link" && (
                          <>
                            <Grid item xs={grid.left}>
                              <p
                                className="small_txt font-weight-bold"
                                style={{ color: theme.primary }}
                              >
                                Link
                              </p>
                            </Grid>
                            <Grid item xs={grid.right}>
                              <FlatInput
                                value={item.link}
                                handleChange={changeCustomizeButton(i)}
                                property="link"
                                placeholder="Tuliskan link button"
                              />
                            </Grid>
                          </>
                        )}
                    </Grid>
                  );
                })}
              </div>
              <div className="d-flex justify-end">
                <Button variant="contained" onClick={store}>
                  <p className="font-weight-bold small_txt capitalize">
                    Simpan
                  </p>
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className="rounded-lg mb-3">
              <p
                className="small_txt font-weight-500 pa-3"
                style={{ color: theme.primary }}
              >
                Preview Pop Up in Apps
              </p>
              <div
                className="pa-3 rounded-lg"
                style={{
                  backgroundColor: theme.secondary,
                }}
              >
                {dp ? (
                  <>
                    <div
                      className="d-flex flexy"
                      style={{
                        backgroundImage: dp ? `url(${dp})` : "",
                        height: "375px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div
                        className="d-flex flexy flex-column"
                        style={{
                          position: "relative",
                        }}
                      >
                        {form.button_close ? (
                          <div
                            style={{
                              width: "24px",
                              height: "24px",
                              backgroundColor: theme.white,
                              borderRadius: "12px",
                              zIndex: 1,
                              position: "absolute",
                              right: -5,
                              top: -5,
                            }}
                          >
                            <CloseIcon />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          style={{
                            flex: 0.75,
                          }}
                        ></div>
                        <div
                          className="d-flex align-center justify-center"
                          style={{
                            flex: 0.25,
                          }}
                        >
                          {form.button_visibility == "single" && (
                            <Button
                              size="small"
                              disableElevation
                              variant="contained"
                              style={{
                                backgroundColor: form.promo_button[0]?.bg_color,
                              }}
                              sx={{ borderRadius: 50 }}
                            >
                              <p
                                className="font-weight-500 small_txt capitalize"
                                style={{
                                  color: form.promo_button[0]?.label_color,
                                }}
                              >
                                {form.promo_button[0]?.label}
                              </p>
                            </Button>
                          )}
                          {form.button_visibility == "vertical_double" && (
                            <div className="d-flex flex-column mb-3 align-center">
                              <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[0]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[0]?.label_color,
                                  }}
                                >
                                  {form.promo_button[0]?.label}
                                </p>
                              </Button>
                              <div className="mt-1 mb-1" />
                              <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[1]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[1]?.label_color,
                                  }}
                                >
                                  {form.promo_button[1]?.label}
                                </p>
                              </Button>
                            </div>
                          )}
                          {form.button_visibility == "horizontal_double" && (
                            <div className="d-flex flex-row align-center mt-3">
                              <Button
                                size="small"
                                className="mr-1"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[0]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[0]?.label_color,
                                  }}
                                >
                                  {form.promo_button[0]?.label}
                                </p>
                              </Button>
                              <Button
                                size="small"
                                className="ml-1"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[1]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[1]?.label_color,
                                  }}
                                >
                                  {form.promo_button[1]?.label}
                                </p>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <p
                      className="small_txt text-center"
                      style={{
                        color: theme.white,
                      }}
                    >
                      {form.caption}
                    </p>
                  </>
                ) : (
                  <p
                    className="small_txt text-center"
                    style={{ color: theme.white }}
                  >
                    Pop Up yang akan Anda unggah akan terlihat di sini.
                  </p>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper elevation={0} className="pa-5 rounded-lg mb-3">
              <div className="mb-3">
                <p
                  className="mb-3 font-weight-500"
                  style={{ color: theme.primary }}
                >
                  Jadwal Publish
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Start Publish
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatDateTimePicker
                      value={formPub.start_promo}
                      handleChange={onChangeValue}
                      property="start_promo"
                      placeholder="Tanggal Publish"
                      min={new Date()}
                    />
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Repeat Publish
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <Select
                      menuPlacement="auto"
                      styles={customStyles}
                      value={formPub.repeat_type}
                      onChange={(value) => onChangeValue("repeat_type")(value)}
                      options={repeatType}
                      placeholder="Pilih 1"
                      className="small_txt"
                    />
                    {formPub.repeat_type && (
                      <>
                        <div className="d-flex flex-row align-center mt-3">
                          <p
                            className="small_txt font-weight-bold"
                            style={{ color: theme.primary }}
                          >
                            Every
                          </p>
                          <div
                            className="ml-2 mr-2"
                            style={{
                              width: "80px",
                            }}
                          >
                            <FlatInput
                              value={formPub.repeat_every}
                              handleChange={onChangeValue}
                              property="repeat_every"
                              placeholder="3"
                              type="number"
                            />
                          </div>
                          <p
                            className="small_txt font-weight-500"
                            style={{ color: theme.primary }}
                          >
                            hari
                          </p>
                        </div>
                        {formPub.repeat_type.value === "week" && (
                          <div className="mt-3">
                            <p
                              className="small_txt font-weight-bold"
                              style={{ color: theme.primary }}
                            >
                              Repeat On
                            </p>
                            {arrDay.map((value, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  label={
                                    <p className="small_txt capitalize">
                                      {value}
                                    </p>
                                  }
                                  control={
                                    <Checkbox
                                      checked={formPub.repeat_on.includes(
                                        value
                                      )}
                                      onChange={handleCheck("repeat_on", value)}
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                        )}
                        {formPub.repeat_type.value === "month" && (
                          <div className="mt-3">
                            <p
                              className="small_txt font-weight-bold"
                              style={{ color: theme.primary }}
                            >
                              Repeat On
                            </p>
                            <div
                              style={{
                                width: "200px",
                              }}
                            >
                              <div className="d-flex flex-row align-center flex-wrap">
                                {arrDate.map((value, i) => {
                                  return (
                                    <button
                                      className="box-date"
                                      key={i}
                                      onClick={() =>
                                        handleCheck(
                                          "repeat_on",
                                          value
                                        )({
                                          target: {
                                            checked: !formPub.repeat_on.includes(
                                              value
                                            ),
                                          },
                                        })
                                      }
                                      style={{
                                        backgroundColor: formPub.repeat_on.includes(
                                          value
                                        )
                                          ? theme.primary
                                          : "white",
                                      }}
                                    >
                                      <p
                                        className="small_txt text-center"
                                        style={{
                                          color: formPub.repeat_on.includes(
                                            value
                                          )
                                            ? theme.white
                                            : "black",
                                        }}
                                      >
                                        {parseInt(value)}
                                      </p>
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      End Repeat Publish
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatDateTimePicker
                      value={formPub.exp_promo}
                      handleChange={onChangeValue}
                      property="exp_promo"
                      placeholder="Tanggal Berakhir"
                      min={new Date()}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="d-flex justify-end">
                <Button
                  onClick={createPublish}
                  disabled={idPopup ? false : true}
                  variant="contained"
                >
                  <p className="font-weight-bold small_txt capitalize">
                    Publish Pop Up
                  </p>
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className="rounded-lg mb-3">
              <p
                className="small_txt font-weight-500 pa-3"
                style={{ color: theme.primary }}
              >
                Preview Pop Up in Apps
              </p>
              <div
                className="pa-3 rounded-lg"
                style={{
                  backgroundColor: theme.secondary,
                }}
              >
                {dp ? (
                  <>
                    <div
                      className="d-flex flexy"
                      style={{
                        backgroundImage: dp ? `url(${dp})` : "",
                        height: "375px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div
                        className="d-flex flexy flex-column"
                        style={{
                          position: "relative",
                        }}
                      >
                        {form.button_close ? (
                          <div
                            style={{
                              width: "24px",
                              height: "24px",
                              backgroundColor: theme.white,
                              borderRadius: "12px",
                              zIndex: 1,
                              position: "absolute",
                              right: -5,
                              top: -5,
                            }}
                          >
                            <CloseIcon />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          style={{
                            flex: 0.75,
                          }}
                        ></div>
                        <div
                          className="d-flex align-center justify-center"
                          style={{
                            flex: 0.25,
                          }}
                        >
                          {form.button_visibility == "single" && (
                            <Button
                              size="small"
                              disableElevation
                              variant="contained"
                              style={{
                                backgroundColor: form.promo_button[0]?.bg_color,
                              }}
                              sx={{ borderRadius: 50 }}
                            >
                              <p
                                className="font-weight-500 small_txt capitalize"
                                style={{
                                  color: form.promo_button[0]?.label_color,
                                }}
                              >
                                {form.promo_button[0]?.label}
                              </p>
                            </Button>
                          )}
                          {form.button_visibility == "vertical_double" && (
                            <div className="d-flex flex-column mb-3 align-center">
                              <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[0]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[0]?.label_color,
                                  }}
                                >
                                  {form.promo_button[0]?.label}
                                </p>
                              </Button>
                              <div className="mt-1 mb-1" />
                              <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[1]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[1]?.label_color,
                                  }}
                                >
                                  {form.promo_button[1]?.label}
                                </p>
                              </Button>
                            </div>
                          )}
                          {form.button_visibility == "horizontal_double" && (
                            <div className="d-flex flex-row align-center mt-3">
                              <Button
                                size="small"
                                className="mr-1"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[0]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[0]?.label_color,
                                  }}
                                >
                                  {form.promo_button[0]?.label}
                                </p>
                              </Button>
                              <Button
                                size="small"
                                className="ml-1"
                                disableElevation
                                variant="contained"
                                style={{
                                  backgroundColor:
                                    form.promo_button[1]?.bg_color,
                                }}
                                sx={{ borderRadius: 50 }}
                              >
                                <p
                                  className="font-weight-500 small_txt capitalize"
                                  style={{
                                    color: form.promo_button[1]?.label_color,
                                  }}
                                >
                                  {form.promo_button[1]?.label}
                                </p>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <p
                      className="small_txt text-center"
                      style={{
                        color: theme.white,
                      }}
                    >
                      {form.caption}
                    </p>
                  </>
                ) : (
                  <p
                    className="small_txt text-center"
                    style={{ color: theme.white }}
                  >
                    Pop Up yang akan Anda unggah akan terlihat di sini.
                  </p>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default FormCreatePopup;
