import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Button, Tooltip, tooltipClasses } from '@mui/material'
import React from 'react';
import { styled } from '@mui/material/styles';
import { Search } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from '../../../../store/apis'
import SkeletonTable from '../../../../components/base/SkeletonTable'
import EmptyData from '../../../../components/base/EmptyData'
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
const ListCounselingClient = ({ id }) => {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const [find, setFind] = useState('')
    const [loading, setLoading] = useState(true)
    const [dataSet, setData] = useState([])
    const [emptyTxt, setEmptyTxt] = useState('')
    const [state, setState] = useState({
        page: 1,
        limit: 5,
        total: null,
        last_page: null,
    })
    const fetchData = () => {
        setLoading(true)
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `konselor/konsultasi-klien?filter[konsultasi_thread.id_pelanggan]=${id}&filter[konsultasi_thread.konsultasi]=${find}${page}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setData(data.data)
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`)
            console.log(err)
            setLoading(false)
            setData([])
        })
    }
    const handlePage = (event, value) => {
        setState({ ...state, page: value })
    }
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip arrow placement="top-start" {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: 200,
            border: '1px solid #007585',
        },
    }));
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [find, state.page])
    return (
        <Paper className='pa-3 rounded-lg'>
            <Grid container spacing={2} >
                <Grid item xs={8}>
                    <h3>Konsultasi</h3>
                </Grid>
                <Grid item xs={4}>
                    <div className="temp-search-keyup mb-3">
                        <input type="text" value={find} onChange={event => setFind(event.target.value)} className='search-keyup' placeholder='Cari catatan' style={{ background: theme.tertiary }} />
                        <Search style={{ marginLeft: '-30px' }} color='primary' />
                    </div>
                </Grid>
            </Grid>
            {!loading ? !dataSet.length ? <EmptyData txt={emptyTxt} /> : <TableContainer component='div' style={{ overflowY: 'none' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>No</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Layanan</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tanggal<br />Konsultasi</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Waktu<br />Konsultasi</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Status</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.konsultasi}</TableCell>
                                <TableCell align="center">{item.tgl_konsultasi}</TableCell>
                                <TableCell align="center">{item.wkt_konsultasi}</TableCell>
                                <TableCell align="center">
                                    <p className='font-weight-bold'
                                        style={{ color: item.status === 'Aktif' ? '#00786A' : item.status === 'Selesai' ? '#6D0BCF' : item.status === 'Kadaluwarsa' ? '#B80222' : '' }}
                                    >{item.status}</p>
                                </TableCell>
                                <TableCell align="right">
                                    {item.status === 'Aktif' ?
                                        <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: '#99DDD5' }}>
                                            <p className='font-weight-bold' style={{ color: '#00786A' }}>Mulai</p>
                                        </Button> : <></>}

                                    {item.status !== 'Selesai' ?
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <div>
                                                        <p className='font-weight-bold mb-1' style={{ color: theme.primary, fontSize: '15px' }}>Topik Konsultasi</p>
                                                        <p style={{ color: '#000', fontSize: '13px' }}>{item.keterangan}</p>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: '#ACE1FF' }}>
                                                <p className='font-weight-bold' style={{ color: '#0B81C2' }}>Topik</p>
                                            </Button>
                                        </HtmlTooltip> : <></>}
                                    {item.status === 'Selesai' ?
                                        <Button variant='contained' className='rounded-xl capitalize' disableElevation size="small" style={{ background: '#D0BAFF' }}>
                                            <p className='font-weight-bold' style={{ color: '#6D0BCF' }}>Riwayat</p>
                                        </Button> : <></>}

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> :
                <SkeletonTable />
            }
            {dataSet.length ? <div className="d-flex align-center justify-space-between" style={{ width: '100%' }}>
                <p className='txt_table'>Menampilkan {state.page} - {state.limit} dari {state.total} </p>
                <div>
                    <Pagination sx={{ padding: '0' }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: '#fff' }} color="primary" />
                </div>
            </div> : <></>}
        </Paper>
    )
}

export default ListCounselingClient
