
import { Button, Grid, Paper, Avatar, Menu, MenuItem, Skeleton } from '@mui/material'
import { Check, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TITLE } from '../../../../store/theming'
import ListLog from './listLog'
import { getApi } from '../../../../store/apis'
import { useParams, useNavigate } from 'react-router-dom'
import day from 'dayjs'
import EmptyData from '../../../../components/base/EmptyData'

const LogActivityClient = () => {
    const theme = useSelector(state => state.theme.myTheme)
    const dummy = useSelector(state => state.theme.dummy)
    const { id } = useParams();
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const [state, setState] = useState({
        type: '',
        time: '',
    })
    const [loading, setLoading] = useState(true)
    const [loadlog, setLoadlog] = useState(true)
    const [dataSet, setData] = useState([])
    const [profile, setProfile] = useState(null)
    const [anchor, setAnchor] = useState({
        type: null,
        time: null,
    })
    const openType = Boolean(anchor.type);
    const openTime = Boolean(anchor.time);
    const openMenu = (event, type) => {
        setAnchor({ ...anchor, [type]: event.currentTarget })
    }
    const handleType = (index) => {
        setAnchor({ ...anchor, type: null })
        setState({ ...state, type: index.val })
    };
    const handleTime = (index) => {
        setAnchor({ ...anchor, time: null })
        setState({ ...state, time: index.val })
    };
    const listType = [
        {
            name: 'Semua Aktivitas',
            val: '',
        },
        {
            name: 'Tes Psikolog',
            val: 'Tes Psikolog'
        },
        {
            name: 'Konsultasi',
            val: 'Konsultasi'
        },
        {
            name: 'Psychological Update',
            val: 'Psychological Update'
        },
        {
            name: 'Game for Development',
            val: 'Game for Development'
        },
    ]
    const listTime = [
        {
            name: 'Semua Waktu',
            val: '',
        },
        {
            name: 'Hari Ini',
            val: '',
        },
        {
            name: 'Minggu Ini',
            val: '',
        },
        {
            name: 'Bulan Ini',
            val: '',
        },
    ]
    const fetchProfile = () => {
        setLoading(true)
        let data = {
            path: `konselor/profile-klien?id_pelanggan=${id}`
        }
        dispatch(getApi(data)).then(data => {
            setProfile(data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setProfile(null)
        })
    }
    let imgType = {
        psikotest: require('../../../../assets/img/layanan_psikotest.png'),
        konsultasi: require('../../../../assets/img/layanan_konseling.png'),
        psyUpdate: require('../../../../assets/img/layanan_psy_upgrade.png'),
        game: require('../../../../assets/img/layanan_game.png'),
    }
    const fetchData = () => {
        setLoadlog(true)
        let data = {
            path: `konselor/klien-log?filter[aktivitas_klien.id_pelanggan]=${id}`
        }
        dispatch(getApi(data)).then(data => {
            let temp = JSON.parse(JSON.stringify(data.data));
            temp.forEach(el => {
                el.tgl = day(el.created_at).format('YYYY-MM-DD')
                switch (el.tipe) {
                    case 'Tes Psikolog':
                        el.color = '#7D37C3'
                        el.img = imgType.psikotest
                        break;
                    case 'Konsultasi':
                        el.color = '#7D37C3'
                        el.img = imgType.konsultasi
                        break;
                    case 'Psychological Update':
                        el.color = '#E53C95'
                        el.img = imgType.psyUpdate
                        break;
                    case 'Game for Development':
                        el.color = '#E53C95'
                        el.img = imgType.game
                        break;
                    default:
                        break;
                }
            })
            setLoadlog(false)
            setData(temp)
        }).catch(err => {
            console.log(err)
            setData([])
            setLoadlog(false)
        })
    }
    useEffect(() => {
        dispatch(CHANGE_TITLE('Log Aktivitas Klien'))
        fetchProfile()
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            {loading}
            <Button className='capitalize rounded-xl mb-3' variant='contained' disableElevation onClick={() => navigate(`/client-list`)} >Kembali</Button>
            {!loading ?
                profile ?
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Paper className='pa-3 rounded-lg d-flex justify-space-between align-center' elevation={0}>
                                <div className="d-flex align-center">
                                    <Avatar
                                        alt={profile.nama}
                                        className='mr-3'
                                        src={profile.foto_link ? profile.foto_link : dummy}
                                        sx={{ width: 56, height: 56 }}
                                    />
                                    <div>
                                        <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                                            Nama
                                        </p>
                                        <p className="font-weight-bold txt_table">
                                            {profile.nama}
                                        </p>
                                    </div>
                                </div>
                                <Button className='capitalize rounded-xl' variant='contained' disableElevation onClick={() => navigate(`/profile-client/${id}`)}>Profile</Button>

                            </Paper>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3}>
                            <Paper onClick={(event) => openMenu(event, 'type')} className="pa-3 d-flex align-center justify-space-between rounded-lg txt_table cursor_pointer" style={{ color: theme.primary }}>
                                <p className='font-weight-bold mb-0'>
                                    {state.type !== '' ? state.type : 'Semua Aktivitas'}
                                </p>
                                {!openType ? <ArrowDropDown /> : <ArrowDropUp />}
                            </Paper>
                            <Menu
                                className='mt-2 rounded-lg'
                                id="limit"
                                anchorEl={anchor.type}
                                open={openType}
                                onClose={() => setAnchor({ ...anchor, type: null })}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                                PaperProps={{ sx: { borderRadius: '8px' } }}
                            >
                                {listType.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        selected={option.val === state.type}
                                        onClick={() => handleType(option)}

                                    >
                                        <div className="d-flex align-center justify-space-between cursor_pointer" style={{ width: "100%" }}>
                                            <p className='font-weight-bold txt_table' style={{ color: theme.primary }}>{option.name}</p>
                                            {option.val === state.type ? <Check fontSize="small" color="primary" /> : <></>}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                        <Grid item xs={2}>
                            <Paper onClick={(event) => openMenu(event, 'time')} className="pa-3 d-flex align-center justify-space-between rounded-lg txt_table cursor_pointer" style={{ color: theme.primary }}>
                                <p className='font-weight-bold mb-0'>
                                    {state.time !== '' ? state.time : 'Semua Waktu'}

                                </p>
                                {!openTime ? <ArrowDropDown /> : <ArrowDropUp />}
                            </Paper>
                            <Menu
                                className='mt-2 rounded-lg'
                                id="limit"
                                anchorEl={anchor.time}
                                open={openTime}
                                onClose={() => setAnchor({ ...anchor, time: null })}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                                PaperProps={{ sx: { borderRadius: '8px' } }}
                            >
                                {listTime.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        selected={option.val === state.time}
                                        onClick={() => handleTime(option)}

                                    >
                                        <div className="d-flex align-center justify-space-between cursor_pointer" style={{ width: "100%" }}>
                                            <p className='font-weight-bold txt_table' style={{ color: theme.primary }}>{option.name}</p>
                                            {option.val === state.time ? <Check fontSize="small" color="primary" /> : <></>}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid> : <EmptyData /> : <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Skeleton variant="rounded" className='rounded-lg' style={{ height: '80px', width: '100%' }} />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" className='rounded-lg' style={{ height: '48px', width: '100%' }} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton variant="rounded" className='rounded-lg' style={{ height: '48px', width: '100%' }} />
                    </Grid>
                </Grid>}

            {profile ? <ListLog theme={theme} list={dataSet} loading={loadlog} /> : <></>}

        </div>
    )
}

export default LogActivityClient
