import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Icon from "@mdi/react"
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js"

const PsikotesSettingList = ({ theme, dataSet, ava, page, limit }) => {
    const navigate = useNavigate()


    return (
        <div>
            <TableContainer component='div'>
                <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ minWidth: '50px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>No.</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Judul Psikotes</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '130px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Harga Normal</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Harga Promo</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '130px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Show</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{i + 1 + (page - 1) * limit}</TableCell>
                                <TableCell>
                                    <p className=''> {item.nama_psikotes} </p>
                                </TableCell>
                                <TableCell >Rp{item.harga}</TableCell>
                                <TableCell>{item.potongan_harga ? `Rp${item.potongan_harga}` : 'Tidak Ada Promo'}</TableCell>
                                <TableCell align="center">
                                    <Button>
                                        {item.status === 1 ?
                                            <Icon path={mdiToggleSwitch} size={1} color={theme.primary} /> : <Icon path={mdiToggleSwitchOffOutline} size={1} />
                                        }
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' className='rounded-xl capitalize mr-3' onClick={() => navigate(`/psikotes/detail/${item.id}`)} disableElevation size="small" style={{ background: theme.act_blue }}>
                                        <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }}>Detail</p>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pa-2">
            </div>
        </div>
    )
}

export default PsikotesSettingList
