/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import { postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const KonselingActivityCancel = ({
  konsultasi,
  open = false,
  onClose,
  refetch,
}) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const icon = require("../../../assets/img/warning.png");

  const onCancel = () => {
    const data = {
      path: "admin/konseling-aktivitas/delete",
      body: {
        id_konsultasi_thread: konsultasi.id,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        onClose(false);
        navigate(-1);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Cancel Konsultasi</h4>
          <IconButton onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="styled_scroll" style={{ overflowX: "hidden" }}>
        <div
          className="borbox"
          style={{ width: "500px", padding: "5px", minHeight: "100px" }}
        >
          <div className="d-flex flex-column align-center justify-center">
            <img src={icon} height="200px" alt="" />
            <p className="font-weight-bold">Yakin membatalkan konsultasi?</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          variant="outlined"
          className="flexy capitalize rounded-lg"
          disableElevation
        >
          <p className="font-weight-bold">Tidak</p>
        </Button>
        <Button
          onClick={() => onCancel()}
          variant="contained"
          className="flexy capitalize rounded-lg"
          disableElevation
        >
          <p className="font-weight-bold">Ya</p>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KonselingActivityCancel;
