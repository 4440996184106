/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import SkeletonTable from "../../../components/base/SkeletonTable";
import { ArrowDropDown } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import { Grid, Pagination, Paper, Card } from "@mui/material";
// import { useNavigate } from 'react-router-dom'

import day from "dayjs";
const PsikologActivity = ({ id }) => {
  // const navigate = useNavigate()

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const ava = useSelector((state) => state.theme.dummy);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });
  let imgType = {
    psikotest: require("../../../assets/img/layanan_psikotest.png"),
    konsultasi: require("../../../assets/img/layanan_konseling.png"),
    psyUpdate: require("../../../assets/img/layanan_psy_upgrade.png"),
    game: require("../../../assets/img/layanan_game.png"),
  };

  const fetchData = () => {
    setLoading(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/psikolog-coach/log-aktivitas?id=${id}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        let temp = JSON.parse(JSON.stringify(data.data));
        temp.forEach((el) => {
          el.tgl = day(el.created_at).format("YYYY-MM-DD");
          switch (el.tipe) {
            case "Tes Psikologi":
              el.color = "#7D37C3";
              el.img = imgType.psikotest;
              break;
            case "Konsultasi":
              el.color = "#7D37C3";
              el.img = imgType.konsultasi;
              break;
            case "Psychological Update":
              el.color = "#E53C95";
              el.img = imgType.psyUpdate;
              break;
            case "Game for Development":
              el.color = "#E53C95";
              el.img = imgType.game;
              break;
            default:
              break;
          }
        });
        setData(temp);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };

  useEffect(() => {
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Paper className="pa-3 rounded-lg mt-3">
        <Grid container spacing={2} className="mb-5">
          <Grid item xs={6}>
            <div className="d-flex align-center">
              <h3>User Activity</h3>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex align-center">
              <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
              <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5">
                <p className="small_txt font-weight-bold mr-3">
                  {state.limit} Data
                </p>{" "}
                <ArrowDropDown />{" "}
              </Card>
              <SearchDataTable value={find} setValue={setFind} />
            </div>
          </Grid>
        </Grid>
        {!loading ? (
          !dataSet.length ? (
            <EmptyData />
          ) : (
            dataSet.map((item, i) => (
              <div key={i}>
                {i !== 0 && dataSet[i].tgl !== dataSet[i - 1].tgl ? (
                  <div className="d-flex align-center mb-3">
                    <div className="pr-3">
                      <p
                        className="font-weight-bold mb-0"
                        style={{ color: theme.primary }}
                      >
                        {day(item.tgl).format("dddd, D MMMM YYYY")}
                      </p>
                    </div>
                    <div
                      className="line_log"
                      style={{ background: theme.secondary }}
                    ></div>
                  </div>
                ) : i === 0 ? (
                  <div className="d-flex align-center mb-3">
                    <div className="pr-3">
                      <p
                        className="font-weight-bold mb-0"
                        style={{ color: theme.primary }}
                      >
                        {day(item.tgl).format("dddd, D MMMM YYYY")}
                      </p>
                    </div>
                    <div
                      className="line_log"
                      style={{ background: theme.secondary }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="d-flex">
                  <div className="timeline mr-2">
                    <div
                      className="connector"
                      style={{ background: theme.secondary }}
                    ></div>
                    <div
                      className="dot mb-3"
                      style={{ background: theme.secondary }}
                    >
                      <div
                        className="inner_dot"
                        style={{ background: theme.tertiary }}
                      ></div>
                    </div>
                  </div>
                  <div className="d-flex align-center small_txt mr-3  mb-3">
                    <p>{day(item.created_at).format("HH:mm")} WIB</p>
                  </div>
                  <Paper className="pa-3 flexy rounded-lg mb-3" elevation={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <div className="d-flex align-center">
                          <img
                            src={item.img}
                            className="mr-3"
                            height="30px"
                            alt=""
                          />
                          <p
                            className="font-weight-bold mb-0"
                            style={{ color: item.color }}
                          >
                            {item.tipe}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} className="d-flex align-center">
                        <div
                          className="line_log_x mr-3"
                          style={{ background: theme.secondary }}
                        ></div>
                        <div className="txt_table">{item.activity}</div>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </div>
            ))
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}

        {dataSet.length ? (
          <div
            className="d-flex align-center justify-space-between"
            style={{ width: "100%" }}
          >
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination
                sx={{ padding: "0" }}
                count={state.last_page}
                page={state.page}
                shape="rounded"
                onChange={handlePage}
                style={{ background: "#fff" }}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>

      <div className="pa-2"></div>
    </div>
  );
};

export default PsikologActivity;
