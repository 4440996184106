/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { AddCircle, ChevronLeft, DateRange, ExpandMore, HighlightOff } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress, FormControlLabel, Grid, Paper, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import day from 'dayjs'
import Select from 'react-select';

/** @jsxImportSource @emotion/react */
import { css as emot } from "@emotion/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FlatArea from "../../../components/base/flatArea";
import FlatDatePicker from "../../../components/base/flatDatePicker";
import FlatInput from "../../../components/base/flatInput";
import { getApi, postApi } from "../../../store/apis";
import { CHANGE_TITLE } from "../../../store/theming";
import SelectKategoriCourse from "./components/selectKategoriCourse";
import SelectPemateriCourse from "./components/SelectPemateriCourse";
import styleInput from '../../../assets/css/search.module.css'

const UpdateCourse = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dp, setDp] = useState('')
  const [form, setForm] = useState({
    cover: "",
    jenis: "",
    model_pertemuan: null,
    judul: "",
    deskripsi_pendek: "",
    detail: "",
    kuota: "",
    promo: "",
    tgl_bataspromo: "",
    harga: "",
    tujuan: "",
    cp: "",
  });
  const [fasilitas, setFasilitas] = useState([''])
  const [bonus, setBonus] = useState([''])
  const [requirement, setRequirement] = useState([''])
  const grid = { left: 3.5, right: 8.5 };
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { id } = useParams();
  const pertemuanType = [{ label: 'Online', value: 'online' }, { label: 'offline', value: 'offline' }]
  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/course/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((res) => {
        setLoading(false);
        if (res) {
          let raw = JSON.parse(JSON.stringify(res))
          setForm({
            ...form,
            jenis: raw.jenis,
            model_pertemuan: raw.model_pertemuan ? pertemuanType.find(el => el.value == raw.model_pertemuan) : null,
            judul: raw.judul,
            deskripsi_pendek: raw.deskripsi_pendek,
            detail: raw.deskripsi_course,
            kuota: raw.kuota,
            promo: raw.harga_diskon.slice(0, -3),
            tgl_bataspromo: raw.tgl_diskon_exp ? new Date(raw.tgl_diskon_exp) : '',
            harga: raw.harga.slice(0, -3),
            tujuan: raw.kurikulum,
            cp: raw.no_kontak
          })
          setDateRange([new Date(raw.tgl_mulai), new Date(raw.tanggal_selesai)])
          setFasilitas(raw.fitur.length ? raw.fitur : [])
          setBonus(raw.bonus.length ? raw.bonus : [])
          setRequirement(raw.requirement.length ? raw.requirement : [])
          setPemateri(raw.author.length ? raw.author : [])
          setKategori(raw.kategori.length ? raw.kategori : [])
          setDp(raw.featured_image)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        // setData(null);
      });
  }

  const renderImg = e => {
    const file = e.target.files[0];
    const fr = new FileReader();
    fr.onload = (f) => {
      setDp(f.target.result)
    };
    setForm({ ...form, cover: file });
    fr.readAsDataURL(file);
  }

  const onChangeValue = (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;
    setForm({ ...form, [prop]: value });
  };

  const onChangeArr = (prop) => (event) => {
    if (prop.type == 'fasilitas') {
      let rawFasil = [...fasilitas]
      rawFasil[prop.idx] = event.target.value
      setFasilitas(rawFasil)
    } else if (prop.type == 'bonus') {
      let rawBonus = [...bonus]
      rawBonus[prop.idx] = event.target.value
      setBonus(rawBonus)
    } else {
      let rawReq = [...requirement]
      rawReq[prop.idx] = event.target.value
      setRequirement(rawReq)
    }
  }
  const [dialog, setDialog] = useState({
    pemateri: false,
    kategori: false,
  })
  const [pemateri, setPemateri] = useState([])
  const [kategori, setKategori] = useState([])
  const formatingAnswer = () => {
    let val_pemateri = pemateri.length ? pemateri.map(e => e.id) : []
    let val_kategori = kategori.length ? kategori.map(e => e.id) : []
    let val_fasilitas = ''
    let val_bonus = ''
    let val_requirement = ''
    fasilitas.forEach((el, i) => {
      if (el) {
        val_fasilitas = `${val_fasilitas}${el}${i < fasilitas.length - 1 ? ',' : ''}`
      }
    })
    bonus.forEach((el, i) => {
      if (el) {
        val_bonus = `${val_bonus}${el}${i < bonus.length - 1 ? ',' : ''}`
      }
    })
    requirement.forEach((el, i) => {
      if (el) {
        val_requirement = `${val_requirement}${el}${i < requirement.length - 1 ? ',' : ''}`
      }
    })
    updateData(val_pemateri, val_kategori, val_fasilitas, val_bonus, val_requirement)
  }

  const updateData = (val_pemateri, val_kategori, val_fasilitas, val_bonus, val_requirement) => {
    setLoading(true)
    const body = new FormData()
    body.append('id', id)
    if (form.cover) {
      body.append('featured_image', form.cover)
    }
    body.append('jenis', form.jenis)
    if (form.jenis == 'sync') {
      body.append('model_pertemuan', form.model_pertemuan.value ?? '')
    }
    body.append('judul', form.judul)
    body.append('kategori', JSON.stringify(val_kategori))
    body.append('deskripsi_pendek', form.deskripsi_pendek)
    body.append('deskripsi_course', form.detail)
    body.append('tgl_mulai', startDate ? day(startDate).format('YYYY-MM-DD') : null)
    body.append('tanggal_selesai', endDate ? day(endDate).format('YYYY-MM-DD') : null)
    body.append('jam_mulai', '00:00:00')
    body.append('jam_selesai', '23:59:00')
    body.append('author', JSON.stringify(val_pemateri))
    body.append('kuota', form.kuota)
    body.append('harga_diskon', form.promo)
    body.append('tgl_diskon_exp', form.tgl_bataspromo ? day(form.tgl_bataspromo).format('YYYY-MM-DD HH:mm:ss') : null)
    body.append('harga', form.harga)
    body.append('kurikulum', form.tujuan)
    body.append('fitur', val_fasilitas)
    body.append('bonus', val_bonus)
    body.append('requirement', val_requirement)
    body.append('no_kontak', form.cp)
    let data = {
      path: 'admin/course/update',
      body: body
    }
    dispatch(postApi(data)).then(res => {
      console.log(res)
      navigate(`/course-detail/${res.data.id}`)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }
  // custom style for select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state.isFocused || state.hasValue ? '' : '#FBF4FE',
      borderRadius: '5px',
      border: 'none',
      outline: `2px solid ${theme.tertiary}`,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5px',
      padding: '3px',
    }),
    input: (provided, state) => ({
      ...provided,
      outline: state.isFocused ? '2px solid #FBF4FE' : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      background: state.isSelected ? '#FBF4FE' : '',
      color: state.isSelected ? '#000' : '',
      ':hover': {
        background: '#FBF4FE',
      },
    }),
  }

  useEffect(() => {
    dispatch(CHANGE_TITLE("Update Course"));
    fetchData()
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg  mb-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeft /> Kembali
      </Button>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Paper elevation={0} className='rounded-lg pa-3'>
              {dp ? <div className='d-flex justify-center mb-3'><img src={dp} height='150px' alt='cover-psikotest' /></div> :
                <div className="d-flex align-center justify-center" style={{ height: '100px' }}>
                  <p className='font-weight-bold text-center' style={{ color: theme.primary, fontWeight: '500' }}>Cover Course</p>
                </div>}
              <div className="d-flex justify-center">
                <Button
                  variant="outlined"
                  className='capitalize'
                  component="label"
                >
                  <p className='font-weight-bold'>{dp ? 'Ubah Gambar' : 'Unggah Gambar'}</p>
                  <input hidden onChange={renderImg} accept="image/*" multiple type="file" />
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className="pa-5 rounded-lg mb-3">
              <p
                className="mb-3 font-weight-500"
                style={{ color: theme.primary }}
              >
                Informasi Course
              </p>
              <Grid
                container
                spacing={2}
                flexDirection="row"
              >
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Jenis Course
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <RadioGroup
                    name="rb-jenis"
                    row
                    value={form.jenis}
                    onChange={onChangeValue("jenis")}
                  >
                    <FormControlLabel
                      value="sync"
                      control={<Radio />}
                      label="Synchronous"
                    />
                    <FormControlLabel
                      value="async"
                      control={<Radio />}
                      label="Asynchronous"
                    />
                  </RadioGroup>
                  {form.jenis == 'sync' ? <Select
                    styles={customStyles}
                    classNamePrefix="select"
                    options={pertemuanType}
                    defaultValue={form.model_pertemuan}
                    onChange={(e) => setForm({ ...form, 'model_pertemuan': e })}
                    name="color"
                  /> : <></>}
                </Grid>

                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Judul Course
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <FlatInput
                    value={form.judul}
                    handleChange={onChangeValue}
                    property="judul"
                    placeholder="Tuliskan judul course"
                  />
                </Grid>

                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Kategori Course
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <div
                    className='d-flex justify-space-between align-center cursor_pointer'
                    style={{
                      width: '100%',
                      height: '40px',
                      background: `${kategori.length ? '#fff' : theme.tertiary}`,
                      borderRadius: '5px',
                      border: `2px solid ${theme.tertiary}`,
                      padding: '0 5px',
                    }}
                    onClick={() => setDialog({ ...dialog, kategori: true })}
                  >
                    <div className='d-flex'>
                      {kategori.length ? kategori.map((el, i) => i < 4 ? <div className='label-cat mr-1 d-flex' style={{ background: theme.tertiary }} key={`kategori-${i}`}>
                        <LinesEllipsis style={{ fontSize: '12px' }} className='mr-2' text={el.nama} />
                        <HighlightOff fontSize='small' color='red' onClick={(e) => { e.stopPropagation(); setKategori(kategori.filter(e => e.id != el.id)) }} />
                      </div> : <></>) : <p style={{ fontSize: 'small' }}>Kategori Course</p>}
                      {kategori.length > 4 ? <div className='label-cat' style={{ background: theme.tertiary }}>
                        <p style={{ color: theme.primary, fontSize: '12px' }}>+{kategori.length - 4} </p>
                      </div> : <></>}
                    </div>
                    <ExpandMore color='primary' />
                  </div>
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Deskripsi Singkat
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <FlatArea
                    value={form.deskripsi_pendek}
                    handleChange={onChangeValue}
                    property="deskripsi_pendek"
                    placeholder="Tuliskan deskripsi singkat mengenai course"
                  />
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Detail Course
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <FlatArea
                    value={form.detail}
                    handleChange={onChangeValue}
                    property="detail"
                    placeholder="Tuliskan detail lengkap mengenai course"
                  />
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Periode Pelaksanaan
                  </p>
                </Grid>
                <Grid item xs={grid.right}>

                  <div className="d-flex align-center"
                    style={{
                      background: theme.tertiary,
                      borderRadius: "5px",
                      padding: "2px",
                    }}>
                    <DateRange className="mx-2" />
                    <DatePicker
                      selectsRange={true}
                      css={emot`
                        background: ${startDate || endDate ? '' : theme.tertiary};
                        border-radius: 5px;
                        outline: 2px solid ${theme.tertiary};
                        &:focus {
                            background: #fff;
                        }
                        `}
                      startDate={startDate}
                      endDate={endDate}
                      className={styleInput.input_psy}
                      dateFormat="dd MMMM yyyy"
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Nama Pemateri
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <div
                    className='d-flex justify-space-between align-center cursor_pointer'
                    style={{
                      width: '100%',
                      height: '40px',
                      background: `${pemateri.length ? '#fff' : theme.tertiary}`,
                      borderRadius: '5px',
                      border: `2px solid ${theme.tertiary}`,
                      padding: '0 5px',
                    }}
                    onClick={() => setDialog({ ...dialog, pemateri: true })}
                  >
                    <div className='d-flex'>
                      {pemateri.length ? pemateri.map((el, i) => i < 4 ?
                        <div className='label-cat mr-1 d-flex' style={{ background: theme.tertiary }} key={`pemateri-${i}`}>
                          <LinesEllipsis style={{ fontSize: '12px' }} className='mr-2' text={el.nama} />
                          <HighlightOff fontSize='small' color='red' onClick={(e) => { e.stopPropagation(); setPemateri(pemateri.filter(e => e.id != el.id)) }} />
                        </div> : <></>) : <p style={{ fontSize: 'small' }}>Pilih Pemateri</p>}
                      {pemateri.length > 4 ? <div className='label-cat' style={{ background: theme.tertiary }}>
                        <p style={{ color: theme.primary, fontSize: '12px' }}>+{pemateri.length - 4} </p>
                      </div> : <></>}
                    </div>
                    <ExpandMore color='primary' />
                  </div>
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Kuota Peserta
                  </p>
                  <p className="xsmall_txt" style={{ color: "#A580AB" }}>
                    Kosongkan bila tidak ada
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <FlatInput
                    value={form.kuota}
                    handleChange={onChangeValue}
                    property="kuota"
                    placeholder="Jumlah maksimal peserta"
                    type="number"
                  />
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Harga Promo
                  </p>
                  <p className="xsmall_txt" style={{ color: "#A580AB" }}>
                    Kosongkan bila tidak ada
                  </p>
                </Grid>
                <Grid item xs={4.25}>
                  <FlatInput
                    value={form.promo}
                    handleChange={onChangeValue}
                    property="promo"
                    placeholder="Harga Promo"
                  />
                </Grid>
                <Grid item xs={4.25}>
                  <FlatDatePicker
                    value={form.tgl_bataspromo}
                    handleChange={onChangeValue}
                    property="tgl_bataspromo"
                    placeholder="Tanggal Batas Promo"
                  />
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Harga Normal
                  </p>
                </Grid>
                <Grid item xs={4.25}>
                  <FlatInput
                    value={form.harga}
                    handleChange={onChangeValue}
                    property="harga"
                    placeholder="Harga Normal"
                  />
                </Grid>
                <Grid item xs={4.25}></Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Tujuan Umum
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <FlatArea
                    value={form.tujuan}
                    handleChange={onChangeValue}
                    property="tujuan"
                    placeholder="Tuliskan tujuan umum course"
                  />
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Fasilitas Course
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  {fasilitas.map((el, i) => <div key={`fasil-${i}`} className='mb-2'><FlatInput
                    value={el}
                    handleChange={onChangeArr}
                    property={{ type: "fasilitas", idx: i }}
                    placeholder="Tuliskan apa saja fasilitas yang bisa didapatkan peserta"
                  /></div>)}

                  <Button
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg  mb-3"
                    onClick={() => setFasilitas([...fasilitas, ''])}
                  >
                    <AddCircle className='mr-2' /> Tambah Fasilitas
                  </Button>
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Bonus Course
                  </p>
                  <p className="xsmall_txt" style={{ color: "#A580AB" }}>
                    Kosongkan bila tidak ada
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  {bonus.map((el, i) => <div key={`bonus-${i}`} className='mb-2'><FlatInput
                    value={el}
                    handleChange={onChangeArr}
                    property={{ type: "bonus", idx: i }}
                    placeholder="Tuliskan bonus yang bisa didapatkan peserta"
                  /></div>)}

                  <Button
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg  mb-3"
                    onClick={() => setBonus([...bonus, ''])}
                  >
                    <AddCircle className='mr-2' /> Tambah Bonus
                  </Button>

                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Requirement Peserta
                  </p>
                  <p className="xsmall_txt" style={{ color: "#A580AB" }}>
                    Kosongkan bila tidak ada
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  {requirement.map((el, i) => <div key={`requirement-${i}`} className='mb-2'><FlatInput
                    value={el}
                    handleChange={onChangeArr}
                    property={{ type: "requirement", idx: i }}
                    placeholder="Tuliskan informasi tambahan untuk peserta, seperti perlengkapan yang harus dibawa atau catatan lainnya."
                  /></div>)}
                  <Button
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg  mb-3"
                    onClick={() => setRequirement([...requirement, ''])}
                  >
                    <AddCircle className='mr-2' /> Tambah Requirement
                  </Button>
                </Grid>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-bold"
                    style={{ color: theme.primary }}
                  >
                    Contact Peserta
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  <FlatArea
                    value={form.cp}
                    handleChange={onChangeValue}
                    property="cp"
                    placeholder="Masukkan nomor WhatsApp yang bisa dihubungi peserta"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <div className="d-flex justify-end">
          <Button variant="contained" onClick={formatingAnswer}>
            <p className="font-weight-bold small_txt capitalize">simpan</p>
          </Button>
        </div>
        <div className="py-2">
          <SelectPemateriCourse dialog={dialog.pemateri} close={() => setDialog({ ...dialog, pemateri: false })} pemateri={pemateri} setPemateri={setPemateri} />
          <SelectKategoriCourse dialog={dialog.kategori} close={() => setDialog({ ...dialog, kategori: false })} kategori={kategori} setKategori={setKategori} />
        </div>
      </div>
    </div>
  );
};

export default UpdateCourse;
