
import { Button, Avatar, Divider, Backdrop } from '@mui/material'
import Cookies from "js-cookie";
import React, { useState, useEffect, useRef } from 'react'
import ChatBubble from './chatBubble'
import { Assignment, Image, Mic, Videocam, Reply as ReplyIcon, Close as CloseIcon, Call as CallIcon } from '@mui/icons-material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useDispatch, useSelector } from 'react-redux'
import {
    collection,
    query,
    doc,
    where,
    setDoc,
    onSnapshot,
} from "firebase/firestore";

import { DeleteMsg } from "./deleteMsg"
import { db } from "../../config/firebase";
import { Menu, MenuItem, MenuDivider, MenuHeader } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import AudioRecorder from './audioRecorder';
import VideoCall from './VideoCall';


const ChatRoom = ({ css, thread }) => {
    const idUser = Cookies.get('id')

    const [textMsg, setTextMsg] = useState('')
    const [imgMsg, setImgMsg] = useState(null)
    const [audioMsg, setAudioMsg] = useState(null)

    const [toRecord, setToRecord] = useState(false)
    const [recording, setRecording] = useState(false)
    const allMsg = useSelector(state => state.chat.msg)
    const twillio = useSelector(state => state.chat.twillio)
    const theme = useSelector(state => state.theme.myTheme)

    const [dataReply, setDataReply] = useState(null)
    const [sending, setSending] = useState(false)
    const [inputEl, setInputEl] = useState(null) //ref element input file

    const listThread = useSelector(state => state.chat.thread)

    const [d_del, setDel] = useState(false)
    const [delMsg, setDelMsg] = useState(null)

    // click enter to send & enter + shift to new line
    const handleEnter = (e) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            if (textMsg !== '') {
                sendMsg('text')
            }
        }
    }

    // if user want to ancel reply
    const cancelReply = () => {
        setDataReply(null)
        gotFocused()
    }

    const [onCall, setOnCall] = useState(false)

    const vnRef = useRef();
    // trigger start call, either its video or audio call
    const startCall = (type, status = 'callout') => {
        setOnCall(true)
        setTimeout(() => {
            vnRef.current.setGum(type, status)
        }, 100);
    }
    // trgger for start recording
    const startRecord = () => {
        setToRecord(true)
        setTimeout(() => {
            vnRef.current.startVn()
        }, 100);
    }
    // perpare pesan gambar
    const preImg = e => {
        const file = e.target.files[0];
        if (file.size > 350000) {
            // vm.isFotoLarge = true;
            console.log("wakakak");
        } else {
            const fr = new FileReader();
            fr.onload = (f) => {
                setImgMsg(f.target.result)
            };
            fr.readAsDataURL(file);
        }
    }

    // perpare reply pesan
    const preReply = item => {
        gotFocused()
        let reply = null
        if (item) {
            reply = {
                id_message: item._id,
                id_user: item.from_id,
                replyText: item.text,
                replyTo: null,
                replyType: item.type,
            }
            setDataReply(reply)
        }
    }


    // trigger for send message
    const sendMsg = async (type) => {
        setSending(true)
        let idMsg = ''

        // code bellow is configuration for id per message
        if (allMsg.length > 0) {
            let idLastMsg = allMsg[allMsg.length - 1]._id;
            idLastMsg = idLastMsg.substring(1);
            idMsg = `m${parseInt(idLastMsg) + 1}`;
        } else {
            idMsg = `m${allMsg.length + 1}`;
        }
        let user = {
            _id: thread.konselor_id,
            name: '',
        }

        // validation for type message
        let message = type == 'text' ? textMsg : type == 'image' ? imgMsg : audioMsg

        // push message to firebase 
        await setDoc(doc(db, "messages", thread.id, thread.code, idMsg), {
            text: message,
            type: type,
            createdAt: new Date(),
            from_id: thread.konselor_id,
            user: user,
            reply: dataReply,
        })

        setSending(false)
        setTextMsg('')
        setDataReply(null)
        setImgMsg(null)
        gotFocused()
        toBottom()
        // update last update message for backend stuff (maybe)
        await setDoc(doc(db, "messages", thread.id), {
            last_update: new Date(),
        })
    }

    // tiap kali render chat box, input text harus focus agar user langsung ketik2
    const gotFocused = () => {
        setTimeout(() => {
            let txtbox = document.getElementById('chatbox')
            txtbox.focus();
        }, 10);
    }

    // pertama kali render chat, harus scroll ke bawah
    const toBottom = () => {
        setTimeout(() => {
            let scrollDown = document.getElementsByClassName(css.temp_chat)[0];
            scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
        }, 100);
    }

    // listen to incoming call
    const incomingCall = () => {
        const q = query(
            collection(db, "rooms"), where("status", '==', "open"), where("caller", '!=', idUser)
        );
        onSnapshot(q, (qs) => {
            qs.docChanges().forEach(change => {
                let calling = localStorage.getItem("calling");
                if (change.type === 'added') {
                    let curentThread = listThread.find(el => el.id == change.doc.id)
                    if (curentThread) {
                        startCall(change.doc.data().type, 'income')
                    }
                }
            })
        })

    }

    const listFastMsg = [
        'Halo, selamat datang di konsultasi Teman Bakat',
        'Ada yang bisa saya bantu?',
        'Ada lagi yang ingin ditanyakan atau disampaikan?',
        'Terima kasih sudah berkonsultasi di aplikasi Teman Bakat.',
    ]
    const fastMsg = msg => {
        setTextMsg(msg)
        gotFocused()
    }

    const preDelMsg = msg => {
        setDelMsg(msg)
        setDel(true)
    }

    const stopwatch = require('../../assets/img/icon timer.png')

    const endSession = async () => {
        setTextMsg('Terima kasih telah berkonsultasi dengan kami')
        await sendMsg('text')
        await setDoc(doc(db, "thread", thread.id), {
            is_done: 1,
        })
        // /log/store
        // /percakapan/simpan
    }
    useEffect(() => {
        toBottom()
        incomingCall()
    }, [allMsg])


    return (
        <>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={onCall}>
                {
                    onCall ? <VideoCall css={css} twillio={twillio} ref={vnRef} setOnCall={setOnCall} thread={thread} /> : <></>
                }
            </Backdrop>
            <div className={css.selected_person}>
                <div className={css.person}>
                    <div className="d-flex align-center">
                        <Avatar alt={thread.pelanggan_nama} src={thread.avatar} sx={{ width: 40, height: 40 }} className="mr-3" />
                        <div className="d-flex flexy">
                            <div className='flexy white-text'>
                                <p className='font-weight-bold '>{thread.pelanggan_nama} </p>
                                <p className='small_txt' style={{ fontSize: '12px' }}>{thread.title}</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <Button color='white' onClick={() => startCall('video')}>
                            <Videocam />
                        </Button>
                        <Button color='white' onClick={() => startCall('audio')}>
                            <CallIcon />
                        </Button>
                    </div>
                </div>
                {imgMsg ?
                    <div className={`${css.prepare_send_img}`}>
                        <div>
                            <img src={imgMsg} className={css.frame_img} alt="" />
                            <div className="d-flex align-center justify-center mt-5">
                                {!sending ?
                                    <Button variant='contained' className='mr-2 capitalize' onClick={() => sendMsg('image')}>Send</Button>
                                    : <Button variant='contained' className='mr-2 capitalize'>Sending...</Button>}
                                <Button variant='outlined' className='capitalize' color='red' disabled={sending} onClick={() => setImgMsg(null)}>Cancel</Button>
                            </div>
                        </div>
                    </div> : <></>
                }

                <div className={css.timer}>
                    <div className="d-flex align-center">
                        <img src={stopwatch} height='30px' className='mr-1' alt="" />
                        <p className={css.running_time}>00:49:59</p>
                    </div>
                    <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: theme.act_red }}>
                        <p className='font-weight-bold' style={{ color: theme.act_lbl_red }}>Tutup Sesi</p>
                    </Button>
                </div>
                <div className={css.temp_chat}>
                    <ChatBubble css={css} preReply={preReply} preDelMsg={preDelMsg} />
                    <DeleteMsg thread={thread} msg={delMsg} d_del={d_del} close={() => setDel(false)} />
                </div>
                <div className={`${css.toolbar}`}>
                    {dataReply ? <div className={css.container_reply}>
                        <div className="d-flex pa-3">
                            <ReplyIcon />
                            <div className={css.reply_txt}>
                                {dataReply.replyType == 'text' ?
                                    <p style={{ fontSize: '14px' }}>{dataReply.replyText}</p> : dataReply.replyType == 'image' ?
                                        <img src={dataReply.replyText} alt="img" className={css.prereply_img} /> : <div className='d-flex align-center'><Mic /> Audio</div>
                                }
                            </div>
                            <Button style={{ minWidth: '5px', padding: 0, height: 'max-content', color: 'red' }} onClick={() => cancelReply()}> <CloseIcon /></Button>
                        </div>
                        <Divider />
                    </div> : <></>}
                    {
                        toRecord ? <AudioRecorder css={css} ref={vnRef} sendMsg={sendMsg} setToRecord={setToRecord} setRecording={setRecording} setAudioMsg={setAudioMsg} recording={recording} /> :
                            <div className="d-flex align-end pa-3" style={{ width: '100%' }}>
                                <Button className='mr-2' style={{ minWidth: '5px', height: 'max-content' }} onClick={() => inputEl.click()}>
                                    <Image />
                                    <input
                                        type="file"
                                        ref={(file) => (setInputEl(file))}
                                        accept="image/*"
                                        onChange={(event) => preImg(event)}
                                        className={css.input_hide}
                                    />
                                </Button>
                                <Button className='mr-2' style={{ minWidth: '5px', height: 'max-content' }} onClick={() => startRecord()}>
                                    <Mic />
                                </Button>
                                <TextareaAutosize maxRows={7} disabled={sending} id='chatbox' value={textMsg} onChange={(e) => setTextMsg(e.target.value)} onKeyDown={(e) => handleEnter(e)} className='chatbox styled_scroll' style={{ width: '100%' }}
                                />
                                <Menu className='mr-2' menuButton={
                                    <Button style={{ minWidth: '5px', height: 'max-content' }}>
                                        <Assignment />
                                    </Button>
                                }
                                    transition arrow>
                                    <MenuHeader>Pesan Cepat</MenuHeader>
                                    <MenuDivider />
                                    {listFastMsg.map((fm, i) => (
                                        <MenuItem key={i} onClick={() => fastMsg(fm)}><p className='small_txt ml-2' >{fm}</p></MenuItem>
                                    ))}
                                </Menu>

                            </div>
                    }
                </div>
            </div >
        </>
    )
}

export default ChatRoom
