/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Grid, Paper, Skeleton, IconButton } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import day from "dayjs";
import { getApi } from "../../../../store/apis";
import { AddCircle } from "@mui/icons-material";
import EmptyData from "../../../../components/base/EmptyData";
import { useNavigate } from "react-router-dom";
import ListDraggablePopup from "./ListDraggablePopup";
import Calendar from "react-calendar";

const PopupBannerActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.myTheme);
  const [tab, setTab] = useState(1);
  const [today, setToday] = useState(day());
  const [load, setLoad] = useState({
    popup: false,
    banner: false,
  });

  const [popup, setPop] = useState([]);
  const [banner, setBanner] = useState([]);

  const fetchPopup = () => {
    setLoad({ ...load, popup: true });
    let data = {
      path: `admin/advertising/popup?filter[start_promo]=${today.format(
        "YYYY-MM-DD"
      )}`,
    };

    dispatch(getApi(data))
      .then((response) => {
        setPop(response.data);
        setLoad({ ...load, popup: false });
      })
      .catch((err) => {
        setPop([]);
        setLoad({ ...load, popup: false });
      });
  };

  const fetchBanner = () => {
    setLoad({ ...load, banner: true });
    let data = {
      path: `admin/advertising/live-banner?date=${today.format("YYYY-MM-DD")}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoad({ ...load, banner: true });
        let raw = JSON.parse(JSON.stringify(data.data));
        setBanner(raw);
      })
      .catch((err) => {
        setLoad({ ...load, banner: true });
        setPop([]);
      });
  };

  const onListChangePopup = (data) => {
    setPop(data);
  };

  const changeDate = (change) => {
    const date = day(today).add(change, "day");
    setToday(date);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE(`Pop Up & Banner`));
    fetchPopup();
    fetchBanner();
  }, [today]);

  return (
    <div>
      <div className="d-flex mt-2">
        <Button
          variant="contained"
          className="mr-3 rounded-lg"
          color={tab == 1 ? "primary" : "white"}
          disableElevation
          onClick={() => setTab(1)}
        >
          <p
            className="font-weight-bold capitalize"
            style={{ color: tab == 1 ? "white" : theme.primary }}
          >
            Schedule
          </p>
        </Button>
        <Button
          variant="contained"
          className="mr-3 rounded-lg"
          color={tab == 2 ? "primary" : "white"}
          disableElevation
          onClick={() => setTab(2)}
        >
          <p
            className="font-weight-bold capitalize"
            style={{ color: tab == 2 ? "white" : theme.primary }}
          >
            Statistik
          </p>
        </Button>
      </div>
      <div className="mt-3">
        {tab == 1 ? (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div className="d-flex flex-row align-center justify-space-between mb-3">
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  {today.locale("id").format("dddd, D MMMM YYYY")}
                </p>
                <div className="d-flex">
                  <IconButton
                    onClick={() => changeDate(-1)}
                    style={{
                      backgroundColor: theme.lighten,
                    }}
                  >
                    <ChevronLeft sx={{ color: theme.primary }} />
                  </IconButton>
                  <div className="mr-1 ml-1"></div>
                  <IconButton
                    onClick={() => changeDate(1)}
                    style={{
                      backgroundColor: theme.lighten,
                    }}
                  >
                    <ChevronRight sx={{ color: theme.primary }} />
                  </IconButton>
                </div>
              </div>
              <Paper className="pa-3 rounded-lg mb-5">
                <div className="d-flex justify-space-between align-center">
                  <p className="font-weight-bold">Pop Up</p>
                  <Button variant="contained" className="capitalize">
                    Setting Pop up
                  </Button>
                </div>
                <Paper
                  style={{ background: theme.tertiary }}
                  elevation={0}
                  className="pa-3 rounded-lg mt-3"
                >
                  {load.popup ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <Skeleton
                              key={index}
                              className="rounded-lg mb-2"
                              variant="rectangular"
                              width="100%"
                              height={60}
                            />
                          );
                        })}
                    </>
                  ) : popup.length ? (
                    <ListDraggablePopup
                      onChange={onListChangePopup}
                      data={popup}
                      theme={theme}
                    />
                  ) : (
                    <EmptyData />
                  )}
                  <div className="d-flex justify-end">
                    <Button
                      variant="contained"
                      className="capitalize"
                      onClick={() =>
                        navigate("/popup-banner-activity/create-popup")
                      }
                    >
                      Tambah Pop Up <AddCircle className="ml-2" />
                    </Button>
                  </div>
                </Paper>
              </Paper>
              <Paper className="pa-3 rounded-lg mb-5">
                <div className="d-flex justify-space-between align-center">
                  <p className="font-weight-bold">Banner</p>
                  <Button variant="contained" className="capitalize">
                    Setting Banner
                  </Button>
                </div>
                <Paper
                  style={{ background: theme.tertiary }}
                  elevation={0}
                  className="pa-3 rounded-lg mt-3"
                >
                  <div className="d-flex justify-end">
                    <Button variant="contained" className="capitalize">
                      Tambah Banner <AddCircle className="ml-2" />
                    </Button>
                  </div>
                </Paper>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className="pa-5 rounded-lg">
                <Calendar value={new Date(today)} />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PopupBannerActivity;
