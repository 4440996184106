/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi, postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import {
  Grid,
  Paper,
  Button,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Close,
  Person,
  Email,
  DateRange,
  PhoneIphone,
  ChevronLeft,
  GridView,
  Add,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InputWithIcon from "../../../components/base/inputWithIcon";
import SelectWithIcon from "../../../components/base/selectWithIcon";
import FlatInput from "../../../components/base/flatInput";

const PsikologEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState({
    nama: state.nama,
    email: state.email,
    tgl_lahir: state.tgl_lahir,
    no_telp: state.no_telp,
    jenis_kelamin: state.jenis_kelamin,
    no_rek: state.no_rek,
    nama_bank: state.nama_bank,
    nama_pemilik_rekening: state.nama_pemilik_rekening,
    jenis: state.jenis,
    kategori: state.kategori ?? 1,
    group: state.group,
    ssp: state.ssp,
    sipp: state.sipp,
    exp_sipp: state.exp_sipp,
    no_anggota_himpsi: state.no_anggota_himpsi,
    expertise: state.expertise.map((el) => el.id),
    expertise_coach: state.expertise_coach,
    permission: state.data_user?.permission || [],
  });

  const listCategory = [
    { name: "Psikolog", id: 1 },
    { name: "Coach", id: 2 },
    { name: "Psikolog & Coach", id: 3 },
  ];

  const [listAcc, setListAcc] = useState([]);
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [keahlianPsy, setKeahlianPsy] = useState([]);

  const postData = () => {
    setLoading(true);
    const body = {
      path: "admin/psikolog-coach/update",
      body: {
        id,
        ...data,
        expertise: JSON.stringify(data.expertise),
        expertise_coach: JSON.stringify(data.expertise_coach),
        permission: JSON.stringify(data.permission),
      },
    };

    dispatch(postApi(body))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Psikolog berhasil diubah", {
          variant,
        });
        navigate(-1);
      })
      .catch((err) => {
        const variant = "error";
        const error = err.data?.errors || false;
        if (error) {
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(`${message} ${error[message]}`, { variant });
          });
        }
        enqueueSnackbar(err.message ?? "Psikolog gagal diubah", { variant });
      });
    setLoading(false);
  };

  const changeTab = (e) => {
    setTab(e.target.id);
  };

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleCheck = (prop, item) => (event) => {
    const { checked } = event.target;
    if (checked) {
      handleChange(prop)({
        target: {
          value: [...data[prop], item],
        },
      });
    } else {
      handleChange(prop)({
        target: {
          value: data[prop].filter((val) => val !== item),
        },
      });
    }
  };

  const onValueChangeCoach = (index) => (event) => {
    let expertiseCoach = [...data.expertise_coach];
    expertiseCoach[index] = event.target.value;
    const value = {
      target: {
        value: expertiseCoach,
      },
    };
    handleChange("expertise_coach")(value);
  };

  const addIndexExpertiseCoach = () => {
    const value = {
      target: {
        value: [...data.expertise_coach, ""],
      },
    };
    handleChange("expertise_coach")(value);
  };

  const removeIndexExpertiseCoach = (index) => {
    let expertiseCoach = [...data.expertise_coach];
    expertiseCoach.splice(index, 1);
    const value = {
      target: {
        value: expertiseCoach,
      },
    };
    handleChange("expertise_coach")(value);
  };

  const fetchPermission = () => {
    let data = {
      path: `admin/permission/psikolog`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setListAcc(data);
      })
      .catch((err) => {
        setListAcc([]);
      });
  };

  const fetchExpertise = () => {
    let data = {
      path: `admin/expertise?filter[tipe]=konselor`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setKeahlianPsy(data);
      })
      .catch((err) => {
        setKeahlianPsy([]);
      });
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Ubah Psikolog & Coach"));
    fetchPermission();
    fetchExpertise();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <div>
            <div className="d-flex justify-space-between align-center mb-3">
              <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg"
                onClick={() => navigate(-1)}
              >
                <ChevronLeft /> Kembali
              </Button>
            </div>
            {state ? (
              <Paper
                className="d-flex flex-column pa-3 rounded-lg"
                elevation={0}
                style={{ height: "800px" }}
              >
                <Box
                  className="mb-3"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={tab}
                    onChange={changeTab}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                      id="1"
                      className="capitalize"
                      label="Informasi Konselor"
                      value="1"
                    />
                    <Tab
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                      id="2"
                      className="capitalize"
                      label="Detail Konselor"
                      value="2"
                    />
                    <Tab
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                      id="3"
                      className="capitalize"
                      label="Access Permission"
                      value="3"
                    />
                  </Tabs>
                </Box>
                <div className="flexy">
                  {tab === "1" && (
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <p className="mb-2" style={{ color: theme.primary }}>
                          Profile Konselor
                        </p>

                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nama Lengkap Konselor
                          </p>
                          <InputWithIcon
                            value={data.nama}
                            icon={<Person color="primary" />}
                            handleChange={handleChange}
                            property="nama"
                          />
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nomor Identitas
                          </p>
                          <InputWithIcon
                            value={data.no_identitas}
                            icon={<Person color="primary" />}
                            handleChange={handleChange}
                            property="no_identitas"
                          />
                        </div>

                        <div className="d-flex flex-row mb-3">
                          <div className="mr-1">
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Jenis Kelamin
                            </p>
                            <RadioGroup
                              name="rb-jenkel"
                              row
                              value={data.jenis_kelamin}
                              onChange={handleChange("jenis_kelamin")}
                            >
                              <FormControlLabel
                                value="Perempuan"
                                control={<Radio />}
                                label="Perempuan"
                              />
                              <FormControlLabel
                                value="Laki-Laki"
                                control={<Radio />}
                                label="Laki-laki"
                              />
                            </RadioGroup>
                          </div>
                          <div className="ml-1">
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Tanggal Lahir
                            </p>
                            <InputWithIcon
                              value={data.tgl_lahir}
                              icon={<DateRange color="primary" />}
                              handleChange={handleChange}
                              type="date"
                              property="tgl_lahir"
                            />
                          </div>
                        </div>

                        <div className="d-flex flex-row mb-3">
                          <div className="mr-1">
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Email Aktif
                            </p>
                            <InputWithIcon
                              value={data.email}
                              icon={<Email color="primary" />}
                              handleChange={handleChange}
                              property="email"
                            />
                          </div>
                          <div className="ml-1">
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Nomor Hp
                            </p>
                            <InputWithIcon
                              value={data.no_telp}
                              icon={<PhoneIphone color="primary" />}
                              handleChange={handleChange}
                              type="number"
                              property="no_telp"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="mb-2" style={{ color: theme.primary }}>
                          Informasi Rekening
                        </p>

                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nomor Rekening
                          </p>
                          <FlatInput
                            value={data.no_rek}
                            handleChange={handleChange}
                            property="no_rek"
                            placeholder="No Rekening"
                          />
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Bank
                          </p>
                          <FlatInput
                            value={data.nama_bank}
                            handleChange={handleChange}
                            property="nama_bank"
                            placeholder="Nama Bank"
                          />
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nama Pemilik Rekening
                          </p>
                          <FlatInput
                            value={data.nama_pemilik_rekening}
                            handleChange={handleChange}
                            property="nama_pemilik_rekening"
                            placeholder="Nama Pemilik Rekening"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {tab === "2" && (
                    <div>
                      <div className="mb-3">
                        <p
                          className="font-weight-bold small_txt"
                          style={{ color: theme.primary }}
                        >
                          Jenis Konselor
                        </p>
                        <RadioGroup
                          name="rb-jenis"
                          row
                          value={data.jenis}
                          onChange={handleChange("jenis")}
                        >
                          <FormControlLabel
                            value="Internal"
                            control={<Radio />}
                            label="Internal"
                          />
                          <FormControlLabel
                            value="Eksternal"
                            control={<Radio />}
                            label="Eksternal"
                          />
                        </RadioGroup>
                      </div>
                      <div className="mb-3">
                        <p
                          className="font-weight-bold small_txt"
                          style={{ color: theme.primary }}
                        >
                          Kategori
                        </p>
                        <div style={{ width: "300px" }}>
                          <SelectWithIcon
                            value={data.kategori}
                            list={listCategory}
                            handleChange={handleChange}
                            item_text="name"
                            item_value="id"
                            icon={<GridView color="primary" />}
                            property="kategori"
                          />
                        </div>
                      </div>
                      <Grid container spacing={2}>
                        {(data.kategori === 1 || data.kategori === 3) && (
                          <Grid item xs={6}>
                            <p
                              className="mb-2"
                              style={{ color: theme.primary }}
                            >
                              Detail Psikolog
                            </p>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <div
                                  className="d-flex align-center"
                                  style={{ height: "100%" }}
                                >
                                  <p
                                    className="font-weight-bold small_txt"
                                    style={{ color: theme.primary }}
                                  >
                                    Grup/Organisasi
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <FlatInput
                                  value={data.group}
                                  handleChange={handleChange}
                                  property="group"
                                  placeholder="Grup/Organisasi"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <div
                                  className="d-flex align-center"
                                  style={{ height: "100%" }}
                                >
                                  <p
                                    className="font-weight-bold small_txt"
                                    style={{ color: theme.primary }}
                                  >
                                    No. Anggota
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <FlatInput
                                  value={data.no_anggota_himpsi}
                                  handleChange={handleChange}
                                  property="no_anggota_himpsi"
                                  placeholder="No Anggota"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <div
                                  className="d-flex align-center"
                                  style={{ height: "100%" }}
                                >
                                  <p
                                    className="font-weight-bold small_txt"
                                    style={{ color: theme.primary }}
                                  >
                                    No. SSP
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <FlatInput
                                  value={data.ssp}
                                  handleChange={handleChange}
                                  property="ssp"
                                  placeholder="No SSP"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <div
                                  className="d-flex align-center"
                                  style={{ height: "100%" }}
                                >
                                  <p
                                    className="font-weight-bold small_txt"
                                    style={{ color: theme.primary }}
                                  >
                                    No. SIPP
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <FlatInput
                                  value={data.sipp}
                                  handleChange={handleChange}
                                  property="sipp"
                                  placeholder="No SIPP"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <div
                                  className="d-flex align-center"
                                  style={{ height: "100%" }}
                                >
                                  <p
                                    className="font-weight-bold small_txt"
                                    style={{ color: theme.primary }}
                                  >
                                    Masa Berlaku SIPP
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <InputWithIcon
                                  value={data.exp_sipp}
                                  icon={<DateRange color="primary" />}
                                  handleChange={handleChange}
                                  type="date"
                                  property="exp_sipp"
                                  positionIcon="right"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <p
                                  className="font-weight-bold small_txt"
                                  style={{ color: theme.primary }}
                                >
                                  Bidang Keahlian Psikolog
                                </p>
                              </Grid>
                              <Grid item xs={8}>
                                <div className="d-flex flex-column">
                                  {keahlianPsy.map((item, i) => (
                                    <FormControlLabel
                                      key={i}
                                      label={item.expertise}
                                      control={
                                        <Checkbox
                                          checked={data.expertise.includes(
                                            item.id
                                          )}
                                          onChange={handleCheck(
                                            "expertise",
                                            item.id
                                          )}
                                        />
                                      }
                                    />
                                  ))}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {(data.kategori === 2 || data.kategori === 3) && (
                          <Grid item xs={6}>
                            <p
                              className="mb-2"
                              style={{ color: theme.primary }}
                            >
                              Detail Coach
                            </p>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <p
                                  className="font-weight-bold small_txt"
                                  style={{ color: theme.primary }}
                                >
                                  Bidang Keahlian Coach
                                </p>
                              </Grid>
                              <Grid item xs={8}>
                                {data.expertise_coach.map((value, index) => {
                                  return (
                                    <div className="mb-2">
                                      <InputWithIcon
                                        value={value}
                                        icon={
                                          <Close
                                            color="inherit"
                                            onClick={removeIndexExpertiseCoach}
                                          />
                                        }
                                        handleChange={onValueChangeCoach}
                                        property={index}
                                        positionIcon="right"
                                      />
                                    </div>
                                  );
                                })}
                              </Grid>
                              <div className="d-flex flexy justify-end">
                                <Button
                                  variant="text"
                                  onClick={addIndexExpertiseCoach}
                                  className="capitalize rounded-lg px-0"
                                  style={{ minWidth: "0", marginTop: 10 }}
                                  disableElevation
                                >
                                  <p
                                    className="font-weight-bold small_txt"
                                    style={{ color: theme.primary }}
                                  >
                                    Tambah Bidang
                                  </p>
                                  <Add />
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                  {tab === "3" && (
                    <div>
                      <p style={{ color: theme.primary }}>Access Permission</p>
                      <p
                        className="small_txt mb-2"
                        style={{ color: "#404040" }}
                      >
                        Beri centang untuk fitur yang bisa diakses dan diubah
                        konselor
                      </p>
                      <div className="d-flex flex-column">
                        {listAcc.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            label={item.name}
                            control={
                              <Checkbox
                                checked={data.permission.includes(item.name)}
                                onChange={handleCheck("permission", item.name)}
                              />
                            }
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-end">
                  <Button
                    onClick={postData}
                    variant="contained"
                    className="capitalize rounded-lg"
                    disableElevation
                  >
                    <p className="font-weight-bold">Simpan</p>
                  </Button>
                </div>
              </Paper>
            ) : (
              <EmptyData />
            )}
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </div>
  );
};

export default PsikologEdit;
