/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TITLE } from '../../../store/theming'
import { getApi } from '../../../store/apis'
import { Grid, Card, Menu, MenuItem, Button, FormControl, RadioGroup, FormControlLabel, Radio, Divider, Paper, Pagination } from '@mui/material'
import { Search, Check, Cancel, FilterAlt } from '@mui/icons-material'

import EmptyData from '../../../components/base/EmptyData'
import TablelistSchedule from './tableListSchedule'
import SkeletonTable from '../../../components/base/SkeletonTable'
const ListScheduleCounseling = () => {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const ava = useSelector(state => state.theme.dummy)
    const [find, setFind] = useState('')
    const [loading, setLoading] = useState(true)
    const [dataSet, setData] = useState([])
    const [emptyTxt, setEmptyTxt] = useState('')
    const [state, setState] = useState({
        page: 1,
        limit: 10,
        last_page: null,
        total: null,
        find: '',
        gender: '',
        profesi: '',
        tipeSort: null,
        asc: true,
    })
    const [count, setCount] = useState(0)
    const handleChange = (prop) => (event) => {
        setState({ ...state, [prop]: event.target.value })
    }
    const [focused, setFocused] = useState(false)
    const listLimit = [10, 25, 50, 100]
    const [anchor, setAnchor] = useState({
        limit: null,
        filter: null,
    })
    const openLimit = Boolean(anchor.limit);
    const openFilter = Boolean(anchor.filter);
    const handleLimit = (index) => {
        setAnchor({ ...anchor, limit: null })
        setState({ ...state, limit: index })
    };
    const openMenu = (event, type) => {
        setAnchor({ ...anchor, [type]: event.currentTarget })
    }
    const resetFind = () => {
        setState({ ...state, find: '' })
        setFind('')
    }
    const handleFilter = () => {
        setAnchor({ ...anchor, filter: null })
        fetchData()
    };
    const resetFilter = () => {
        setState({ ...state, profesi: '', gender: '' })
    }
    const fetchData = () => {
        setLoading(true)
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `konselor/jadwal-konsultasi-klien?filter[pelanggan.nama]=${find}${page}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setData(data.data)
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            if (err.status == 400) {
                if (find != '') {
                    setEmptyTxt(`${find} tidak cocok untuk data apapun!`)
                } else {
                    setEmptyTxt(`No Data Available`)
                }
            } else {
                setEmptyTxt(`${err.status}: ${err.data.message}`)
            }
            setLoading(false)
            setData([])
        })
        let arrFilter = [state.gender, state.profesi]
        let counter = arrFilter.filter(i => i !== '').length
        setCount(counter)
    }
    const handlePage = (event, value) => {
        setState({ ...state, page: value })
    }
    useEffect(() => {
        dispatch(CHANGE_TITLE('Jadwal Konsultasi'))
        fetchData()
    }, [state.limit, find, state.page])
    return (
        <div>
            <Grid container spacing={2} className='mb-5'>
                <Grid item xs={2}>
                    <p className='mb-2 small_txt' style={{ color: theme.primary }}>Tampilkan</p>
                    <Card onClick={(event) => openMenu(event, 'limit')} className="pa-3 flexy d-flex rounded-lg txt_table cursor_pointer" style={{ color: theme.primary }}>
                        <div className='font-weight-bold'>{state.limit} Data</div>
                    </Card>
                    <Menu
                        className='mt-2 rounded-lg'
                        id="limit"
                        anchorEl={anchor.limit}
                        open={openLimit}
                        onClose={() => setAnchor({ ...anchor, limit: null })}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                        PaperProps={{ sx: { borderRadius: '8px' } }}
                    >
                        {listLimit.map((option, index) => (
                            <MenuItem
                                key={index}
                                selected={option === state.limit}
                                onClick={() => handleLimit(option)}
                                style={{ width: '150px' }}

                            >
                                <div className="d-flex align-center justify-space-between cursor_pointer" style={{ width: "100%" }}>
                                    <p className='font-weight-bold txt_table' style={{ color: theme.primary }}>{option} Data</p>
                                    {option === state.limit ? <Check fontSize="small" color="primary" /> : <></>}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ height: '24px' }}></div>
                    <Card className="d-flex align-center justify-space-between rounded-lg pr-1"
                        elevation={0}
                        style={{ border: `solid 2px ${focused ? theme.primary : theme.secondary}`, background: `${focused ? '' : theme.secondary}` }}>
                        <input type="text"
                            placeholder='Search'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            value={state.find}
                            onChange={handleChange('find')}
                            className='search_input'
                            style={{ background: focused ? '' : theme.secondary }} />
                        {state.find !== '' ? <Cancel onClick={() => resetFind()} onMouseDown={(event) => event.preventDefault()} fontSize="small" color='secondary' className='mr-2 cursor_pointer' /> : <></>}
                        <Button onClick={() => setFind(state.find)} onMouseDown={(event) => event.preventDefault()} variant={focused ? 'contained' : 'flat'} color={focused ? 'primary' : 'secondary'} style={{ minWidth: '0', padding: '4px' }} >
                            <Search fontSize="small" color={focused ? 'white' : 'primary'} />
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <div style={{ height: '24px' }}></div>
                    <Card className="pa-3 d-flex rounded-lg cursor_pointer"
                        onClick={(event) => openMenu(event, 'filter')}
                        style={{ color: count ? '#fff' : theme.primary, width: 'max-content', background: count ? theme.primary : '' }}>
                        <FilterAlt className='mr-2' fontSize='small' />
                        <div className='txt_table d-flex align-center '>
                            <p className='font-weight-bold mr-2'>Filter</p>
                            {count ? <div className='badge' style={{ color: theme.primary }}>{count}</div> : <></>}
                        </div>
                    </Card>
                    <Menu
                        className='mt-2 rounded-lg'
                        id="filter"
                        anchorEl={anchor.filter}
                        open={openFilter}
                        onClose={() => setAnchor({ ...anchor, filter: null })}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'div',
                        }}
                        PaperProps={{ sx: { borderRadius: '8px', width: '200px' } }}
                    >
                        <div className="px-3 d-flex align-center justify-space-between">
                            <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                                Filter
                            </p>
                            <p className="font-weight-bold small_txt cursor_pointer" style={{ color: theme.secondary }} onClick={() => resetFilter('both')}>
                                Reset
                            </p>
                        </div>
                        <div className="px-3 mt-3">
                            <p className="font-weight-bold txt_table mb-1" style={{ color: theme.primary }}>
                                Jenis Kelamin
                            </p>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={state.gender}
                                    onChange={handleChange('gender')}
                                >
                                    <FormControlLabel value="L" control={<Radio size='small' style={{ padding: '6px' }} />} label="Laki-laki" />
                                    <FormControlLabel value="P" control={<Radio size='small' style={{ padding: '6px' }} />} label="Perempuan" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <Divider sx={{ my: 0.5 }} />
                        <div className="px-3 mt-3">
                            <p className="font-weight-bold txt_table mb-1" style={{ color: theme.primary }}>
                                Profesi
                            </p>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={state.profesi}
                                    onChange={handleChange('profesi')}
                                >
                                    <FormControlLabel value="Siswa/Mahasiswa" control={<Radio size='small' style={{ padding: '6px' }} />} label="Siswa/Mahasiswa" />
                                    <FormControlLabel value="Pekerja" control={<Radio size='small' style={{ padding: '6px' }} />} label="Pekerja" />
                                </RadioGroup>
                            </FormControl>
                            <Button variant='contained' size='small' className='capitalize mt-2' style={{ width: '100%' }} onClick={() => handleFilter()}>Terapkan</Button>
                        </div>
                    </Menu>
                </Grid>
            </Grid>
            <Paper className='pa-3 rounded-lg'>
                {!loading ? !dataSet.length ? <EmptyData /> : <TablelistSchedule theme={theme} ava={ava} dataSet={dataSet} /> : <SkeletonTable txt={emptyTxt} />}
                {dataSet.length ? <div className="d-flex align-center justify-space-between" style={{ width: '100%' }}>
                    <p className='txt_table'>Menampilkan {state.page} - {state.limit} dari {state.total} </p>
                    <div>
                        <Pagination sx={{ padding: '0' }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: '#fff' }} color="primary" />
                    </div>
                </div> : <></>}
            </Paper>
        </div >
    )
}

export default ListScheduleCounseling
