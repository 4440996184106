/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
  tooltipClasses,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import { DialogDelete } from "../../../components/base/dialogDelete";

const PsikologList = ({ theme, dataSet, ava, onChangeStatus, refetch }) => {
  const [dialog, setDialog] = useState(false);
  const [pocket, setPocket] = useState(null);
  const [formateDate, setDate] = useState(null);
  const navigate = useNavigate();

  const deleteData = item => {
    setPocket({
      path: 'admin/psikolog-coach/delete',
      body: {
        id: item.id
      }
    })
    setDialog(true)
  }
  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-2"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  User ID
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "200px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Nama Konselor
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Email Konselor
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Nomor Hp
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Jenis
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Tgl. Registrasi
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  <div className="d-flex align-center">
                    <Avatar alt={item.nama} src={item.foto_link ? item.foto_link : ava} className="mr-3" />
                    <p className=""> {item.nama} </p>
                  </div>
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.no_telp}</TableCell>
                <TableCell>{item.jenis}</TableCell>
                <TableCell>
                  <p className="small_txt">
                    {day(item.created_at).format("DD/MM/YY | HH:mm")}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => onChangeStatus(item)}>
                    {item.status == 1 ? (
                      <Icon
                        path={mdiToggleSwitch}
                        size={1}
                        color={theme.primary}
                      />
                    ) : (
                      <Icon path={mdiToggleSwitchOffOutline} size={1} />
                    )}
                  </Button>
                </TableCell>
                <TableCell>
                  <div className="d-flex">
                    <Button
                      variant="contained"
                      className="rounded-xl capitalize mr-3"
                      onClick={() =>
                        navigate(`/counselor/detail/${item.id}`)
                      }
                      disableElevation
                      size="small"
                      style={{ background: theme.act_blue }}
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.act_lbl_blue }}
                      >
                        Detail
                      </p>
                    </Button>
                    <Button
                      variant="contained"
                      className="rounded-xl capitalize"
                      onClick={() => deleteData(item)}
                      disableElevation
                      size="small"
                      style={{ background: theme.act_red }}
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.act_lbl_red }}
                      >
                        Delete
                      </p>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
      </div>
    </div>
  );
};

export default PsikologList;
