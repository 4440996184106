import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzqk8Wh2CR7abQK4Pwrf1E3TvCtRoCyx8",
  authDomain: "temanbakat-development.firebaseapp.com",
  projectId: "temanbakat-development",
  storageBucket: "temanbakat-development.appspot.com",
  messagingSenderId: "211053518311",
  appId: "1:211053518311:web:fde914a70e0bfd24733142",
  measurementId: "G-8BGQNQHC1N",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const analytics = getAnalytics(app);

export { db };
