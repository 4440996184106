/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CHANGE_TITLE } from '../../../store/theming'
import { useDispatch, useSelector } from 'react-redux'
import { Backdrop, Button, CircularProgress, Grid, Paper } from '@mui/material'
import styleInput from '../../../assets/css/search.module.css'
import Select from 'react-select';

/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'
import PickAlatTest from './components/pickAlatTest'
import PickKonsultasi from './components/pickKonsultasi'
import PickAngket from './components/pickAngket'
import { getApi, postApi } from '../../../store/apis'
import { ChevronLeft } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const PsikotesCreate = () => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: state.isFocused ? '' : '#FBF4FE',
            borderRadius: '5px',
            border: 'none',
            outline: state.isFocused ? `2px solid ${theme.primary}` : '',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            padding: '3px',
        }),
        input: (provided, state) => ({
            ...provided,
            outline: state.isFocused ? '2px solid #FBF4FE' : 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: '5px',
            background: state.isSelected ? '#FBF4FE' : '',
            color: state.isSelected ? '#000' : '',
            ':hover': {
                background: '#FBF4FE',
            },
        }),
    }

    const [dp, setDp] = useState(null)
    const [alatTest, setAlatTest] = useState([])
    const [angket, setAngket] = useState([])
    const [konsultasi, setKonsultasi] = useState([])
    const [form, setForm] = useState({
        keterangan: '',
        nama_psikotes: '',
        harga_normal: '',
        potongan_harga: '',
        id_kategori: '',
        cover: '',
    })

    const handleChange = (prop) => (event) => {
        if (prop == 'id_kategori') {
            console.log(event.value)
            setForm({ ...form, [prop]: event.value });
        } else {
            setForm({ ...form, [prop]: event.target.value });
        }
    };

    const renderImg = e => {
        const file = e.target.files[0];
        const fr = new FileReader();
        fr.onload = (f) => {
            setDp(f.target.result)
        };
        setForm({ ...form, cover: file });
        fr.readAsDataURL(file);
    }
    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])

    const fetchData = () => {
        setLoading(true)
        let data = {
            path: `admin/psikotes-setting/kategori`
        }
        dispatch(getApi(data)).then(data => {
            let rawData = JSON.parse(JSON.stringify(data.data))
            let tempData = []
            rawData.forEach(el => {
                tempData.push({
                    value: el.id,
                    label: el.kategori
                })
            })
            setCategories(tempData)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setCategories([])
        })
    }

    const createData = () => {
        setLoading(true)
        let val_alat_test = alatTest.length ? alatTest.map(el => el.id) : []
        let val_konsultasi = konsultasi.length ? konsultasi.map(el => el.id) : []
        let val_angket = angket.length ? angket.map(el => el.angket_terbuka_id) : []

        let data = {
            path: `admin/psikotes-setting/psikotes/create`,
            body: {
                nama_psikotes: form.nama_psikotes,
                keterangan: form.keterangan,
                harga_normal: form.harga_normal,
                potongan_harga: form.potongan_harga,
                id_kategori: form.id_kategori,
                id_alat_test: JSON.stringify(val_alat_test),
                id_konsultasi: JSON.stringify(val_konsultasi),
                id_angket: JSON.stringify(val_angket),
            }
        }
        dispatch(postApi(data)).then(data => {
            console.log(data.id, data.data.id)
            navigate(`/psikotes/detail/${data.data.psikotes.id}`)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        dispatch(CHANGE_TITLE('Tambah Psikotes'))
        fetchData()
    }, [])

    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg mb-3"
                onClick={() => navigate(-1)}
            >
                <ChevronLeft /> Kembali
            </Button>
            <Grid container spacing={2} >
                <Grid item xs={3}>
                    <Paper elevation={0} className='rounded-lg pa-3'>
                        {dp ? <div className='d-flex justify-center'><img src={dp} height='150px' alt='cover-psikotest' /></div> :
                            <div className="d-flex align-center justify-center" style={{ height: '100px' }}>
                                <p className='font-weight-bold text-center' style={{ color: theme.primary, fontWeight: '500' }}>Cover Psikotes</p>
                            </div>}
                        <div className="d-flex justify-center">
                            <Button
                                variant="outlined"
                                className='capitalize'
                                component="label"
                            >
                                <p className='font-weight-bold'>Unggah Gambar</p>
                                <input hidden onChange={renderImg} accept="image/*" multiple type="file" />
                            </Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper elevation={0} className='rounded-lg pa-3 mb-3'>
                        <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>Detail Psikotes</p>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Judul Psikotes</p>
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    placeholder='Judul Psikotes'
                                    value={form.nama_psikotes}
                                    onChange={handleChange('nama_psikotes')}
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Keterangan Psikotes</p>
                            </Grid>
                            <Grid item xs={9}>
                                <textarea
                                    placeholder='Keterangan Psikotes'
                                    value={form.keterangan}
                                    onChange={handleChange('keterangan')}
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Harga Normal</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    placeholder='Harga Normal'
                                    value={form.harga_normal}
                                    onChange={handleChange('harga_normal')}
                                    type='number'
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Harga Promo</p>
                                <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila tidak ada</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    placeholder='Harga Promo'
                                    value={form.potongan_harga}
                                    onChange={handleChange('potongan_harga')}
                                    type='number'
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Kategori Psikotes</p>
                            </Grid>
                            <Grid item xs={9}>
                                {!loading ?
                                    <Select
                                        styles={customStyles}
                                        classNamePrefix="select"
                                        options={categories}
                                        defaultValue={form.id_kategori}
                                        onChange={handleChange('id_kategori')}
                                        name="color"
                                        css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    /> : <></>}

                            </Grid>
                        </Grid>

                    </Paper>
                    <Paper elevation={0} className='rounded-lg pa-3 mb-3'>
                        <p style={{ color: theme.primary, fontWeight: '500' }}>Alat Tes</p>
                        <PickAlatTest picked={alatTest} setPicked={setAlatTest} />
                    </Paper>
                    <Paper elevation={0} className='rounded-lg pa-3 mb-3'>
                        <p style={{ color: theme.primary, fontWeight: '500' }}>Konsultasi</p>
                        <PickKonsultasi picked={konsultasi} setPicked={setKonsultasi} />
                    </Paper>
                    <Paper elevation={0} className='rounded-lg pa-3 mb-3'>
                        <p style={{ color: theme.primary, fontWeight: '500' }}>Angket Terbuka</p>
                        <PickAngket picked={angket} setPicked={setAngket} />
                    </Paper>
                    <div className="d-flex justify-end">
                        {loading ?
                            <Button className='rounded-lg font-weight-bold capitalize' variant='contained' disableElevation>Tunggu sebentar...</Button>
                            : <Button className='rounded-lg font-weight-bold capitalize' variant='contained' disableElevation onClick={createData}>Tambah Psikotes</Button>}
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default PsikotesCreate
