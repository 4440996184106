import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import { postApi } from '../../../../store/apis'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux'
import { Close as CloseIcon, ShortText, Notes } from '@mui/icons-material';
import InputWithIcon from '../../../../components/base/inputWithIcon';
import AreaWithIcon from '../../../../components/base/areaWithIcon';

const PostKategoriPsikotes = ({ dialog = false, close, refetch, pocket, form, setForm }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const [state, setState] = useState({
        loading: false,
        kategori: '',
        keterangan: '',
    })
    const handleChange = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    }

    const postData = () => {
        setState({ ...state, loading: true })
        let body = {
            kategori: form.kategori,
            keterangan: form.keterangan
        }
        if (pocket.type === 'update') {
            body['id_kategori'] = form.id_kategori
        }
        let data = {
            path: pocket.path,
            body: body
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setState({ ...state, loading: false, })
            setForm({ ...state, kategori: '', keterangan: '' })
            close()
            refetch()
        }).catch(err => {
            console.log(err)
            setState({ ...state, loading: false })
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }
    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10 }
            }}
        >
            <DialogTitle id="alert-dialog-title" className='py-3'>
                <div className="d-flex justify-space-between">
                    <h4>{pocket ? pocket.title : ''}</h4>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent >
                {form ?
                    <div className='borbox' style={{ width: '400px', padding: '5px' }}>
                        <div className="mb-4">
                            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Nama Kategori</p>
                            <InputWithIcon value={form.kategori} icon={<ShortText color='primary' />} handleChange={handleChange} property='kategori' />
                        </div>
                        <div className="mb-4">
                            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Keterangan Kategori</p>
                            <AreaWithIcon value={form.keterangan} icon={<Notes color='primary' />} handleChange={handleChange} property='keterangan' />
                        </div>
                        <div className="d-flex justify-end">
                            {!state.loading ?
                                <Button variant='contained' className='capitalize rounded-lg' disableElevation onClick={() => postData()}><p className="font-weight-bold">Submit</p></Button> :
                                <Button variant='contained' className='capitalize rounded-lg' disableElevation><p className="font-weight-bold">Loading...</p></Button>}
                        </div>
                    </div> : <></>
                }
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

export default PostKategoriPsikotes
