const kriteria = [
  "all",
  "new_member",
  "poin_kurang_dari_20",
  "umur_kurang_dari_20",
];

const buttonVisible = [
  {
    label: "No Button",
    value: "none",
  },
  {
    label: "Single Button",
    value: "single",
  },
  {
    label: "Vertical Double Button",
    value: "vertical_double",
  },
  {
    label: "Horizontal Double Button",
    value: "horizontal_double",
  },
];

const defaultValueButton = {
  label: "",
  label_color: "",
  bg_color: "",
  action: "",
};

const action = [
  { value: "close", label: "Close Pop Up" },
  { value: "feature", label: "Open Feature" },
  { value: "link", label: "Embed Link" },
];

const repeatType = [
  { value: "day", label: "Daily" },
  { value: "week", label: "Weekly" },
  { value: "month", label: "Monthly" },
];

const arrDay = ["senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: state.isFocused || state.hasValue ? "" : "#FBF4FE",
    borderRadius: "5px",
    border: "none",
    outline: `2px solid #FBF4FE`,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "5px",
    padding: "3px",
  }),
  input: (provided, state) => ({
    ...provided,
    outline: state.isFocused ? "2px solid #FBF4FE" : "none",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    background: state.isSelected ? "#FBF4FE" : "",
    color: state.isSelected ? "#000" : "",
    ":hover": {
      background: "#FBF4FE",
    },
  }),
};

export {
  kriteria,
  defaultValueButton,
  action,
  buttonVisible,
  customStyles,
  repeatType,
  arrDay,
};
