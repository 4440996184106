/* eslint-disable eqeqeq */
import { DragIndicator, Delete as DeleteIcon, RadioButtonUnchecked, RadioButtonChecked, Add as AddIcon } from '@mui/icons-material';
import { Button, IconButton, Paper } from '@mui/material';
import React, { Component } from 'react';
import DraggableList from "react-draggable-list";
import FlatInput from '../../../../components/base/flatInput';
import Select from 'react-select';
import BlankInput from '../../../../components/base/blankInput';



class Item extends Component {
    render() {
        const { item, itemSelected, dragHandleProps, commonProps } = this.props;
        const { onMouseDown, onTouchStart } = dragHandleProps;
        const { section, i } = item
        const { handleInput, removeQuestion, theme, addOption } = commonProps
        const listType = [
            { label: 'Essay', value: 'text' },
            { label: 'Option', value: 'choices' },
            { label: 'Task', value: 'file' },
        ]
        const customStyles = {
            control: (provided) => ({
                ...provided,
                borderRadius: '6px',
                border: 'none',
                outline: `2px solid ${theme.tertiary}`,
                fontSize: 'small'
            }),
            menu: () => ({
                label: "menu",
                top: "100%",
                backgroundColor: "hsl(0, 0%, 100%)",
                boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                marginBottom: 8,
                marginTop: 8,
                position: "absolute",
                width: "100%",
                zIndex: 2,
                boxSizing: "border-box",
                borderRadius: '5px',
                padding: '1px',
                fontSize: 'small',
            }),
            input: (provided, state) => ({
                ...provided,
                outline: state.isFocused ? `2px solid ${theme.tertiary}` : 'none',
            }),
            option: (provided, state) => ({
                ...provided,
                borderRadius: '5px',
                zIndex: 'auto',
                background: state.isSelected ? '#FBF4FE' : '',
                color: state.isSelected ? '#000' : '',
                ':hover': {
                    background: '#FBF4FE',
                },
            }),
        }
        return (
            <Paper className='rounded-lg mb-2 d-flex' style={{ boxSizing: 'border-box' }}>
                <div className="pa-3 d-flex align-start flexy">
                    <div className="d-flex">
                        <IconButton color='primary' onClick={() => removeQuestion(i)}>
                            <DeleteIcon />
                        </IconButton>
                        <div className="flexy mr-3" style={{ width: '115px' }}>
                            <Select
                                styles={customStyles}
                                classNamePrefix="select"
                                options={listType}
                                defaultValue={section.type}
                                name="Tipe Pertanyaan"
                                onChange={handleInput({ idx: i, level: 'item', type: 'select', label: 'type' })}
                            />
                        </div>
                    </div>
                    <div className="flexy mr-1" style={{ marginTop: '1px' }}>
                        <FlatInput
                            value={section.pertanyaan}
                            handleChange={handleInput}
                            property={{ idx: i, level: 'item', label: 'pertanyaan' }}
                            placeholder='Pertanyaan' />
                        { }
                        {section.type.value == 'choices' ?
                            <div className="my-3">
                                {section.pilihan.length ? section.pilihan.map((opt, k) =>
                                    <div key={`opsi-${k}`} className='d-flex align-center mb-2'>
                                        {section.jawaban !== opt ?
                                            <RadioButtonUnchecked
                                                fontSize='small'
                                                color='primary'
                                                className='mr-2 cursor_pointer'
                                                onClick={handleInput({ idx: i, opt: opt, label: 'jawaban', level: 'answerOpt', })} /> :
                                            <RadioButtonChecked className='mr-2'
                                                color='primary'
                                                fontSize='small' />}
                                        <BlankInput
                                            property={{ idx: i, idx_opt: k, label: 'pilihan', level: 'opsi', }}
                                            value={opt}
                                            handleChange={handleInput}
                                            placeholder='Opsi'
                                            idEl={`opt${i}${k}`}
                                        />
                                        {section.jawaban == opt ? <p className="small_txt"> (Terpilih sebagai jawaban) </p> : <></>}
                                    </div>)
                                    : <></>}
                                <Button className='capitalize ' onClick={() => addOption(i)}><AddIcon fontSize='small' />
                                    <p className="font-weight-bold small_txt ml-1">Tambah Pilihan</p>
                                </Button>
                            </div>
                            : section.type.value == 'text' ? <div className='my-3'>
                                <FlatInput
                                    value={section.jawaban}
                                    handleChange={handleInput}
                                    property={{ idx: i, level: 'item', label: 'jawaban' }}
                                    placeholder='Jawaban' />
                            </div> : <></>}
                    </div>
                </div>
                <div className="pa-1 d-flex align-center"
                    style={{ background: theme.tertiary, minHeight: '100%', borderRadius: '0 8px 8px 0' }}>
                    <IconButton
                        className='grabbable'
                        onTouchStart={(e) => {
                            e.preventDefault();
                            document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                        onMouseDown={(e) => {
                            document.body.style.overflow = "hidden";
                            onMouseDown(e);
                        }}
                        onTouchEnd={(e) => {
                            e.target.style.backgroundColor = "black";
                            document.body.style.overflow = "visible";
                        }}
                        onMouseUp={() => {
                            document.body.style.overflow = "visible";
                        }}>
                        <DragIndicator />
                    </IconButton>
                </div>
            </Paper>
        )
    }
}

class DraggableQuiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            useContainer: false,
        };
    }

    render() {
        const { useContainer } = this.state;
        const { section, _onListChange, handleInput, removeQuestion, theme, addOption } = this.props
        return (
            <DraggableList
                itemKey={i => i.section.id}
                unsetZIndex={true}
                commonProps={{
                    theme: theme,
                    handleInput: handleInput,
                    removeQuestion: removeQuestion,
                    addOption: addOption
                }}
                template={Item}
                list={section.map((section, i) => ({ section, i }))}
                onMoveEnd={(newList) => _onListChange(newList.map(el => el.section))}
                container={() => (useContainer ? this._container : document.body)}
            />
        );
    }
}

export default DraggableQuiz;