/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi, postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import {
  Grid,
  Paper,
  Button,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Person,
  Email,
  LocationOn,
  PhoneIphone,
  Phone,
  ChevronLeft,
  GridView,
  Business,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InputWithIcon from "../../../components/base/inputWithIcon";
import SelectWithIcon from "../../../components/base/selectWithIcon";
import AreaWithIcon from "../../../components/base/areaWithIcon";

const CompanyEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState({
    nama: state.nama,
    jenis: state.jenis,
    email: state.email,
    alamat: state.alamat,
    no_kontak: state.no_kontak,
    no_hp: state.no_hp,
    nama_klien: state.data_klien?.nama || "",
    email_klien: state.data_klien?.email || "",
    no_telp_klien: state.data_klien?.no_telp || "",
    permission: state.data_klien?.permission || [],
  });
  const [listAcc, setListAcc] = useState([]);
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const listJenis = ["Profesional", "Pendidikan", "Pelajar"];

  const postData = () => {
    setLoading(true);
    const body = {
      path: "admin/company/update",
      body: {
        id,
        ...data,
        permission: JSON.stringify(data.permission),
      },
    };

    dispatch(postApi(body))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Company nerhasil diubah", { variant });
        navigate(-1);
      })
      .catch((err) => {
        const variant = "error";
        const error = err.data?.errors || false;
        if (error) {
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(`${message} ${error[message]}`, { variant });
          });
        }
        enqueueSnackbar(err.message ?? "Company Gagal diubah", { variant });
      });
    setLoading(false);
  };

  const changeTab = (e) => {
    setTab(e.target.id);
  };

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleCheck = (item) => (event) => {
    const { checked } = event.target;
    if (checked) {
      handleChange("permission")({
        target: {
          value: [...data.permission, item],
        },
      });
    } else {
      handleChange("permission")({
        target: {
          value: data.permission.filter((val) => val !== item),
        },
      });
    }
  };

  const fetchPermission = () => {
    let data = {
      path: `admin/permission/company`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setListAcc(data);
      })
      .catch((err) => {
        setListAcc([]);
      });
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Ubah Profile Company/Institution"));
    fetchPermission();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div>
            <div className="d-flex justify-space-between align-center mb-3">
              <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg"
                onClick={() => navigate(-1)}
              >
                <ChevronLeft /> Kembali
              </Button>
            </div>
            {state ? (
              <Paper
                className="d-flex flex-column pa-3 rounded-lg"
                elevation={0}
                style={{ height: "600px" }}
              >
                <Box
                  className="mb-3"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={tab}
                    onChange={changeTab}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                      id="1"
                      className="capitalize"
                      label="Informasi Client & Penanggung Jawab"
                      value="1"
                    />
                    <Tab
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                      id="2"
                      className="capitalize"
                      label="Access Permission"
                      value="2"
                    />
                  </Tabs>
                </Box>
                <div className="flexy">
                  {tab === "1" && (
                    <Grid container spacing={2}>
                      <Grid item xs={5.8}>
                        <p className="mb-2" style={{ color: theme.primary }}>
                          Informasi Client
                        </p>

                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nama Company/Institution
                          </p>
                          <InputWithIcon
                            value={data.nama}
                            icon={<Business color="primary" />}
                            handleChange={handleChange}
                            property="nama"
                          />
                        </div>

                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Jenis Company/Institution
                          </p>
                          <SelectWithIcon
                            value={data.jenis}
                            handleChange={handleChange}
                            list={listJenis}
                            icon={<GridView color="primary" />}
                            property="jenis"
                          />
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Email Aktif
                          </p>
                          <InputWithIcon
                            value={data.email}
                            icon={<Email color="primary" />}
                            handleChange={handleChange}
                            property="email"
                          />
                        </div>
                        <div className="d-flex flex-row mb-3">
                          <div className="mr-1">
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Nomor Telepon
                            </p>
                            <InputWithIcon
                              value={data.no_kontak}
                              icon={<Phone color="primary" />}
                              handleChange={handleChange}
                              type="number"
                              property="no_kontak"
                            />
                          </div>
                          <div className="ml-1">
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Nomor Hp
                            </p>
                            <InputWithIcon
                              value={data.no_hp}
                              icon={<PhoneIphone color="primary" />}
                              handleChange={handleChange}
                              type="number"
                              property="no_telp"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Alamat
                          </p>
                          <AreaWithIcon
                            value={data.alamat}
                            icon={<LocationOn color="primary" />}
                            handleChange={handleChange}
                            property="alamat"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={0.4} />
                      <Grid item xs={5.8}>
                        <p className="mb-2" style={{ color: theme.primary }}>
                          Informasi Penanggung Jawab
                        </p>

                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nama Penanggung Jawab
                          </p>
                          <InputWithIcon
                            value={data.nama_klien}
                            icon={<Person color="primary" />}
                            handleChange={handleChange}
                            property="nama_klien"
                          />
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Email Aktif
                          </p>
                          <InputWithIcon
                            value={data.email_klien}
                            icon={<Email color="primary" />}
                            handleChange={handleChange}
                            property="email_klien"
                          />
                        </div>
                        <div className="mb-3">
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Nomor Hp
                          </p>
                          <InputWithIcon
                            value={data.no_telp_klien}
                            icon={<PhoneIphone color="primary" />}
                            handleChange={handleChange}
                            type="number"
                            property="no_telp_klien"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {tab === "2" && (
                    <div>
                      <p style={{ color: theme.primary }}>Access Permission</p>
                      <p
                        className="small_txt mb-2"
                        style={{ color: "#404040" }}
                      >
                        Beri centang untuk fitur yang bisa diakses klien
                      </p>
                      <div className="d-flex flex-column">
                        {listAcc.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            label={item.name}
                            control={
                              <Checkbox
                                checked={data.permission.includes(item.name)}
                                onChange={handleCheck(item.name)}
                              />
                            }
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-end">
                  <Button
                    onClick={postData}
                    variant="contained"
                    className="capitalize rounded-lg"
                    disableElevation
                  >
                    <p className="font-weight-bold">Simpan</p>
                  </Button>
                </div>
              </Paper>
            ) : (
              <EmptyData />
            )}
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyEdit;
