/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip, tooltipClasses, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Icon from "@mdi/react"
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js"
import day from 'dayjs'
import LinesEllipsis from 'react-lines-ellipsis'
import { DialogDelete } from '../../../components/base/dialogDelete'

const TrainingActivityList = ({ theme, dataSet, page, limit, refetch }) => {
    const [dialog, setDialog] = useState(false)
    const [pocket, setPocket] = useState(null)
    const navigate = useNavigate()
    const deleteData = item => {
        setPocket({
            path: 'admin/training/delete',
            body: {
                id: item.id
            }
        })
        setDialog(true)
    }

    return (
        <div>
            <TableContainer component='div'>
                <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ minWidth: '50px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>No.</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Judul Training</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1' >
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Pelaksanaan</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Pemateri</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Jenis</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Jml. Peserta</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '130px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tipe Peserta</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Show</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{i + 1 + (page - 1) * limit}</TableCell>
                                <TableCell>
                                    <Tooltip title={item.judul} placement="top" followCursor={true} arrow>
                                        <div>
                                            <LinesEllipsis
                                                className="small_txt"
                                                text={item.judul}
                                            />
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell >
                                    {item.pelaksanaan.length ?
                                        <p className="small_txt">
                                            {day(item.pelaksanaan[0].tanggal_mulai).format('DD/MM/YYYY')} {item.pelaksanaan[0].wkt_mulai.slice(0, 5)} - {item.pelaksanaan[0].wkt_selesai.slice(0, 5)}
                                        </p> : <></>}
                                </TableCell>
                                <TableCell>
                                    <div className="d-flex">
                                        {item.pemateri_training.length ?
                                            <div className="box_pemateri flexy mr-1">
                                                <LinesEllipsis className="small_txt text-center" text={item.pemateri_training[0].detail.nama} />
                                            </div> : <></>}
                                        {item.pemateri_training.length > 1 ? <div className='box_pemateri small_txt'> +{item.pemateri_training.length - 1} </div> : <></>}
                                    </div>
                                </TableCell>
                                <TableCell align="center">{item.jenis_training}</TableCell>
                                <TableCell align="center">{item.kuota}</TableCell>
                                <TableCell align="center">
                                    {item.kategori_peserta.length ?
                                        <p className="small_txt">
                                            {item.kategori_peserta.map((el, i) => `${el.kategori.nama}${i == item.kategori_peserta.length - 1 ? '' : ', '}`)}
                                        </p> : <></>}
                                </TableCell>
                                <TableCell align="center">
                                    <Button>
                                        {item.status_show == 'open' ? (
                                            <Icon
                                                path={mdiToggleSwitch}
                                                size={1}
                                                color={theme.primary}
                                            />
                                        ) : (
                                            <Icon path={mdiToggleSwitchOffOutline} size={1} />
                                        )}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <div className="d-flex">
                                        <Button
                                            variant='contained'
                                            className='rounded-lg capitalize mr-3'
                                            onClick={() => navigate(`/training-detail/${item.id}`)}
                                            disableElevation size="small"
                                            style={{ background: theme.act_cyan }}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_cyan }}>View</p>
                                        </Button>
                                        <Button
                                            variant='contained'
                                            className='rounded-lg capitalize mr-3'
                                            onClick={() => navigate(`/counselor/detail/${item.id_konselor}`)}
                                            disableElevation size="small"
                                            style={{ background: theme.act_orange }}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_orange }}>Resch.</p>
                                        </Button>
                                        <Button
                                            variant='contained'
                                            className='rounded-lg capitalize'
                                            onClick={() => deleteData(item)}
                                            disableElevation size="small"
                                            style={{ background: theme.act_red }}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_red }}>Delete</p>
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pa-2">
                <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
            </div>
        </div>
    )
}

export default TrainingActivityList
