import "./App.css";
import RouterSetup from "./components/router/RouterSetup";
import { ThemeProvider } from "@mui/system";
import theme from "./theme/theme";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getRole } from "./store/theming";
import { SnackbarProvider } from "notistack";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    let role = localStorage.getItem("role");
    dispatch(getRole(role));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={3000} maxSnack={10}>
          <RouterSetup />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
