/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Backdrop,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { postApi, getApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  Close as CloseIcon,
  Person,
  Email,
  Key,
  DateRange,
  PhoneIphone,
  ChevronLeft,
  ChevronRight,
  Check,
  AccountCircle,
  GridView,
  Add,
} from "@mui/icons-material";
import InputWithIcon from "../../../components/base/inputWithIcon";
import css from "../../../assets/css/search.module.css";
import SelectWithIcon from "../../../components/base/selectWithIcon";

const PsikologCreate = ({ d_create = false, close, refetch }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const listCategory = [
    { name: "Psikolog", id: 1 },
    { name: "Coach", id: 2 },
    { name: "Psikolog & Coach", id: 3 },
  ];

  const [keahlianPsy, setKeahlianPsy] = useState([]);

  const [state, setState] = useState({
    username: "",
    password: "",
    nama: "",
    tgl_lahir: "",
    email: "",
    no_telp: "",
    jenis_kelamin: "",
    jenis: "",
    kategori: "",
    group: "",
    no_anggota_himpsi: "",
    ssp: "",
    sipp: "",
    bidang_keahlian: [],
    permission: [],
  });

  const [listAcc, setListAcc] = useState([]);
  const [loadAcc, setLoadAcc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bidangKeahlianPsy, setBidangPsy] = useState([]);
  const [bidangKeahlianCoach, setBidangCoach] = useState([""]);

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleCheck = (item, type) => (e) => {
    const { checked } = e.target;
    let permission = [...state.permission];
    if (type === "permission") {
      if (checked) {
        permission.push(item);
        handleChange("permission")({ target: { value: permission } });
      } else {
        permission = permission.filter((a) => a !== item);
        handleChange("permission")({ target: { value: permission } });
      }
    } else {
      if (checked) {
        setBidangPsy([...bidangKeahlianPsy, item]);
      } else {
        setBidangPsy(bidangKeahlianPsy.filter((a) => a !== item));
      }
    }
  };

  const onValueChangeCoach = (index) => (event) => {
    let expertiseCoach = [...bidangKeahlianCoach];
    expertiseCoach[index] = event.target.value;
    setBidangCoach(expertiseCoach);
  };

  const addIndexExpertiseCoach = () => {
    setBidangCoach([...bidangKeahlianCoach, ""]);
  };

  const switchStep = (state) => {
    if (state == "next") {
      postData(step, state);
    } else {
      postData(step - 1, state);
    }
  };

  const listStep = [1, 2, 3, 4];
  const [step, setStep] = useState(0);
  const fetchPermission = () => {
    setLoadAcc(true);
    let data = {
      path: `admin/permission/psikolog`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoadAcc(false);
        setListAcc(data);
      })
      .catch((err) => {
        setLoadAcc(false);
        setListAcc([]);
      });
  };

  const fetchExpertise = () => {
    let data = {
      path: `admin/expertise?filter[tipe]=konselor`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setKeahlianPsy(data);
      })
      .catch((err) => {
        setKeahlianPsy([]);
      });
  };

  const postData = (page = 0, direction) => {
    setLoading(true);
    let body = {
      step: page,
    };

    switch (page) {
      case 1:
        body = {
          step: page,
          username: state.username,
          password: state.password,
        };
        break;
      case 2:
        body = {
          step: page,
          nama: state.nama,
          tgl_lahir: state.tgl_lahir,
          email: state.email,
          no_telp: state.no_telp,
          jenis_kelamin: state.jenis_kelamin,
        };
        break;
      case 3:
        body = {
          step: page,
          jenis: state.jenis,
          kategori: state.kategori,
        };

        if (state.kategori == 1) {
          body["group"] = state.group;
          body["no_anggota_himpsi"] = state.no_anggota_himpsi;
          body["ssp"] = state.ssp;
          body["sipp"] = state.sipp;
          body["exp_sipp"] = state.exp_sipp;
          body["expertise"] = JSON.stringify(bidangKeahlianPsy);
        } else if (state.kategori == 2) {
          body["expertise_coach"] = JSON.stringify(bidangKeahlianCoach);
        } else {
          body["group"] = state.group;
          body["no_anggota_himpsi"] = state.no_anggota_himpsi;
          body["ssp"] = state.ssp;
          body["sipp"] = state.sipp;
          body["exp_sipp"] = state.exp_sipp;
          body["expertise"] = JSON.stringify(bidangKeahlianPsy);
          body["expertise_coach"] = JSON.stringify(bidangKeahlianCoach);
        }

        break;
      case 4:
        body = {
          step: page,
          permission: JSON.stringify(state.permission),
        };
        break;
    }
    let data = {
      path: "admin/psikolog-coach/store",
      body: body,
    };
    let variant = "";
    dispatch(postApi(data))
      .then((response) => {
        let res = response.data;
        if (res.data) {
          let step = parseInt(res.data.step);
          step = step > 0 ? step : 1;
          let tempForm = { ...state };
          delete tempForm["step"];
          if (page != 4) {
            res = {
              ...res.data,
              expertise: res.data.expertise
                ? JSON.parse(res.data.expertise)
                : [],
              expertise_coach: res.data.expertise_coach
                ? JSON.parse(res.data.expertise_coach)
                : [],
              permission: res.data.permission
                ? JSON.parse(res.data.permission)
                : [],
            };
            setState({ ...state, ...res, step });
            if (res.expertise) {
              if (res.expertise) {
                setBidangPsy(res.expertise);
              }
              if (res.expertise_coach) {
                setBidangCoach(
                  res.expertise_coach.length > 0 ? res.expertise_coach : [""]
                );
              }
            }
          }

          if (direction === "next") {
            variant = "success";
            if (page == 4) {
              setState({
                username: "",
                password: "",
                nama: "",
                tgl_lahir: "",
                email: "",
                no_telp: "",
                jenis_kelamin: "",
                jenis: "",
                kategori: "",
                group: "",
                no_anggota_himpsi: "",
                ssp: "",
                sipp: "",
                bidang_keahlian: [],
                permission: [],
              });
              setStep(1);
              close();
              refetch();
              enqueueSnackbar(data.message ?? "Akun berhasil dibuat", {
                variant,
              });
            } else {
              setStep(step + 1);
            }
          } else {
            setStep(step);
          }
        } else {
          setStep(step + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        if (err.status === "bad_request") {
          const error = err.data.errors;
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(error[message], { variant });
          });
        } else {
          enqueueSnackbar(err.message ?? "Akun gagal dibuat", { variant });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    postData();
    fetchPermission();
    fetchExpertise();
  }, []);

  return (
    <Dialog
      open={d_create}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Tambah Akun Psikolog</h4>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="styled_scroll" style={{ overflowX: "hidden" }}>
        <div
          className="borbox pa-3"
          style={{ width: "500px", padding: "5px", minHeight: "400px" }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="d-flex mb-3">
            {listStep.map((stp, i) => (
              <div
                key={`step-${i}`}
                style={{
                  border: `2px solid ${theme.lighten}`,
                  color: step === stp ? theme.primary : theme.lighten,
                  background:
                    step === stp
                      ? theme.lighten
                      : step > stp
                      ? theme.primary
                      : "#fff",
                }}
                className="steper mr-2"
              >
                {step > stp ? <Check fontSize="small" /> : stp}
              </div>
            ))}
          </div>
          {step == 1 ? (
            <div>
              <p
                className="font-weight-bold mb-3"
                style={{ color: theme.primary }}
              >
                Buat Akun Konselor
              </p>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Username
                </p>
                <InputWithIcon
                  value={state.username}
                  icon={<AccountCircle color="primary" />}
                  handleChange={handleChange}
                  property="username"
                />
              </div>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Password
                </p>
                <InputWithIcon
                  icon={<Key color="primary" />}
                  value={state.password}
                  handleChange={handleChange}
                  type="password"
                  property="password"
                />
              </div>
            </div>
          ) : step == 2 ? (
            <div>
              <p
                className="font-weight-bold mb-3"
                style={{ color: theme.primary }}
              >
                Informasi Konselor
              </p>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Nama Lengkap
                </p>
                <InputWithIcon
                  value={state.nama}
                  icon={<Person color="primary" />}
                  handleChange={handleChange}
                  property="nama"
                />
              </div>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Tanggal Lahir
                </p>
                <InputWithIcon
                  value={state.tgl_lahir}
                  icon={<DateRange color="primary" />}
                  handleChange={handleChange}
                  type="date"
                  property="tgl_lahir"
                />
              </div>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Email Aktif
                </p>
                <InputWithIcon
                  value={state.email}
                  icon={<Email color="primary" />}
                  handleChange={handleChange}
                  property="email"
                />
              </div>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Nomor Hp
                </p>
                <InputWithIcon
                  value={state.no_telp}
                  icon={<PhoneIphone color="primary" />}
                  handleChange={handleChange}
                  type="number"
                  property="no_telp"
                />
              </div>

              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Jenis Kelamin
                </p>
                <RadioGroup
                  name="rb-jenkel"
                  row
                  value={state.jenis_kelamin}
                  onChange={handleChange("jenis_kelamin")}
                >
                  <FormControlLabel
                    value="Perempuan"
                    control={<Radio />}
                    label="Perempuan"
                  />
                  <FormControlLabel
                    value="Laki-Laki"
                    control={<Radio />}
                    label="Laki-laki"
                  />
                </RadioGroup>
              </div>
            </div>
          ) : step == 3 ? (
            <div>
              <p
                className="font-weight-bold mb-3"
                style={{ color: theme.primary }}
              >
                Detail Konselor
              </p>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Jenis Konselor
                </p>
                <RadioGroup
                  name="rb-jenis"
                  row
                  value={state.jenis}
                  onChange={handleChange("jenis")}
                >
                  <FormControlLabel
                    value="Internal"
                    control={<Radio />}
                    label="Internal"
                  />
                  <FormControlLabel
                    value="Eksternal"
                    control={<Radio />}
                    label="Eksternal"
                  />
                </RadioGroup>
              </div>
              <div className="mb-4">
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Kategori
                </p>
                <SelectWithIcon
                  value={state.kategori}
                  list={listCategory}
                  handleChange={handleChange}
                  item_text="name"
                  item_value="id"
                  icon={<GridView color="primary" />}
                  property="kategori"
                />
              </div>
              {state.kategori && (
                <>
                  {(state.kategori === 1 || state.kategori === 3) && (
                    <div
                      className="mb-4 pa-3 rounded-lg"
                      style={{ background: theme.lighten }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <div
                            className="d-flex align-center"
                            style={{ height: "100%" }}
                          >
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Grup/Organisasi
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={css.icon_input}
                            value={state.group}
                            onChange={handleChange("group")}
                            style={{
                              background: theme.tertiary,
                              borderRadius: "0 5px 5px 0",
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div
                            className="d-flex align-center"
                            style={{ height: "100%" }}
                          >
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              No. Anggota
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={css.icon_input}
                            type="number"
                            onChange={handleChange("no_anggota_himpsi")}
                            value={state.no_anggota_himpsi}
                            style={{
                              background: theme.tertiary,
                              borderRadius: "0 5px 5px 0",
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div
                            className="d-flex align-center"
                            style={{ height: "100%" }}
                          >
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              No. SSP
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={css.icon_input}
                            value={state.ssp}
                            onChange={handleChange("ssp")}
                            style={{
                              background: theme.tertiary,
                              borderRadius: "0 5px 5px 0",
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div
                            className="d-flex align-center"
                            style={{ height: "100%" }}
                          >
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              No. SIPP
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={css.icon_input}
                            type="number"
                            value={state.sipp}
                            onChange={handleChange("sipp")}
                            style={{
                              background: theme.tertiary,
                              borderRadius: "0 5px 5px 0",
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div
                            className="d-flex align-center"
                            style={{ height: "100%" }}
                          >
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Masa Berlaku SIPP
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          <input
                            className={css.icon_input}
                            type="date"
                            value={state.exp_sipp}
                            onChange={handleChange("exp_sipp")}
                            style={{
                              background: theme.tertiary,
                              borderRadius: "0 5px 5px 0",
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Bidang Keahlian Psikolog
                          </p>
                        </Grid>
                        <Grid item xs={8}>
                          {keahlianPsy.map((item, i) => (
                            <FormControlLabel
                              key={i}
                              label={item.expertise}
                              control={
                                <Checkbox
                                  checked={bidangKeahlianPsy.includes(item.id)}
                                  onChange={handleCheck(item.id)}
                                />
                              }
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {(state.kategori === 2 || state.kategori === 3) && (
                    <div
                      className="mb-4 pa-3 rounded-lg"
                      style={{ background: theme.lighten }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <div
                            className="d-flex align-start"
                            style={{ height: "100%" }}
                          >
                            <p
                              className="font-weight-bold small_txt"
                              style={{ color: theme.primary }}
                            >
                              Bidang Keahlian Coach
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          {bidangKeahlianCoach.map((value, index) => {
                            return (
                              <input
                                key={index}
                                className={css.icon_input}
                                value={value}
                                onChange={onValueChangeCoach(index)}
                                style={{
                                  background: theme.tertiary,
                                  borderRadius: "0 5px 5px 0",
                                  marginBottom: 5,
                                }}
                              />
                            );
                          })}
                        </Grid>
                      </Grid>
                      <div className="d-flex justify-end">
                        <Button
                          variant="text"
                          onClick={addIndexExpertiseCoach}
                          className="capitalize rounded-lg px-0"
                          style={{ minWidth: "0", marginTop: 10 }}
                          disableElevation
                        >
                          <p
                            className="font-weight-bold small_txt"
                            style={{ color: theme.primary }}
                          >
                            Tambah Bidang
                          </p>
                          <Add />
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div>
              <p className="font-weight-bold" style={{ color: theme.primary }}>
                Access Permission
              </p>
              <p
                className="small_txt text-grey mb-3"
                style={{ color: theme.primary }}
              >
                Beri centang untuk fitur yang bisa diakses dan diubah konselor
              </p>
              <div className="mb-4">
                {loadAcc && listAcc.length ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <div>
                    {listAcc.map((item, i) => (
                      <FormControlLabel
                        key={i}
                        label={item.name}
                        control={
                          <Checkbox
                            checked={state.permission.includes(item.name)}
                            onChange={handleCheck(item.name, "permission")}
                          />
                        }
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-end px-5 pb-5">
          {step <= 1 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              onClick={() => switchStep("prev")}
              className="capitalize rounded-lg mr-2 px-0"
              style={{ minWidth: "0" }}
              disableElevation
            >
              {" "}
              <ChevronLeft />{" "}
            </Button>
          )}
          {step < 4 ? (
            <Button
              onClick={() => switchStep("next")}
              variant="contained"
              className="capitalize rounded-lg"
              style={{ minWidth: "0" }}
              disableElevation
            >
              {" "}
              <ChevronRight />{" "}
            </Button>
          ) : (
            <Button
              onClick={() => switchStep("next")}
              variant="contained"
              className="capitalize rounded-lg"
              disableElevation
            >
              <p className="font-weight-bold">Buat Akun</p>
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PsikologCreate;
