/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft, ExpandMore, Add as AddIcon } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Grid, Paper } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FlatArea from '../../../../components/base/flatArea'
import FlatInput from '../../../../components/base/flatInput'
import { getApi, postApi } from '../../../../store/apis'
import { CHANGE_TITLE } from '../../../../store/theming'
import SelectKategoriPeserta from './selectKategoriPeserta'
import SetDynamicalForm from './setDynamicalForm'

const CreateFormTraining = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { type } = useParams();

    const theme = useSelector((state) => state.theme.myTheme)
    const [catPeserta, setCatPeserta] = useState({
        id: '',
        nama: ''
    })
    const [loading, setLoading] = useState(false)
    const [infoForm, setInfoForm] = useState({
        judul: '',
        keterangan: '',
    })
    const [err, setErr] = useState({
        nama: '',
        training_kategori_peserta_id: ''
    })

    const fetchData = () => {
        setLoading(true);
        let data = {
            path: `admin/training/form?filter[id]=${type}`,
        };
        dispatch(getApi(data))
            .then((res) => {
                if (res.data.length) {
                    let raw = JSON.parse(JSON.stringify(res.data[0]))
                    getKategoriPeserta(raw.training_kategori_peserta_id)
                    setInfoForm({
                        ...infoForm,
                        judul: raw.nama,
                        keterangan: raw.deskripsi ?? '',
                    })
                    setRawForm(raw.konten_decoded)
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
            });
    };

    const getKategoriPeserta = id => {
        setLoading(true)
        let data = {
            path: `admin/training/kategori-peserta`
        }
        dispatch(getApi(data)).then(res => {
            setLoading(false)
            let raw = res.data
            if (raw.length) {
                let found = raw.find(el => el.id == id)
                setCatPeserta({
                    id: found.id,
                    nama: found.nama
                })
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)

        })
    }

    const saveData = () => {
        let rawErr = { ...err }
        for (let i in rawErr) {
            rawErr[i] = ''
        }
        setErr(rawErr)
        setLoading(true)
        let body = {
            training_kategori_peserta_id: catPeserta?.id ?? '',
            nama: infoForm.judul,
            deskripsi: infoForm.keterangan,
            konten: JSON.stringify(rawForm)
        }
        if (type != 'create') {
            body['id'] = type
        }
        let data = {
            path: `admin/training/form/${type == 'create' ? type : 'update'}`,
            body: body
        }
        dispatch(postApi(data)).then(res => {
            console.log(res)
            const variant = "success";
            enqueueSnackbar(data.message ?? "Data tersimpan!", { variant });
            navigate(`/training-form`)
            setLoading(false)
        }).catch(err => {
            console.log(err.data.errors)
            let require = err.data?.errors
            if (require) {
                for (let i in require) {
                    rawErr[i] = require[i][0]
                }
            }
            setErr(rawErr)
            const variant = "error";
            enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
            setLoading(false)
        })
    }

    const [dialog, setDialog] = useState(false)

    const [rawForm, setRawForm] = useState([{
        section: 1,
        judul: '',
        items: [
            {
                id: (Math.random() + 1).toString(36).substring(7),
                type: { label: 'Text', value: 'text' },
                pertanyaan: '',
                opsi: [],
            }
        ]
    }])

    const addSection = () => {
        let section = {
            section: rawForm.length + 1,
            judul: '',
            items: [
                {
                    id: (Math.random() + 1).toString(36).substring(7),
                    type: { label: 'Text', value: 'text' },
                    pertanyaan: '',
                    opsi: [],
                }
            ]
        }

        setRawForm([...rawForm, section])
    }


    const handleInfoForm = (prop) => (event) => {
        setInfoForm({ ...infoForm, [prop]: event.target.value });
    };
    useEffect(() => {
        dispatch(CHANGE_TITLE(`${type == 'create' ? 'Create' : 'Update'} Form`))
        if (type != 'create') {
            fetchData()
        }
    }, [])

    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg  mb-3"
                onClick={() => navigate(-1)}
            >
                <ChevronLeft /> Kembali
            </Button>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Paper className='pa-5 rounded-lg mb-5'>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <p style={{ color: theme.secondary, fontSize: '20px' }}>Detail Form</p>
                            </Grid>
                            <Grid item xs={8}>
                                <div className="d-flex justify-end">
                                    <Button
                                        variant="contained"
                                        style={{ minWidth: "0" }}
                                        className="capitalize rounded-lg  mb-3"
                                        onClick={saveData}
                                    >
                                        Simpan
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Judul Form</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatInput
                                    value={infoForm.judul}
                                    handleChange={handleInfoForm}
                                    property="judul"
                                    placeholder='Judul Form'
                                />
                                <p className='small_txt red-text'>{err.nama}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Keterangan Form</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatArea
                                    value={infoForm.keterangan}
                                    handleChange={handleInfoForm}
                                    property="keterangan"
                                    placeholder='Keterangan Form'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Kategori Peserta</p>
                            </Grid>
                            <Grid item xs={8}>
                                <div
                                    className='d-flex justify-space-between align-center cursor_pointer'
                                    style={{
                                        width: '100%',
                                        height: '36px',
                                        background: `${catPeserta.id ? '#fff' : theme.tertiary}`,
                                        borderRadius: '5px',
                                        border: `2px solid ${theme.tertiary}`,
                                        padding: '0 5px',
                                    }}
                                    onClick={() => setDialog(true)}
                                >
                                    <p className="small_txt">
                                        {catPeserta.id ? catPeserta.nama : 'Kategori Peserta'}
                                    </p>
                                    <ExpandMore color='primary' />
                                </div>
                                {err.training_kategori_peserta_id ? <p className='small_txt red-text'>Kategori Peserta harus diisi!</p> : <></>}
                            </Grid>
                        </Grid>
                    </Paper>
                    <SetDynamicalForm theme={theme} rawForm={rawForm} setRawForm={setRawForm} />

                    <Button
                        fullWidth
                        className='capitalize pa-4 rounded-lg'
                        disableElevation
                        variant='contained'
                        style={{ background: theme.lighten, color: theme.primary }}
                        onClick={addSection}>
                        <AddIcon />
                        <p className="font-weight-bold">Tambah Bagian</p>
                    </Button>
                </Grid>
            </Grid>
            <div className="py-2">
                <SelectKategoriPeserta dialog={dialog} category={catPeserta} setCategory={setCatPeserta} close={() => setDialog(false)} pocket={null} />
                <div style={{ height: '150px' }}></div>
            </div>
        </div>
    )
}

export default CreateFormTraining
