import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";

const grid = {
  left: 4,
  right: 8,
};

const checkURL = (url) => {
  if (typeof url !== "string") return false;
  return (
    url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null
  );
};

const DialogDetailPeserta = ({
  open,
  close,
  id_pelanggan,
  paid,
  kategori,
  data_pelanggan,
  pertanyaan_tambahan,
}) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const form = pertanyaan_tambahan ? JSON.parse(pertanyaan_tambahan) : [];
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 16, width: "592px" },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Detail Peserta</h4>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={grid.left}>
            <p
              className="small_txt font-weight-700"
              style={{ color: theme.primary }}
            >
              User ID
            </p>
          </Grid>
          <Grid item xs={grid.right}>
            <p className="small_txt font-weight-500">{id_pelanggan}</p>
          </Grid>

          <Grid item xs={grid.left}>
            <p
              className="small_txt font-weight-700"
              style={{ color: theme.primary }}
            >
              Nama Akun
            </p>
          </Grid>
          <Grid item xs={grid.right}>
            <p className="small_txt font-weight-500">{data_pelanggan?.nama}</p>
          </Grid>
          <Grid item xs={grid.left}>
            <p
              className="small_txt font-weight-700"
              style={{ color: theme.primary }}
            >
              Status Pembayaran
            </p>
          </Grid>
          <Grid item xs={grid.right}>
            <p
              className="small_txt font-weight-500"
              style={{ color: paid ? "#00AB97" : "#DB1D3F" }}
            >
              {paid ? "Success" : "Pending"}
            </p>
          </Grid>
          <Grid item xs={grid.left}>
            <p
              className="small_txt font-weight-700"
              style={{ color: theme.primary }}
            >
              Kategori
            </p>
          </Grid>
          <Grid item xs={grid.right}>
            <p className="small_txt font-weight-500">{kategori ?? "-"}</p>
          </Grid>
          <Grid item xs={12}>
            <div style={{ border: "1px solid rgba(246, 229, 254, 1)" }}></div>
          </Grid>
          {form.map((item) => {
            return (
              <>
                <Grid item xs={grid.left}>
                  <p
                    className="small_txt font-weight-700 capitalize"
                    style={{ color: theme.primary }}
                  >
                    {item.pertanyaan}
                  </p>
                </Grid>
                <Grid item xs={grid.right}>
                  {checkURL(item.jawaban) ? (
                    <img src={item.jawaban} height="200px" alt="image" />
                  ) : (
                    <p className="small_txt font-weight-500">{item.jawaban}</p>
                  )}
                </Grid>
              </>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DialogDetailPeserta;
