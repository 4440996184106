/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDropDown, ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Card,
  Grid,
  Paper,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Button,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import day from "dayjs";
import { getApi } from "../../../store/apis";
import Chart from "react-apexcharts";

const TrxReport = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [filter, setFilter] = useState("bulan");
  const [layanan, setLayanan] = useState("semua");
  const [barColor, setBarColor] = useState(["#CD6CDB"]);
  const [currentMonth, setMonth] = useState(day().format("MM"));
  const [currentYear, setYear] = useState(day().format("YYYY"));
  const [loading, setLoading] = useState(true);
  const [configSales, setConfigSales] = useState(null);
  const [configServices, setConfigServices] = useState(null);
  const [pie, setPie] = useState(null);
  const [summary, setSummary] = useState(null);
  const colors = ["#A22FB5", "#4BD8D0", "#20BEB5", "#8B11EA", "#F36B94"];
  const month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const tab = [
    {
      name: "semua",
      color: "#CD6CDB",
    },
    {
      name: "psikotes",
      color: "#8B11EA",
    },
    {
      name: "konsultasi",
      color: "#F36B94",
    },
    {
      name: "coaching",
      color: "#DF567F",
    },
    {
      name: "training",
      color: "#D037B8",
    },
    {
      name: "course",
      color: "#B6239F",
    },
  ];

  const currencyFormater = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const fetchData = () => {
    setLoading(true);
    const service = layanan === "semua" ? "" : layanan;
    const tipe = filter;
    const long = 4;
    let body = {
      path: `admin/transaksi/statistics?filter[tipe]=${service}&tipe=${tipe}&how_long=${long}`,
    };
    dispatch(getApi(body))
      .then((response) => {
        let categoriesSales = [];
        let seriesSales = [];
        let categoriesServices = [];
        let seriesServices = [];

        if (filter === "bulan") {
          month.forEach((mm) => {
            const transactions = response[currentYear][mm];
            const MM = day(`${currentYear}-${mm}-01`)
              .locale("id")
              .format("MMM");
            categoriesSales.push(MM);
            categoriesServices.push(MM);
            if (transactions) {
              const sum = transactions
                .map((i) => i.harga)
                .reduce((a, b) => parseInt(a) + parseInt(b));
              seriesSales.push(sum);
              seriesServices.push(transactions.length);
            } else {
              seriesSales.push(0);
              seriesServices.push(0);
            }
          });
        } else {
          for (let key in response) {
            categoriesSales.push(key);
            categoriesServices.push(key);
            const sum = response[key]
              .map((i) => i.harga)
              .reduce((a, b) => parseInt(a) + parseInt(b));
            seriesSales.push(sum);
            seriesServices.push(response[key].length);
          }
        }

        seriesSales = [
          {
            name: "Penjualan",
            data: seriesSales,
          },
        ];
        seriesServices = [
          {
            name: "Layanan",
            data: seriesServices,
          },
        ];

        makeBarChart({
          seriesSales,
          categoriesSales,
          seriesServices,
          categoriesServices,
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDetail = ({ month, year }) => {
    setMonth(month);
    setYear(year);
    const filterDate = filter === "bulan" ? `${year}-${month}` : year;
    const service = layanan === "semua" ? "" : layanan;
    let data = {
      path: `admin/transaksi/detail?filter[tipe]=${service}&filter[date]=${filterDate}`,
    };
    dispatch(getApi(data))
      .then((res) => {
        setSummary(res);
        makePieChart(res.layanan_terjual);
      })
      .catch((err) => console.log(err));
  };

  const makeBarChart = ({
    seriesSales,
    categoriesSales,
    seriesServices,
    categoriesServices,
  }) => {
    let options = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const series =
              filter === "bulan"
                ? config.dataPointIndex
                : config.w.config.xaxis.categories[config.dataPointIndex];
            const selectedMonth =
              filter == "bulan"
                ? day(`${currentYear}-${month[series]}-01`).format("MM")
                : currentMonth;
            const selectedYear = filter === "bulan" ? currentYear : series;
            getDetail({ month: selectedMonth, year: selectedYear });
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        colors: ["transparent"],
      },

      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${currencyFormater(val)}`;
          },
        },
      },
    };

    const configSales = {
      series: seriesSales,
      options: {
        ...options,
        xaxis: {
          categories: categoriesSales,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `Rp${currencyFormater(val)}`;
            },
          },
        },
        colors: ["#4FEFE5"],
      },
    };

    const configServices = {
      series: seriesServices,
      options: {
        ...options,
        xaxis: {
          categories: categoriesServices,
        },
        colors: barColor,
      },
    };

    setConfigSales(configSales);
    setConfigServices(configServices);
  };

  const makePieChart = (data) => {
    let raw = data;
    let series = raw.map((el) => el.harga);
    let labels = raw.map((el) => el.label);
    let conf = {
      series: series,
      options: {
        chart: {
          width: 150,
          type: "pie",
        },
        labels: labels,
        colors: colors,
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyFormater(val)}`;
            },
          },
        },
      },
    };

    setPie(conf);
  };

  const changeMonth = (change) => {
    const date = day(`${currentYear}-${currentMonth}-01`)
      .add(change, "month")
      .format("YYYY-MM")
      .split("-");
    setMonth(date[1]);
    setYear(date[0]);
    getDetail({ month: date[1], year: date[0] });
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const setTab = (e) => {
    const id = e.target.id;
    const { color } = tab.filter((el) => el.name === id)[0];
    setLayanan(id);
    setBarColor(color);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Transaction Report"));
    fetchData();
    getDetail({ month: currentMonth, year: currentYear });
  }, [filter, layanan]);

  return (
    <div>
      <p className="small_txt" style={{ color: "#404040" }}>
        Lihat total layanan terjual, serta jumlah pendapatan penjualan layanan
        berbayar dalam periode waktu tertentu di Teman Bakat.
      </p>
      <Box
        className="mb-5 mt-5"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs value={layanan} onChange={setTab}>
          {tab.map((item) => {
            return (
              <Tab
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                }}
                key={item.name}
                id={item.name}
                className="capitalize"
                label={item.name}
                value={item.name}
              />
            );
          })}
        </Tabs>
      </Box>
      <div className="d-flex justify-space-between mt-3">
        <Menu
          transition
          menuButton={
            <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
              <p className="small_txt font-weight-bold mr-3">
                {filter == "bulan" ? "Bulanan" : "Tahunan"}
              </p>
              <ArrowDropDown />
            </Card>
          }
        >
          <MenuItem onClick={() => setFilter("bulan")}>
            <p className="font-weight-bold txt_table">Bulanan</p>
          </MenuItem>
          <MenuItem onClick={() => setFilter("tahun")}>
            <p className="font-weight-bold txt_table">Tahunan</p>
          </MenuItem>
        </Menu>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className="pa-5 rounded-lg my-3">
            <div className="d-flex flex-row align-center">
              <h3 className="font-weight-bold mr-1">Semua Penjualan </h3>
              <p className="small_txt"> (dalam ribuan)</p>
            </div>

            {loading ? (
              <div
                style={{ height: 350 }}
                className="d-flex flexy align-center justify-center"
              >
                <CircularProgress color="primary" size={35} />
              </div>
            ) : (
              configSales && (
                <Chart
                  options={configSales.options}
                  series={configSales.series}
                  height={350}
                  type="bar"
                />
              )
            )}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="pa-5 rounded-lg my-3">
            <div className="d-flex flex-row align-center">
              <h3 className="font-weight-bold mr-1">Semua Layanan </h3>
              <p className="small_txt"> (Qty)</p>
            </div>
            {loading ? (
              <div
                style={{ height: 350 }}
                className="d-flex flexy align-center justify-center"
              >
                <CircularProgress color="primary" size={35} />
              </div>
            ) : (
              configServices && (
                <Chart
                  options={configServices.options}
                  series={configServices.series}
                  height={350}
                  type="bar"
                />
              )
            )}
          </Paper>
        </Grid>
      </Grid>
      <div className="py-3">
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <Button onClick={() => changeMonth(-1)}>
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item xs={10}>
            <div className="py-3">
              <h3 className="text-center font-weight-bold">
                {filter === "bulan"
                  ? day(`${currentYear}-${currentMonth}-01`)
                      .locale("id")
                      .format("MMMM YYYY")
                  : `Tahun ${currentYear}`}
              </h3>
              <p className="text-center font-weight-bold">
                Ringkasan Semua Penjualan
              </p>
              {filter === "bulan" ? (
                <p className="text-center small_txt">
                  Periode 1 - {getDaysInMonth(currentYear, currentMonth)}{" "}
                  {day(`${currentYear}-${currentMonth}-01`)
                    .locale("id")
                    .format("MMMM YYYY")}
                </p>
              ) : (
                <p className="text-center small_txt">
                  Periode {parseInt(currentYear) - 1} - {currentYear}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={1}>
            <Button onClick={() => changeMonth(1)}>
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      </div>
      {summary && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className="pa-3 rounded-lg mb-4">
              <p className="small_txt font-weight-700">Total Revenue</p>
              <div className="d-flex align-center">
                <h3 style={{ color: theme.primary }}>
                  {currencyFormater(summary.total_revenue.revenue)}
                </h3>
                <p className="small_txt ml-1">(E-wallet & Poin)</p>
              </div>
              <div className="d-flex justify-space-between">
                <p className="small_txt">Total Penjualan</p>
                <p className="small_txt">
                  {currencyFormater(summary.total_revenue.penjualan)}
                </p>
              </div>
              <div className="d-flex justify-space-between">
                <p className="small_txt">Potongan Penjualan</p>
                <p className="small_txt">
                  {currencyFormater(summary.total_revenue.potongan)}
                </p>
              </div>
            </Paper>
            <Paper className="pa-3 rounded-lg mb-4">
              <p className="small_txt font-weight-700">Total Layanan Terjual</p>
              <h3 style={{ color: theme.primary }}>
                {currencyFormater(summary.total_layanan)} Layanan
              </h3>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className="pa-3 rounded-lg mb-4">
              <p className="small_txt font-weight-700">Layanan Terjual</p>
              <p className="small_txt mb-3">
                Statistik jumlah dan sub total penjualan per layanan
              </p>
              <div className="d-flex align-center justify-center mb-3">
                {pie && (
                  <Chart
                    options={pie.options}
                    series={pie.series}
                    height={200}
                    width={230}
                    type="pie"
                  />
                )}
              </div>
              <Grid container spacing={3} className="mb-2">
                <Grid item xs={4}>
                  <p
                    className="small_txt font-weight-500"
                    style={{ color: theme.primary }}
                  >
                    Kategori
                  </p>
                </Grid>
                <Grid item xs={4} className="d-flex flex-row justify-center">
                  <p
                    className="small_txt font-weight-500"
                    style={{ color: theme.primary }}
                  >
                    Qty
                  </p>
                </Grid>
                <Grid item xs={4} className="d-flex flex-row justify-end">
                  <p
                    className="small_txt font-weight-500"
                    style={{ color: theme.primary }}
                  >
                    Sub Total
                  </p>
                </Grid>
              </Grid>
              {summary.layanan_terjual.map((item, i) => {
                return (
                  <Grid container spacing={3} key={i + 1} className="mb-1">
                    <Grid item xs={4} className="d-flex align-center">
                      <div
                        style={{
                          borderRadius: "50%",
                          width: "15px",
                          height: "15px",
                          background: colors[i],
                        }}
                      ></div>
                      <p
                        className="small_txt ml-2"
                        style={{ fontWeight: "500", color: "#000" }}
                      >
                        {item.label}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className="d-flex flex-row justify-center"
                    >
                      <p className="small_txt">{item.qty}</p>
                    </Grid>
                    <Grid item xs={4} className="d-flex flex-row justify-end">
                      <p
                        className="small_txt"
                        style={{ fontWeight: "500", color: "#000" }}
                      >
                        {currencyFormater(item.harga)}
                      </p>
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className="pa-3 rounded-lg mb-4">
              <p className="small_txt font-weight-700">Metode Pembelian</p>
              <p className="small_txt mb-3">
                Statistik jumlah penjualan layanan berdasarkan metode pembelian
              </p>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <p
                    className="small_txt font-weight-500"
                    style={{ color: theme.primary }}
                  >
                    Kategori
                  </p>
                </Grid>
                <Grid item xs={4} className="d-flex flex-row justify-center">
                  <p
                    className="small_txt font-weight-500"
                    style={{ color: theme.primary }}
                  >
                    Qty
                  </p>
                </Grid>
                <Grid item xs={4} className="d-flex flex-row justify-end">
                  <p
                    className="small_txt font-weight-500"
                    style={{ color: theme.primary }}
                  >
                    Sub Total
                  </p>
                </Grid>
              </Grid>
              {summary?.metode_pembelian.map((item, i) => {
                return (
                  <Grid container spacing={3} key={i + 1}>
                    <Grid item xs={4}>
                      <p className="small_txt">{item.label}</p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className="d-flex flex-row justify-center"
                    >
                      <p className="small_txt">{item.qty}</p>
                    </Grid>
                    <Grid item xs={4} className="d-flex flex-row justify-end">
                      <p className="small_txt">
                        {currencyFormater(item.harga)}
                      </p>
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default TrxReport;
