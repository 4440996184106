import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import css from '../../assets/css/search.module.css'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Select, MenuItem } from '@mui/material'

const SelectWithIcon = ({ value, list, property, item_text, item_value, handleChange, icon, }) => {
    const theme = useSelector(state => state.theme.myTheme)

    return (
        <div className='d-flex flexy align-center rounded-lg' style={{ background: theme.lighten, padding: '3px' }}>
            <div className='px-2 d-flex align-center' style={{ background: theme.lighten }}>
                {icon}
            </div>
            <Select
                id="demo-simple-select"
                value={value}
                label="Age"
                className={css.select_custom}
                style={{ height: '35px', outline: 'none', border: 'none' }}
                onChange={handleChange(property)}
            >
                {list.map((lm, i) => <MenuItem key={i} value={item_value ? lm[item_value] : lm}>{item_text ? lm[item_text] : lm}</MenuItem>)}
            </Select>
        </div>
    )
}

export default SelectWithIcon