import { configureStore } from "@reduxjs/toolkit";
import configTheme from "./theming.js";
import configAuth from "./auth.js";
import configApi from "./apis.js";
import configChat from "./chat.js";

export default configureStore({
  reducer: {
    theme: configTheme,
    auth: configAuth,
    apis: configApi,
    chat: configChat,
  },
});
