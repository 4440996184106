import React, { useState } from 'react'
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip, tooltipClasses, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Icon from "@mdi/react"
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js"
import day from 'dayjs'

const PsikotesActivityList = ({ theme, dataSet, ava, page, limit }) => {
    const [dialog, setDialog] = useState({
        post: false,
        view: false,
    })
    const [pocket, setPocket] = useState(null)
    const [formateDate, setDate] = useState(null)
    const navigate = useNavigate()


    return (
        <div>
            <TableContainer component='div'>
                <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ minWidth: '50px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>No.</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Nama User</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1' >
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Email</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Judul Psikotes</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '130px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tgl. Check Out</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Status Pengerjaan</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '130px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tgl Selesai</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Waktu Selesai</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Metode Pembelian</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{i + 1 + (page - 1) * limit}</TableCell>
                                <TableCell>
                                    <p className=''> {item.nama} </p>
                                </TableCell>
                                <TableCell >{item.email}</TableCell>
                                <TableCell>{item.judul_psikotes}</TableCell>
                                <TableCell align="center">{item.tgl_checkout}</TableCell>
                                <TableCell>api belum</TableCell>
                                <TableCell align="center">{item.tgl_selesai}</TableCell>
                                <TableCell align="center">{item.wkt_selesai}</TableCell>
                                <TableCell align="center">{item.metode_pembelian}</TableCell>
                                <TableCell>
                                    <Button variant='contained' className='rounded-xl capitalize mr-3' onClick={() => navigate(`/counselor/detail/${item.id_konselor}`)} disableElevation size="small" style={{ background: theme.act_blue }}>
                                        <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }}>Detail</p>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pa-2">
            </div>
        </div>
    )
}

export default PsikotesActivityList
