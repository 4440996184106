/* eslint-disable eqeqeq */
import { DragIndicator, Delete as DeleteIcon, RadioButtonUnchecked, CheckBoxOutlineBlank, Add as AddIcon } from '@mui/icons-material';
import { Button, IconButton, Paper } from '@mui/material';
import React, { Component } from 'react';
import DraggableList from "react-draggable-list";
import FlatInput from '../../../../components/base/flatInput';
import Select from 'react-select';
import BlankInput from '../../../../components/base/blankInput';



class Item extends Component {
    render() {
        const { item, itemSelected, dragHandleProps, commonProps } = this.props;
        const { onMouseDown, onTouchStart } = dragHandleProps;
        const { section, j } = item
        const { i, handleInput, removeQuestion, typeForm, theme, addOption } = commonProps
        const customStyles = {
            control: (provided) => ({
                ...provided,
                borderRadius: '6px',
                border: 'none',
                outline: `2px solid ${theme.tertiary}`,
                fontSize: 'small'
            }),
            menu: () => ({
                label: "menu",
                top: "100%",
                backgroundColor: "hsl(0, 0%, 100%)",
                boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                marginBottom: 8,
                marginTop: 8,
                position: "absolute",
                width: "100%",
                zIndex: 2,
                boxSizing: "border-box",
                borderRadius: '5px',
                padding: '1px',
                fontSize: 'small',
            }),
            input: (provided, state) => ({
                ...provided,
                outline: state.isFocused ? `2px solid ${theme.tertiary}` : 'none',
            }),
            option: (provided, state) => ({
                ...provided,
                borderRadius: '5px',
                zIndex: 'auto',
                background: state.isSelected ? '#FBF4FE' : '',
                color: state.isSelected ? '#000' : '',
                ':hover': {
                    background: '#FBF4FE',
                },
            }),
        }
        return (
            <Paper className='rounded-lg mb-2 d-flex' style={{ boxSizing: 'border-box' }}>
                <div className="pa-3 d-flex align-start flexy">
                    <div className="d-flex">
                        <IconButton color='primary' onClick={() => removeQuestion(i, j)}>
                            <DeleteIcon />
                        </IconButton>
                        <div className="flexy mr-3" style={{ width: '150px' }}>
                            <Select
                                styles={customStyles}
                                classNamePrefix="select"
                                options={typeForm}
                                defaultValue={section.type}
                                name="Tipe Pertanyaan"
                                onChange={handleInput({ idx: i, idx_item: j, level: 'item', type: 'select', label: 'type' })}
                            />
                        </div>
                    </div>
                    <div className="flexy mr-1" style={{ marginTop: '1px' }}>
                        <FlatInput
                            value={section.pertanyaan}
                            handleChange={handleInput}
                            property={{ idx: i, idx_item: j, level: 'item', label: 'pertanyaan' }}
                            placeholder='Pertanyaan' />
                        {section.type.value == 'radio' || section.type.value == 'checkbox' ?
                            <div className="my-3">
                                {section.opsi.length ? section.opsi.map((opt, k) =>
                                    <div key={`opsi-${j}${k}`} className='d-flex align-center mb-2'>
                                        {section.type.value == 'radio' ?
                                            <RadioButtonUnchecked fontSize='small' className='mr-2' /> :
                                            <CheckBoxOutlineBlank className='mr-2' fontSize='small' />}
                                        <BlankInput
                                            property={{ idx: i, idx_item: j, idx_opt: k, level: 'opsi', }}
                                            value={opt}
                                            handleChange={handleInput}
                                            placeholder='Opsi'
                                            idEl={`opt${i}${j}${k}`}
                                        />
                                    </div>)
                                    : <></>}
                                <Button className='capitalize ' onClick={() => addOption(i, j)}><AddIcon fontSize='small' />
                                    <p className="font-weight-bold">Tambah Pilihan</p>
                                </Button>
                            </div> : <></>}
                    </div>
                </div>
                <div className="pa-3 d-flex align-center"
                    style={{ background: theme.tertiary, minHeight: '100%', borderRadius: '0 8px 8px 0' }}>
                    <IconButton
                        className='grabbable'
                        onTouchStart={(e) => {
                            e.preventDefault();
                            document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                        onMouseDown={(e) => {
                            document.body.style.overflow = "hidden";
                            onMouseDown(e);
                        }}
                        onTouchEnd={(e) => {
                            e.target.style.backgroundColor = "black";
                            document.body.style.overflow = "visible";
                        }}
                        onMouseUp={() => {
                            document.body.style.overflow = "visible";
                        }}>
                        <DragIndicator />
                    </IconButton>
                </div>
            </Paper>
        )
    }
}

class DraggableQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            useContainer: false,
        };
    }

    render() {
        const { useContainer } = this.state;
        const { section, _onListChange, i, handleInput, removeQuestion, typeForm, theme, addOption } = this.props
        return (
            <DraggableList
                itemKey={i => i.section.id}
                unsetZIndex={true}
                commonProps={{
                    i: i,
                    handleInput: handleInput,
                    removeQuestion: removeQuestion,
                    typeForm: typeForm,
                    theme: theme,
                    addOption: addOption
                }}
                template={Item}
                list={section.map((section, j) => ({ section, j }))}
                onMoveEnd={(newList) => _onListChange(newList.map(el => el.section), i)}
                container={() => (useContainer ? this._container : document.body)}
            />
        );
    }
}

export default DraggableQuestion;