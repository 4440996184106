/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import LinesEllipsis from "react-lines-ellipsis";
import { DialogDelete } from "../../../components/base/dialogDelete";

const CourseActivityList = ({ theme, dataSet, ava, page, limit, refetch }) => {
  const [dialog, setDialog] = useState(false);
  const [pocket, setPocket] = useState(null);
  const navigate = useNavigate();
  const deleteData = item => {
    setPocket({
      path: 'admin/course/delete',
      body: {
        id: item.id
      }
    })
    setDialog(true)
  }

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-2"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  No.
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "170px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Judul Course
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "150px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Periode
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "200px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Pemateri
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Jenis
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Jml. Peserta
                </p>
              </TableCell>

              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Show
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{i + 1 + (page - 1) * limit}</TableCell>
                <TableCell>
                  <Tooltip
                    title={item.judul}
                    placement="top"
                    followCursor={true}
                    arrow
                  >
                    <div>
                      <LinesEllipsis className="small_txt" text={item.judul} />
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {item.tgl_mulai ? day(item.tgl_mulai).format('DD/MM/YYYY') : ''}
                  -
                  {item.tanggal_selesai ? day(item.tanggal_selesai).format('DD/MM/YYYY') : ''}
                </TableCell>
                <TableCell>
                  <div className="d-flex aling-center">
                    {item.author.length ?
                      <div className="box_pemateri flexy mr-1"> <LinesEllipsis className="small_txt text-center" text={item.author[0].nama} />  </div> : <></>}
                    {item.author.length > 1 ? <div className='box_pemateri small_txt'> +{item.author.length - 1} </div> : <></>}
                  </div>
                </TableCell>
                <TableCell align="center">{item.jenis}</TableCell>
                <TableCell align="center">{item.jumlah_peserta}</TableCell>
                <TableCell align="center">
                  <Button>
                    {item.status_show == "open" ? (
                      <Icon
                        path={mdiToggleSwitch}
                        size={1}
                        color={theme.primary}
                      />
                    ) : (
                      <Icon path={mdiToggleSwitchOffOutline} size={1} />
                    )}
                  </Button>
                </TableCell>
                <TableCell>
                  <div className="d-flex">
                    <Button
                      variant="contained"
                      className="rounded-lg capitalize mr-3"
                      onClick={() =>
                        navigate(`/course-detail/${item.id}`)
                      }
                      disableElevation
                      size="small"
                      style={{ background: theme.act_cyan }}
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.act_lbl_cyan }}
                      >
                        View
                      </p>
                    </Button>
                    <Button
                      variant="contained"
                      className="rounded-lg capitalize"
                      onClick={() => deleteData(item)}
                      disableElevation
                      size="small"
                      style={{ background: theme.act_red }}
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.act_lbl_red }}
                      >
                        Delete
                      </p>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
      </div>
    </div >
  );
};

export default CourseActivityList;
