/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHANGE_TITLE } from "../../../store/theming";
import FormCreateCourse from "./components/FormCreateCourse";


const CreateCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(CHANGE_TITLE("Tambah Course"));
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg  mb-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeft /> Kembali
      </Button>
      <FormCreateCourse setLoading={setLoading} />
    </div>
  );
};

export default CreateCourse;
