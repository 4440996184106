import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { postApi } from '../../store/apis';
import { Delete } from '@mui/icons-material';

export const DialogDelete = ({ d_del, pocket, close, refetch }) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({
        loading: false
    })
    const deleteData = () => {
        setState({ ...state, loading: true })
        let data = {
            path: pocket.path,
            body: pocket.body
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            close()
            setState({ ...state, loading: false })
            refetch()
        }).catch(err => {
            setState({ ...state, loading: false })
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }

    return (
        <Dialog
            open={d_del}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10, minWidth: '350px' }
            }}
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent>
                <div className="d-flex justify-center">
                    <Delete color='red' fontSize='large' />
                </div>
                <p className='text-center' style={{ fontSize: '25px' }}>Are You Sure?</p>
                <p className='text-center'>Apakah Anda yakin ingin menghapus data ini?</p>
                <div className="mt-5 d-flex justify-center">
                    <Button onClick={close} color='black' className='mr-3'>Cancel</Button>
                    {!state.loading ?
                        <Button onClick={deleteData} color='red' variant='contained' autoFocus>
                            <p className='white-text'>delete</p>
                        </Button> :
                        <Button variant='contained' color='red' autoFocus>
                            <p className='white-text'>Loading...</p>
                        </Button>}
                </div>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}
