import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

const DialogAdd = ({ open, onClose, onClickManual }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 12, minWidth: "270" },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <p className="font-weight-bold">Tambah Akun Pengguna</p>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex">
          <Button
            className="d-flex flexy capitalize rounded-lg"
            onClick={onClickManual}
            color={"primary"}
            disableElevation
            variant="contained"
          >
            <p>Tambah Secara Manual</p>
          </Button>
        </div>
        <div className="mt-3 mb-3">
          <p className="small_txt text-center">atau</p>
        </div>
        <div className="d-flex">
          <Button
            className="d-flex flexy capitalize rounded-lg"
            onClick={() => console.log("")}
            color={"primary"}
            disableElevation
            variant="contained"
          >
            <p>Import Data</p>
          </Button>
        </div>
        <div className="d-flex flexy flex-row justify-center mt-3">
          <p className="small_txt text-center mr-1">Unduh format data</p>
          <a
            href="#"
            className="small_txt text-center font-weight-bold"
            style={{ textDecorationLine: "underline", color: theme.primary }}
          >
            di sini
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogAdd;
