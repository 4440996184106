/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Skeleton, Paper, Avatar, IconButton, Backdrop, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { getApi, postApi } from '../../../store/apis';
import SearchDataTable from '../../../components/base/searchDataTable';
import { useEffect } from 'react';
import EmptyData from '../../../components/base/EmptyData';
import { CheckCircle, Close as CloseIcon, RadioButtonUnchecked } from '@mui/icons-material';


const GiveUserVcr = ({ dialog, pocket, close, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        page: 1,
        limit: 15
    })

    const [loading, setLoading] = useState(false)
    const [init, setInit] = useState(false)
    const [find, setFind] = useState('')
    const dispatch = useDispatch()
    const [dataSet, setData] = useState([])
    const [emptyTxt, setEmptyTxt] = useState("Ketik nama user");
    const [select, setSelect] = useState(null)
    const fetchData = () => {
        setLoading(true);
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
        let data = {
            path: `admin/individual-user?filter[nama]=${find}${page}&sort=-created_at`,
        };

        dispatch(getApi(data))
            .then((data) => {
                setLoading(false);
                setData(data.data);
                setState({ ...state, total: data.total, last_page: data.last_page });
                if (find != '' && !data.data.length) {
                    setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
                }
            })
            .catch((err) => {
                if (err.status == 400) {
                    if (find != "") {
                        setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
                    } else {
                        setEmptyTxt(`No Data Available`);
                    }
                } else {
                    setEmptyTxt(`${err.status}: ${err.data.message}`);
                }
                setLoading(false);
                setData([find]);
            });
    };

    const giveIt = () => {
        setInit(true);
        let data = {
            path: 'admin/voucher/pelanggan/store',
            body: {
                id: pocket,
                email: select
            }
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            close()
            refetch()
            setSelect(null)
            setFind('')
            setData([])
            setInit(false);
        }).catch(err => {
            setInit(false);
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }
    const tengkorak = () => {
        let data = []
        for (let i = 0; i < 5; i++) {
            data.push(
                <Skeleton variant="rounded" className='rounded-lg mb-3' style={{ height: '30px', width: '100%' }} />
            )
        }
        return data
    }
    const dummyAva = require(`../../../assets/img/ava/ava1.png`)
    useEffect(() => {
        if (find) {
            fetchData()
        }
    }, [find])

    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10, minWidth: '450px' }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className="d-flex justify-space-between">
                    <h4>List User</h4>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={init}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="mb-3">
                    <SearchDataTable value={find} setValue={setFind} />
                </div>
                {!loading ? dataSet.length ?
                    dataSet.map((item, i) => <Paper key={`user${i}`} className='pa-3 mb-3 d-flex justify-space-betwen'>
                        <div className="d-flex align-center flexy">
                            <Avatar alt={item.nama} src={item.foto ? item.foto_link : dummyAva} className="mr-3" />
                            <div>
                                <p className='small_txt'> {item.nama} </p>
                                <p className='txt_table grey-text'> {item.email} </p>
                            </div>
                        </div>
                        <IconButton onClick={() => setSelect(item.email)}>
                            {select == item.email ? <CheckCircle color='primary' /> : <RadioButtonUnchecked />}
                        </IconButton>
                    </Paper>)
                    : <EmptyData txt={emptyTxt} /> : tengkorak()}
            </DialogContent>
            <DialogActions>
                {select ? <Button
                    className="capitalize rounded-lg"
                    variant="contained"
                    fullWidth
                    onClick={giveIt}
                >
                    <CheckCircle className="mr-1" />
                    <p className="font-weight-bold">simpan</p>
                </Button> : <></>}
            </DialogActions>
        </Dialog>
    )
}
export default GiveUserVcr