import React from "react";
import { useSelector } from "react-redux";
import css from "../../assets/css/search.module.css";

const AreaWithIcon = ({
  value,
  placeholder = "",
  property,
  handleChange,
  icon,
}) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <div
      className="d-flex flexy align-start rounded-lg"
      style={{ background: theme.lighten, padding: "3px" }}
    >
      <div
        className="px-2 d-flex align-center py-2"
        style={{ background: theme.lighten }}
      >
        {icon}
      </div>
      <textarea
        id="awi"
        rows="5"
        value={value}
        className={css.icon_input}
        placeholder={placeholder}
        onChange={handleChange(property)}
        style={{
          background: theme.tertiary,
          borderRadius: "0 5px 5px 0",
          resize: "none",
          fontSize: "small",
        }}
      ></textarea>
    </div>
  );
};

export default AreaWithIcon;
