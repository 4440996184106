/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import { Grid, Pagination, Paper, Card, Button } from "@mui/material";
import SkeletonTable from "../../../components/base/SkeletonTable";

import { ArrowDropDown, AddCircle } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useNavigate } from "react-router-dom";
import TrxActivityList from "./trxActivityList";

const TrxActivityPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const ava = useSelector((state) => state.theme.dummy);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/transaksi?filter[data_pelanggan.nama]=${find}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data.data);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };

  const listLimit = [10, 50, 100];

  useEffect(() => {
    dispatch(CHANGE_TITLE("Transaction Activity"));
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className="d-flex align-center">
            <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
            <Menu
              menuClassName="my_menu"
              menuButton={
                <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                  <p className="small_txt font-weight-bold mr-3">
                    {state.limit} Data
                  </p>
                  <ArrowDropDown />
                </Card>
              }
              transition
            >
              {listLimit.map((lm, i) => (
                <MenuItem
                  key={`limit-${i}`}
                  onClick={() => setState({ ...state, limit: lm })}
                >
                  <p className="font-weight-bold txt_table">{lm} Data</p>
                </MenuItem>
              ))}
            </Menu>
            <SearchDataTable value={find} setValue={setFind} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="d-flex align-center justify-end">
            <Button
              className="capitalize rounded-lg"
              variant="contained"
              onClick={() => navigate("/poin-package")}
            >
              <AddCircle className="mr-3" />
              <p className="font-weight-bold">Point Package</p>
            </Button>
          </div>
        </Grid>
      </Grid>
      <Paper className="pa-3 rounded-lg mt-3">
        {!loading ? (
          !dataSet.length ? (
            <EmptyData />
          ) : (
            <TrxActivityList
              theme={theme}
              ava={ava}
              dataSet={dataSet}
              page={state.page}
              limit={state.limit}
              refetch={fetchData}
            />
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}
        {dataSet.length ? (
          <div
            className="d-flex align-center justify-space-between"
            style={{ width: "100%" }}
          >
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination
                sx={{ padding: "0" }}
                count={state.last_page}
                page={state.page}
                shape="rounded"
                onChange={handlePage}
                style={{ background: "#fff" }}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>
      <div className="pa-2">
        {/* <PsikologCreate d_create={dialog} close={() => setDialog(false)} refetch={fetchData} /> */}
      </div>
    </div>
  );
};

export default TrxActivityPage;
