/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Backdrop, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { postApi } from '../../../store/apis';
import { Close } from '@mui/icons-material';

const GiveManyUserVcr = ({ dialog, pocket, close, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [init, setInit] = useState(false)
    const dispatch = useDispatch()

    const giveIt = e => {
        const file = e.target.files[0];
        setInit(true);
        let body = new FormData()
        body.append('id', pocket)
        body.append('file', file)
        let data = {
            path: 'admin/voucher/pelanggan/import',
            body: body
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            close()
            refetch()
            setInit(false);
        }).catch(err => {
            setInit(false);
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }

    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10, width: '400px' }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className="d-flex justify-space-between">
                    <h4>Import User</h4>
                    <IconButton onClick={close}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={init}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    style={{ height: '45px' }}
                    className='capitalize'
                    component="label"
                >
                    <p className='font-weight-bold'>Import Data</p>
                    <input
                        hidden
                        onChange={giveIt}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file" />
                </Button>
                <div style={{ minHeight: '30px' }}></div>
                <p className="small_txt text-center">Import data harus sesuai dengan format yang telah ditentukan.
                    Unduh format <span className='font-weight-bold cursor_pointer'>di sini</span> </p>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}
export default GiveManyUserVcr