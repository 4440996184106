/* eslint-disable eqeqeq */
import { Close, DeleteOutline } from '@mui/icons-material'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import day from 'dayjs'
const DetailJadwal = ({ dialog = false, close, pocket, theme }) => {

    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10 }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className="d-flex justify-space-between align-center">
                    <h3>Detail Jadwal</h3>
                    <IconButton onClick={close}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent >
                {pocket ?
                    <div className='borbox' style={{ width: '400px', padding: '5px' }}>
                        <p className="font-weight-bold mb-3" style={{ color: theme.darken }}> {day(pocket.waktu).locale('id').format('dddd, DD MMMM YYYY')} </p>
                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}> Waktu Konsultasi </p>
                        <p className="small_txt font-weight-bold mb-3"> {day(pocket.waktu).format('HH:mm')} </p>
                        <p className="small_txt font-weight-bold"
                            style={{ color: pocket.role == 'Psikolog' ? '#10A3B0' : pocket.role == 'Coach' ? '#FF9F0E' : '#A449FF', }}>
                            {pocket.sub_role} {pocket.role}
                        </p>
                        <p className="small_txt font-weight-bold mb-3"> {pocket.konselor} </p>
                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}> Nama Klien </p>
                        <p className="small_txt font-weight-bold mb-3"> {pocket.klien ?? '-'} </p>
                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}> Layanan </p>
                        <p className="small_txt font-weight-bold mb-5"> {pocket.layanan ?? '-'} </p>
                        <Button
                            disableElevation
                            variant='contained'
                            className='capitalize'
                            disabled={pocket.booked ? true : false}
                            style={{
                                background: pocket.booked ? '' : theme.act_red,
                                color: pocket.booked ? '' : theme.act_lbl_red
                            }}
                        >
                            <DeleteOutline className='mr-2' />
                            <p className="font-weight-bold">Hapus Jadwal</p>
                        </Button>
                    </div> : <></>
                }

            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog >
    )
}

export default DetailJadwal