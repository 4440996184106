/* eslint-disable eqeqeq */
import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
} from "@mui/material";
import day from 'dayjs'
import LinesEllipsis from "react-lines-ellipsis";
import { DialogDelete } from "../../../components/base/dialogDelete";
import { useState } from "react";
import { Menu, ControlledMenu, FocusableItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Done } from "@mui/icons-material";

const VcrActivityList = ({ theme, dataSet, setData, page, limit, refetch }) => {
    const navigate = useNavigate()
    const refs = useRef([]);
    const [isOpen, setOpen] = useState(null);
    const [dialog, setDialog] = useState(false)
    const [pocket, setPocket] = useState(null)
    const deleteData = item => {
        setPocket({
            path: 'admin/voucher/delete',
            body: {
                id: item.id
            }
        })
        setDialog(true)
    }
    const [content, setContent] = useState('')
    const onClickCode = item => {
        setContent(item.kode)
        setOpen(item.id)
    }
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    const changeCode = item => {
        let data = {
            path: 'admin/voucher/update-kode',
            body: {
                id: item.id,
                kode: content
            }
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setOpen(null)
            refetch()
        }).catch(err => {
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }

    return (
        <div>
            <TableContainer component="div">
                <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                    <TableHead>
                        <TableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell
                                style={{
                                    minWidth: "20px",
                                    background: theme.tertiary,
                                    borderRadius: "10px 0 0 10px",
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    No
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "150px",
                                    background: theme.tertiary,
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Kode Voucher
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "200px",
                                    background: theme.tertiary,
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Nama Voucher
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Peruntukkan
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Jenis
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Mode
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "150px",
                                    background: theme.tertiary,
                                }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Kedaluwarsa
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "50px",
                                    background: theme.tertiary,
                                }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Kuota
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "50px",
                                    background: theme.tertiary,
                                }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Claimed
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "50px",
                                    background: theme.tertiary,
                                }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Redeem
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "50px",
                                    background: theme.tertiary,
                                }}
                                align="center"
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Status
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "150px",
                                    background: theme.tertiary,
                                    borderRadius: "0 10px 10px 0",
                                }}
                                align="center"
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Tindakan
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="center">{i + 1 + (page - 1) * limit}</TableCell>
                                <TableCell>
                                    <div onClick={() => onClickCode(item)} className="pa-2">
                                        <p ref={refs.current[i]} className="font-weight-bold blue-text">
                                            {item.kode}
                                        </p>
                                    </div>
                                    <ControlledMenu
                                        arrow
                                        arrowClassName='arrow_Class'
                                        state={isOpen == item.id ? 'open' : 'closed'}
                                        onClose={() => setOpen(null)}
                                        anchorRef={refs.current[i]}
                                        transition
                                        style={{
                                            borderRadius: '2px',
                                            padding: '1px'
                                        }}
                                    >
                                        <FocusableItem style={{ padding: '10px' }}>
                                            {({ ref }) => (
                                                <div className="d-flex">
                                                    <input ref={ref}
                                                        type="text"
                                                        className="input_vcr"
                                                        onChange={(e) => setContent(e.target.value)}
                                                        onKeyUp={(e) => { if (e.key === 'Enter') changeCode(item) }}
                                                        value={content} />
                                                    <Button variant='contained'
                                                        className="px-2"
                                                        disableElevation
                                                        onClick={() => changeCode(item)}
                                                        style={{ minWidth: '10px' }}>
                                                        <Done />
                                                    </Button>
                                                </div>
                                            )}
                                        </FocusableItem>
                                    </ControlledMenu>
                                    {/* <Menu
                                        menuButton={
                                            <p className="font-weight-bold">{item.kode}</p>
                                        }
                                        transition
                                        style={{ borderRadius: '4px' }}
                                        arrow={true}
                                    >
                                        <FocusableItem style={{ padding: '10px' }}>
                                            {({ ref }) => (
                                                <div className="d-flex">
                                                    <input ref={ref} type="text" className="input_vcr" value={item.kode} placeholder="Type to filter" />
                                                    <button>aw</button>
                                                </div>
                                            )}
                                        </FocusableItem>
                                    </Menu> */}
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">{item.nama}</p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.peruntukan ?? ''}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.type ?? ''}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        api belum
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.expire_date ?? ''}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.kuota ?? ''}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.data_voucher_pelanggan_count ?? ''}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.jumlah_reedem ?? ''}
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.status}
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <div className="d-flex">
                                        <Button
                                            variant='contained'
                                            className='rounded-xl capitalize mr-3'
                                            disableElevation
                                            onClick={() => navigate(`/voucher-detail/${item.id}`)}
                                            size="small"
                                            style={{ background: theme.act_blue }}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }}>Detail</p>
                                        </Button>
                                        <Button variant='contained'
                                            className='rounded-xl capitalize'
                                            disableElevation
                                            size="small"
                                            onClick={() => deleteData(item)}
                                            style={{ background: theme.act_red }}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_red }}>Delete</p>
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pa-2">
                <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
            </div>
        </div >
    );
};

export default VcrActivityList;
