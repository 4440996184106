import { Avatar, IconButton } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Logout, Notifications } from '@mui/icons-material'
import Cookies from "js-cookie";
import SidebarMain from '../components/sidebar/SidebarMain.jsx'
import { useSelector } from 'react-redux'
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { useNavigate } from "react-router-dom";
const Main = () => {
    const navigate = useNavigate()
    const title = useSelector(state => state.theme.title)
    const theme = useSelector(state => state.theme.myTheme)
    const signOut = () => {
        Cookies.remove("token");
        Cookies.remove("id");
        localStorage.clear();
        navigate(0)
    }
    return (
        <div className='main'>
            <aside>
                <div className="sidebar" style={{ background: theme.secondary }}>
                    <SidebarMain />
                </div>
            </aside>
            <div className="page" style={{ background: theme.tertiary }}>
                <nav style={{ background: theme.tertiary }}>
                    <p className="title_nav" style={{ color: theme.darken }}>{title}</p>
                    <div className="d-flex align-center">
                        <div className="temp_notif mr-3">
                            <IconButton>
                                <Notifications color='primary' />
                            </IconButton>
                        </div>
                        <Menu style={{ width: 'max-content' }} menuButton={<Avatar
                            src="https://i.pinimg.com/236x/a6/bb/f1/a6bbf185cb184de269d0d3b1fdb0a189.jpg"
                            sx={{ width: 45, height: 45 }} />}
                            transition arrow>
                            <MenuItem ><p className='font-weight-bold txt_table' >Profile</p></MenuItem>
                            <MenuItem onClick={() => signOut()}><p className='font-weight-bold txt_table' >Logout</p></MenuItem>
                        </Menu>

                    </div>
                </nav>
                <div className="main_page">
                    <Outlet />
                </div>
            </div>
        </div >
    )
}

export default Main
