import { MoreHoriz } from '@mui/icons-material'
import { Button, Card, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import css from '../../assets/css/chat.module.css'
import ChatRoom from './chatRoom'
import ListConversation from './listConversation'
import { getMsg, getTwillio } from '../../store/chat'

const MainCounseling = () => {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const [thread, setThread] = useState(null)
    const [loadMsg, setLoadMsg] = useState(false)

    const pickThread = item => {
        if (thread) {
            if (thread.id != item.id) {
                setLoadMsg(true)
                setThread(item)
                dispatch(getMsg(item)).then(() => {
                    setLoadMsg(false)
                })
            }
        } else {
            setLoadMsg(true)
            setThread(item)
            dispatch(getMsg(item)).then(() => {
                setLoadMsg(false)

            })
        }

    }
    useEffect(() => {
        dispatch(getTwillio())
    }, [])

    return (
        <div className='container_chat'>
            <Card className='pa-2 rounded-lg' elevation={0} style={{ background: theme.secondary }}>
                <Grid container spacing={1} style={{ height: '100%' }}>
                    <Grid item xs={4}>
                        <Card className='py-3 pl-3 rounded-lg pr-1' elevation={0}>
                            <div className={css.list_conversation}>
                                <div className="d-flex justify-space-between align-center mb-3 pr-1">
                                    <p className={css.title_page}>Ruang Konsultasi</p>
                                    <Button className='pa-0' style={{ minWidth: '5px' }}>
                                        <MoreHoriz htmlColor={theme.primary} />
                                    </Button>
                                </div>
                                <ListConversation css={css} theme={theme} pickThread={pickThread} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={8}>
                        {
                            !loadMsg ?
                                <Card className='rounded-lg' elevation={0}>
                                    {thread ? <ChatRoom css={css} thread={thread} /> : <></>}
                                </Card> : <><p>loading</p></>}

                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default MainCounseling
