/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft } from "@mui/icons-material";
import {
  Button,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHANGE_TITLE } from "../../../../store/theming";
import FormCreatePopup from "./FormCreatePopup";

const tab = ["Detail Pop Up", "Jadwal Publish"];

const CreatePopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState("Detail Pop Up");

  useEffect(() => {
    dispatch(CHANGE_TITLE(`Tambah Pop Up`));
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg  mb-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeft /> Kembali
      </Button>
      <Box
        className="mb-5 mt-5"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs value={tabActive} onChange={(e) => setTabActive(e.target.id)}>
          {tab.map((value) => {
            return (
              <Tab
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                }}
                key={value}
                id={value}
                className="capitalize"
                label={value}
                value={value}
              />
            );
          })}
        </Tabs>
      </Box>
      <FormCreatePopup
        setLoading={setLoading}
        tabActive={tabActive}
        setTabActive={setTabActive}
      ></FormCreatePopup>
    </>
  );
};

export default CreatePopup;
