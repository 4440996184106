import React from 'react'

const PageNotFound = () => {
    const icon = require('../../assets/img/broken_link.png')
    return (
        <div className='d-flex flex-column align-center justify-center'>
            <img src={icon} alt="" />
            <h2 className="font-weight-bold">
                This page isn't available
            </h2>
            <p className='small_txt text-center' style={{ width: '50%' }}>
                This page isn't available
                The link may be broken, or the page may have been removed. Check to see if the link you're trying to open is correct.
            </p>
        </div>
    )
}

export default PageNotFound
