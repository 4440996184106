import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, ImageList, ImageListItem } from '@mui/material'
import EmptyData from '../../../../components/base/EmptyData'

const ViewProctoring = ({ v_proctor, pocket, close }) => {
    return (
        <div>
            {pocket ? <Dialog
                open={v_proctor}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                PaperProps={{
                    style: { borderRadius: 8 }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {pocket.nama}
                </DialogTitle>
                <DialogContent>
                    <div className='borbox' style={{ width: '800px' }}>
                        {pocket.foto_proctoring ?
                            <ImageList variant="masonry" cols={3} gap={8}>
                                {pocket.foto_proctoring.map((item, i) => (
                                    <ImageListItem key={i}>
                                        <img
                                            src={`${item}?w=248&fit=crop&auto=format`}
                                            srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                            // pocket.foto_proctoring.map((item, i) => (<div key={i} className=''> <img src={item} alt="Foto" /> </div>))
                            : <EmptyData txt='Tidak ada foto yang di tampilkan!' />}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' className='capitalize' onClick={() => close()}>Tutup</Button>
                </DialogActions>
            </Dialog> : <></>}

        </div>
    )
}

export default ViewProctoring
