import { Grid, Card, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TITLE } from '../../../store/theming'
import AllSchedule from './allSchedule'
import '../../../assets/css/reactCalendar.css';
import DatePickerSchedule from './datePickerSchedule'
const ManageScheduleConsultation = () => {
    const dispatch = useDispatch()
    // const theme = useSelector(state => state.theme.myTheme)
    const [selectedHour, setSelectedHour] = useState([])
    const availHour = ['08.00', '09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00', '20.00']
    const pickHour = (h) => {
        if (selectedHour.includes(h)) {
            let spliced = [...selectedHour]
            let index = spliced.indexOf(h)
            if (index > -1) {
                spliced.splice(index, 1)
            }
            setSelectedHour(spliced)
        } else {
            setSelectedHour([...selectedHour, h])
        }
    }
    useEffect(() => {
        dispatch(CHANGE_TITLE('Setting Jadwal Konsultasi'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <Grid container spacing={2} className='mb-4'>
                <Grid item xs={8}>
                    <Card className='pa-3 rounded-lg' >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div className="pr-2 left_bor" >
                                    <div style={{ minHeight: '90px' }}>
                                        <p className='font-weight-bold'>Jam Aktif Tersedia</p>
                                        <p className='small_txt mb-3'>Pilih jam aktif yang Anda inginkan.
                                            Pilihan jam berlaku untuk semua layanan aktif.</p>
                                    </div>
                                    <Grid container spacing={1}>
                                        {availHour.map((item, i) => (
                                            <Grid item xs={4} key={i}>
                                                <Button className='capitalize' style={{ width: '100%' }} onClick={() => pickHour(item)} variant={selectedHour.includes(item) ? 'contained' : 'outlined'} >
                                                    {item}
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ minHeight: '90px' }}>
                                    <p className='font-weight-bold'>Tanggal Aktif Tersedia</p>
                                    <p className='small_txt mb-3'>Tanggal aktif bisa dipilih lebih dari satu, dan berlaku
                                        untuk semua jam dan layanan aktif.</p>
                                </div>
                                <DatePickerSchedule />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card className='pa-3 rounded-lg' ></Card>
                </Grid>
            </Grid>
            <AllSchedule />
        </div>
    )
}

export default ManageScheduleConsultation
