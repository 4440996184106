
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { Paper, Skeleton, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import EmptyData from '../../../../components/base/EmptyData'
import { getApi } from '../../../../store/apis'
import SearchDataTable from '../../../../components/base/searchDataTable'

const PickKonsultasi = ({ picked, setPicked }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [dataSet, setData] = useState([])
    const [find, setFind] = useState('')
    const fetchData = () => {
        setLoading(true)
        let data = {
            path: `admin/konsultasi?filter[nama_konsultasi]=${find}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setData(data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setData([])
        })
    }

    const handleCheck = item => e => {
        const { checked } = e.target
        if (checked) {
            setPicked([...picked, item])
        } else {
            setPicked(picked.filter(a => a.id !== item.id))
        }
    }

    const skeletonLoader = () => {
        const items = [];
        for (let i = 0; i < 5; i++) {
            items.push(
                <Skeleton variant="rectangular" key={i} className='mb-2 rounded' height={40} />
            );
        }
        return <div>{items}</div>;
    };
    
    useEffect(() => {
        fetchData()
    }, [find])
    return (
        <div>
            <Paper className='pa-3 rounded-lg mt-3' elevation={0} sx={{ border: `2px solid ${theme.tertiary}` }}>
                <div className="mb-5">
                    <SearchDataTable value={find} setValue={setFind} />
                </div>
                <div className='pa-1 card_at styled_scroll'>
                    {!loading ? dataSet.length ?
                        dataSet.map((item, i) => <Paper className='px-3 py-1 rounded mb-2' key={i}>
                            <FormControlLabel
                                label={item.nama_konsultasi}
                                control={
                                    <Checkbox
                                        checked={picked.some(e => e.id === item.id)}
                                        onChange={handleCheck(item)}
                                    />
                                }
                            />
                        </Paper>) :
                        <EmptyData /> : skeletonLoader()}
                </div>
            </Paper>
        </div>
    )
}

export default PickKonsultasi
