/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi, postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import { Grid, Paper, Skeleton, Button, Divider } from "@mui/material";
import {
  Delete as DeleteIcon,
  Circle,
  ChevronLeft,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import CompanyClient from "./companyClient";
import CompanyVoucher from "./companyVoucher";
import { DialogDelete } from "../../../components/base/dialogDelete";
import ButtonEdit from "../../../components/base/ButtonEdit";

const CompanyDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const ava = useSelector((state) => state.theme.dummy);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [tab, setTab] = useState(1);
  const [pocket, setPocket] = useState(null);
  const [d_del, setDel] = useState(false);
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    find: "",
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/company/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };

  const onChangeStatus = () => {
    const data = {
      path: "admin/company/switch",
      body: {
        id: id,
        status: dataSet.status === 1 ? 0 : 1,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        setData({ ...dataSet, status: dataSet.status === 1 ? 0 : 1 });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const onDeleteAccount = () => {
    /**
     * ENUM DELETED
     * 0 = delete
     * 1 = restore
     */
    const data = {
      path: "admin/company/delete",
      body: {
        id_grup: id,
        deleted: "0",
      },
    };

    setPocket(data);
    setDel(true);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Company/Institution Detail"));
    fetchData();
  }, [state.limit, find, state.page]);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {!loading ? (
            dataSet ? (
              <div>
                <div className="d-flex justify-space-between align-center mb-3">
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft /> Kembali
                  </Button>
                  <div className="d-flex align-center">
                    <p className="small_txt font-weight-bold">
                      {dataSet.status === 1
                        ? "Non-Aktifkan Akun"
                        : "Aktifkan Akun"}{" "}
                    </p>
                    <Button onClick={onChangeStatus}>
                      {dataSet.status === 1 ? (
                        <Icon
                          path={mdiToggleSwitch}
                          size={1}
                          color={theme.primary}
                        />
                      ) : (
                        <Icon path={mdiToggleSwitchOffOutline} size={1} />
                      )}
                    </Button>
                  </div>
                </div>
                <Paper className="pa-5 rounded-lg" elevation={0}>
                  <div className="d-flex justify-space-between mb-2">
                    <div>
                      <h3>{dataSet.nama}</h3>
                      <p style={{ color: '#404040' }}> {dataSet.data_klien?.username ?? ''} </p>
                    </div>
                    <div>
                      <ButtonEdit
                        onClick={() =>
                          navigate(`/company/detail/${id}/edit`, {
                            state: { ...dataSet },
                          })
                        }
                      />
                      <Button
                        onClick={onDeleteAccount}
                        variant="contained"
                        className="rounded-lg capitalize ml-3"
                        disableElevation
                        size="small"
                        style={{
                          background: theme.act_red,
                          height: "max-content",
                        }}
                      >
                        <DeleteIcon
                          className="mr-1"
                          style={{ color: theme.act_lbl_red }}
                        />
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_red }}
                        >
                          {" "}
                          Delete
                        </p>
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Company/Institution
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Jenis Company/Institution
                                </p>
                                <p className="small_txt">{dataSet.jenis}</p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Email Company/Institution
                                </p>
                                <p className="small_txt">{dataSet.email}</p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  No. Telp.
                                </p>
                                <p className="small_txt">{dataSet.no_kontak}</p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Alamat
                                </p>
                                <p className="small_txt">{dataSet.alamat}</p>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Penanggung Jawab
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Nama Penanggung Jawab
                                </p>
                                <p className="small_txt">
                                  {dataSet.data_klien?.nama ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Email Penanggung Jawab
                                </p>
                                <p className="small_txt">
                                  {dataSet.data_klien?.email ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  No. HP
                                </p>
                                <p className="small_txt">
                                  {dataSet.data_klien?.no_telp ?? "-"}
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Access Permission
                          </p>
                          <div className="mt-2"></div>
                          <Grid item xs={12}>
                            <p
                              className="small_txt font-weight-bold"
                              style={{ color: theme.primary }}
                            >
                              Access Permission
                            </p>
                            {dataSet.data_klien?.permission.map((item) => {
                              return (
                                <p
                                  className="small_txt"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  ● {item}
                                </p>
                              );
                            }) ?? <></>}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ) : (
              <EmptyData />
            )
          ) : (
            <Skeleton
              variant="rounded"
              className="rounded-lg"
              style={{ minHeight: "300px", width: "100%" }}
            />
          )}
        </Grid>
      </Grid>

      {/* tabulaltion start here */}
      <div className="d-flex align-center mt-5">
        <Button
          className="capitalize mr-3 rounded-lg"
          onClick={() => setTab(1)}
          color={tab === 1 ? "primary" : "white"}
          disableElevation
          variant="contained"
        >
          <p className="font-weight-bold">List Pengguna</p>
        </Button>
        <Button
          className="capitalize rounded-lg font-weight-bold"
          onClick={() => setTab(2)}
          color={tab === 2 ? "primary" : "white"}
          disableElevation
          variant="contained"
        >
          <p className="font-weight-bold">List Voucher</p>
        </Button>
      </div>
      {tab === 2 ? <CompanyVoucher id={id} /> : <CompanyClient id={id} />}
      <div className="py-2">
        <DialogDelete
          pocket={pocket}
          d_del={d_del}
          close={() => setDel(false)}
          refetch={() => navigate("/company-list")}
        />
      </div>
    </div>
  );
};

export default CompanyDetail;
