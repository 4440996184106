/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { CheckBoxOutlineBlank, CheckBox, KeyboardArrowDown, KeyboardArrowUp, Delete as DelIcon, DragIndicator, } from '@mui/icons-material'
import { Button, Grid, Paper, Skeleton, Collapse } from '@mui/material'
import { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EmptyData from '../../../../components/base/EmptyData'
import { getApi } from '../../../../store/apis'
import DraggableList from 'react-draggable-lists';

const PickAlatTest = ({ picked, setPicked }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [dataSet, setData] = useState([])
    const fetchData = () => {
        setLoading(true)
        let data = {
            path: `admin/alat-test`
        }
        dispatch(getApi(data)).then(data => {
            let temp = JSON.parse(JSON.stringify(data))
            temp.forEach(item => {
                if (item.sub_alat_test.length) {
                    item['open'] = false
                }
            })
            setLoading(false)
            setData(temp)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setData([])
        })
    }

    const handleOpener = idx => {
        let temp = [...dataSet]
        temp[idx].open = !temp[idx].open
        setData(temp)
    }


    const pickItem = item => {
        let at = {
            id: item.id,
            nama_alat: item.nama_alat,
            id_parent: item.id_parent ?? item.id
        }
        if (picked.length) {
            let found = picked.find(el => el.id == item.id)
            console.log(found)
            if (!found) {
                setPicked([...picked, at])
            }
        } else {
            setPicked([...picked, at])
        }
    }

    const removeItem = item => {
        setPicked(picked.filter(a => a.id !== item.id))
    }

    useEffect(() => {
        fetchData()
    }, [])

    const skeletonLoader = () => {
        const items = [];
        for (let i = 0; i < 5; i++) {
            items.push(
                <Skeleton variant="rectangular" key={i} className='mb-2 rounded' height={40} />
            );
        }
        return <div>{items}</div>;
    };

    const Item = ({ item, itemSelected, dragHandleProps }) => {
        const { onMouseDown, onTouchStart } = dragHandleProps;

        return (
            <Paper className='pa-3 mb-2 rounded'>
                <div className='d-flex'>
                    <DragIndicator color='secondary' className='mr-2 grabbable'
                        onTouchStart={(e) => {
                            e.preventDefault();
                            e.target.style.backgroundColor = "blue";
                            document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                        onMouseDown={(e) => {
                            document.body.style.overflow = "hidden";
                            onMouseDown(e);
                        }}
                        onTouchEnd={(e) => {
                            e.target.style.backgroundColor = "black";
                            document.body.style.overflow = "visible";
                        }}
                        onMouseUp={() => {
                            document.body.style.overflow = "visible";
                        }}
                    />
                    <p className="small_txt cursor_pointer font-weight-bold flexy">{item.nama_alat} </p>
                    <Button color='red' onClick={() => removeItem(item)} style={{ minWidth: '0', padding: '0' }}>
                        <DelIcon />
                    </Button>

                </div>
            </Paper>
        );
    };

    const containerRef = useRef();

    const _onListChange = (newList) => {
        setPicked(newList)
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                        Pilihan Alat Tes
                    </p>
                    <Paper className='pa-1 rounded-lg' elevation={0} sx={{ border: `2px solid ${theme.tertiary}` }}>
                        <div className="pa-2  card_at styled_scroll">
                            {!loading ? dataSet.length ?
                                dataSet.map((item, i) =>
                                    <Paper className='pa-3 mb-2 rounded' key={i}>
                                        <div className='d-flex'>
                                            {picked.some(e => e.id === item.id) ? <CheckBox onClick={() => removeItem(item)} color='secondary' className='mr-2 cursor_pointer' />
                                                : <CheckBoxOutlineBlank onClick={() => pickItem(item)} color='secondary' className='mr-2 cursor_pointer' />}
                                            <p onClick={() => pickItem(item)} className="small_txt cursor_pointer font-weight-bold flexy">{item.nama_alat} </p>
                                            {item.sub_alat_test.length ?
                                                <Button onClick={() => handleOpener(i)} style={{ minWidth: '0', padding: '0' }}>
                                                    {item.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                </Button> : <></>}
                                        </div>
                                        <Collapse in={item.open} timeout="auto" unmountOnExit className='mt-3'>
                                            {
                                                item.open ? item.sub_alat_test.map((sub, j) =>
                                                    <Paper className='pa-3 mb-2 rounded' key={`sub-${j}`}>
                                                        <div className='d-flex'>
                                                            {picked.some(e => e.id === sub.id) ? <CheckBox onClick={() => removeItem(sub)} color='secondary' className='mr-2 cursor_pointer' />
                                                                : <CheckBoxOutlineBlank onClick={() => pickItem(sub)} color='secondary' className='mr-2 cursor_pointer' />}
                                                            {/* <CheckBoxOutlineBlank onClick={() => pickItem(sub)} color='secondary' className='mr-2' /> */}
                                                            <p onClick={() => pickItem(sub)} className="small_txt cursor_pointer font-weight-bold flexy">{sub.nama_alat} </p>
                                                        </div>
                                                    </Paper>) : <></>
                                            }
                                        </Collapse>
                                    </Paper>)
                                : <EmptyData /> : skeletonLoader()}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                        Alat Tes yang Dipilih
                    </p>
                    <Paper className='pa-2 rounded-lg' elevation={0} sx={{ background: theme.tertiary }}>
                        <div className='pa-1 card_at styled_scroll'>
                            {picked.length ?
                                <div ref={containerRef}>
                                    <DraggableList
                                        itemKey="id"
                                        template={Item}
                                        list={picked}
                                        onMoveEnd={(newList) => _onListChange(newList)}
                                        container={() => containerRef.current}
                                    />
                                </div> : <div> <p className="small_txt text-center">Alat Tes yang di pilih akan tampil disini</p> </div>
                            }

                            {/* {picked.length ? picked.map((item, k) =>
                                <Paper className='pa-3 mb-2 rounded' key={`picked-${k}`}>
                                    <div className='d-flex'>
                                        <DragIndicator color='secondary' className='mr-2 grabbable' />
                                        <p className="small_txt cursor_pointer font-weight-bold flexy">{item.nama_alat} </p>
                                        <Button color='red' onClick={() => removeItem(item)} style={{ minWidth: '0', padding: '0' }}>
                                            <DelIcon />
                                        </Button>

                                    </div>
                                </Paper>
                            ) : <div> <p className="small_txt text-center">Alat Tes yang di pilih akan tampil disini</p> </div>} */}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default PickAlatTest
