/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddCircle, ArrowDropDown, ChevronLeft } from '@mui/icons-material'
import { Button, Grid, Pagination, Paper, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Backdrop, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
    Menu,
    MenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { getApi } from '../../../store/apis'
import SearchDataTable from '../../../components/base/searchDataTable'
import EmptyData from '../../../components/base/EmptyData'
import SkeletonTable from '../../../components/base/SkeletonTable'
import PostPointPackage from './postPointPackage'
import { DialogDelete } from '../../../components/base/dialogDelete'
const PointPackageList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const [find, setFind] = useState('')
    const [loading, setLoading] = useState({
        get: false,
        detail: false,
    })
    const [dataSet, setData] = useState([])
    const [state, setState] = useState({
        page: 1,
        limit: 10,
        last_page: null,
        total: null,
        tipeSort: null,
        asc: true,
    })

    const [pocket, setPocket] = useState(null)
    const [dialog, setDialog] = useState({
        create: false,
        delete: false,
    })
    const [form, setForm] = useState(null)

    const createData = () => {
        setPocket({
            path: 'admin/poin/store',
            title: 'Tambah Paket Poin',
            type: 'create'
        })
        setForm({
            poin: '',
            promo_poin: '',
            harga: '',
            potongan_harga: '',
            masa_aktif: '',
            deskripsi: '',
            exp_promo: '',
            exp_promo_harga: '',
        })
        setDialog({ ...dialog, create: true })
    }
    const updateData = item => {
        setPocket({
            path: 'admin/poin/update',
            title: 'Edit Paket Poin',
            type: 'update'
        })
        setForm({
            id: item.id,
            poin: item.poin,
            promo_poin: item.promo_poin,
            harga: item.harga,
            potongan_harga: item.potongan_harga,
            masa_aktif: item.masa_aktif,
            deskripsi: item.deskripsi,
            exp_promo: item.exp_promo ? new Date(item.exp_promo) : null,
            exp_promo_harga: item.exp_promo_harga ? new Date(item.exp_promo_harga) : null,
        })
        setDialog({ ...dialog, create: true })
    }
    const deleteData = item => {
        setPocket({
            path: `admin/poin/delete`,
            body: {
                id: item.id
            }
        })
        setDialog({ ...dialog, delete: true })
    }

    const fetchData = () => {
        setLoading({ ...loading, get: true })
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `admin/poin?filter[poin]=${find}${page}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading({ ...loading, get: false })
            setData(data.data)
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            console.log(err)
            setLoading({ ...loading, get: false })
            setData([])
        })
    }

    const handlePage = (event, value) => {
        setState({ ...state, page: value })
    }

    const listLimit = [10, 50, 100]



    useEffect(() => {
        fetchData()
    }, [state.limit, find, state.page])

    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.detail
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg  mb-3"
                onClick={() => navigate(`/poin-activity`)}
                disableElevation
            >
                <ChevronLeft /> Kembali
            </Button>
            <Paper className='pa-3 rounded-lg mt-3'>
                <Grid container spacing={2} className='mb-5'>
                    <Grid item xs={6}>
                        <div className="d-flex align-center">
                            <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
                            <Menu menuClassName='my_menu' menuButton={<Card className='px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2'><p className="small_txt font-weight-bold mr-3">{state.limit} Data</p><ArrowDropDown /></Card>} transition>
                                {listLimit.map((lm, i) => <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}><p className='font-weight-bold txt_table'>{lm} Data</p></MenuItem>)}
                            </Menu>
                            <SearchDataTable value={find} setValue={setFind} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="d-flex justify-end">
                            <Button
                                className='capitalize rounded-lg'
                                variant='contained'
                                onClick={createData}
                            >
                                <AddCircle className='mr-3' />
                                <p className="font-weight-bold">Tambah Paket</p>
                            </Button>
                        </div>
                    </Grid>

                </Grid>
                {!loading.get ? !dataSet.length ? <EmptyData /> :
                    <TableContainer component='div'>
                        <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                            <TableHead >
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={{ minWidth: '30px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>ID</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '130px', background: theme.tertiary }} className='py-1'  >
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Nominal</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' >
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Harga</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Masa Aktif</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Bonus Poin</p>
                                    </TableCell>
                                    {/* <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Exp. Promo</p>
                                    </TableCell> */}
                                    <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Diskon Harga</p>
                                    </TableCell>
                                    {/* <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Exp. Promo</p>
                                    </TableCell> */}
                                    <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Keterangan</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataSet.map((item, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <p className="small_txt font-weight-bold">
                                                {item.id}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className="small_txt font-weight-bold">
                                                {item.poin} Poin
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className="small_txt font-weight-bold">
                                                Rp{item.harga}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className="small_txt font-weight-bold">
                                                {item.masa_aktif} Bulan
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            {item.promo_poin ?
                                                <div><p className="small_txt font-weight-bold">
                                                    {item.promo_poin} Poin
                                                </p>
                                                    <p className="xsmall_txt grey-text">
                                                        Expired at {item.exp_promo}
                                                    </p> </div> : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {item.potongan_harga ?
                                                <div> <p className="small_txt font-weight-bold">
                                                    {item.potongan_harga}
                                                </p>
                                                    <p className="xsmall_txt grey-text">
                                                        Expired at {item.exp_promo_harga}
                                                    </p></div> : '-'}
                                        </TableCell>
                                        <TableCell>
                                            <p className="small_txt">
                                                {item.deskripsi ?? '-'}
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="d-flex">
                                                <Button
                                                    variant='contained'
                                                    className='rounded-xl capitalize mr-3'
                                                    disableElevation
                                                    size="small"
                                                    onClick={() => updateData(item)}
                                                    style={{ background: theme.act_orange }}>
                                                    <p className='font-weight-bold' style={{ color: theme.act_lbl_orange }}>Edit</p>
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    className='rounded-xl capitalize'
                                                    disableElevation size="small"
                                                    onClick={() => deleteData(item)}
                                                    style={{ background: theme.act_red }}>
                                                    <p className='font-weight-bold' style={{ color: theme.act_lbl_red }}>Delete</p>
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <SkeletonTable />}
                {dataSet.length ? <div className="d-flex align-center justify-space-between" style={{ width: '100%' }}>
                    <p className='txt_table'>Menampilkan {state.page} - {state.limit} dari {state.total} </p>
                    <div>
                        <Pagination sx={{ padding: '0' }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: '#fff' }} color="primary" />
                    </div>
                </div> : <></>}
            </Paper>
            <div className="pa-2">
                <PostPointPackage
                    dialog={dialog.create}
                    close={() => setDialog({ ...dialog, create: false })}
                    form={form}
                    setForm={setForm}
                    refetch={fetchData}
                    pocket={pocket}
                />
                <DialogDelete d_del={dialog.delete} close={() => setDialog({ ...dialog, delete: false })} pocket={pocket} refetch={fetchData} />
            </div>
        </div>
    )
}

export default PointPackageList
