/* eslint-disable eqeqeq */
import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import ListLayananPsikolog from './listLayananPsikolog'

const SettingLayananPage = () => {
    const [type, setType] = useState('psikolog')

    return (
        <div>
            <h3 className="mb-3">Setting Layanan</h3>
            <div className="d-flex mb-3">
                <Button
                    onClick={() => setType('psikolog')}
                    className='mr-3 capitalize  rounded-lg'
                    disableElevation
                    variant='contained'
                    color={type == 'psikolog' ? 'secondary' : 'white'}
                    style={{ width: '100px' }}
                >
                    <p className="font-weight-bold">Psikolog</p>
                </Button>
                <Button
                    onClick={() => setType('coach')}
                    className='mr-3 capitalize rounded-lg'
                    disableElevation
                    color={type == 'coach' ? 'secondary' : 'white'}
                    variant='contained'
                    style={{ width: '100px' }}>
                    <p className="font-weight-bold">Coach</p>
                </Button>
            </div>
            {type == 'psikolog' ? <ListLayananPsikolog /> : <></>}
        </div>
    )
}

export default SettingLayananPage
