import React, { useState } from 'react'
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip, tooltipClasses, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Icon from "@mdi/react"
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js"
import day from 'dayjs'
const ListIndividualUser = ({ theme, dataSet, ava, onChangeStatus }) => {
    const navigate = useNavigate()
    const dummyAva = () => {
        let ava = require(`../../../assets/img/ava/ava${Math.floor(Math.random() * 11) + 1}.png`)
        return ava
    }

    return (
        <div>
            <TableContainer component='div'>
                <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ minWidth: '90px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>User ID</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Nama User</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' >
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Email</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Nomor Hp</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'  >
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Kategori</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'  >
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Poin</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '135px', background: theme.tertiary }} className='py-1'  >
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tgl. Reg.</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Status</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{item.id}</TableCell>
                                <TableCell>
                                    <div className="d-flex align-center">
                                        <Avatar alt={item.nama} src={item.foto ? item.foto_link : dummyAva()} className="mr-3" />
                                        <p className=''> {item.nama} </p>
                                    </div>
                                </TableCell>
                                <TableCell style={{ maxWidth: '230px', overflow: 'hidden' }} >
                                    {item.email ? <Tooltip title={item.email} placement="top" followCursor={true} arrow>
                                        <p className='small_txt'>{item.email}</p>
                                    </Tooltip> : '-'}

                                </TableCell>
                                <TableCell>{item.no_telp}</TableCell>
                                <TableCell>{item.profesi}</TableCell>
                                <TableCell>{item.poin}</TableCell>
                                <TableCell ><p className='small_txt'>{day(item.created_at).format('DD/MM/YY | HH:mm')}</p></TableCell>
                                <TableCell align="center">
                                    <Button onClick={() => onChangeStatus(item)}>
                                        {item.status === 1 ?
                                            <Icon path={mdiToggleSwitch} size={1} color={theme.primary} /> : <Icon path={mdiToggleSwitchOffOutline} size={1} />
                                        }
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' className='rounded-xl capitalize mr-3' onClick={() => navigate(`/individual-user/detail/${item.id}`)} disableElevation size="small" style={{ background: theme.act_blue }}>
                                        <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }}>Detail</p>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pa-2">
            </div>
        </div>
    )
}

export default ListIndividualUser
