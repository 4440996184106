/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid } from '@mui/material'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux'
import { Close as CloseIcon, Notes } from '@mui/icons-material';
import { getApi } from '../../../../../store/apis';

const DetailPesertaCourse = ({ dialog = false, close, pocket, listSection, setSection, section, progress }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10 }
            }}
        >
            <DialogTitle id="alert-dialog-title" className='py-3'>
                <div className="d-flex justify-space-between">
                    <h4>Detail Peserta</h4>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>

                {pocket && dialog ?
                    <div className='borbox' style={{ width: '400px', padding: '5px' }}>
                        <p className='mb-3' style={{ color: theme.secondary }}>Identitas Peserta</p>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    User ID
                                </p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="small_txt">
                                    {pocket.id}
                                </p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nama
                                </p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="small_txt">
                                    {pocket.nama}
                                </p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nomor WA
                                </p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="small_txt">
                                    {pocket.no_telp}
                                </p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Email
                                </p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="small_txt">
                                    {pocket.email}
                                </p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Kategori
                                </p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="small_txt">
                                    {pocket.kategori}
                                </p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Status Pembayaran
                                </p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="small_txt">
                                    {pocket.pembayaran}
                                </p>
                            </Grid>
                        </Grid>
                        <p className='my-3' style={{ color: theme.secondary }}>Course Progress</p>
                        {listSection.map((item, i) => <div key={`section-${i}`}
                            className="steper cursor_pointer"
                            onClick={() => setSection(item)}
                            style={{
                                border: `2px solid ${theme.lighten}`,
                                color: theme.primary,
                                background: section.id == item.id ? theme.lighten : '#fff'
                            }}>1</div>)}
                        {progress.topik_progress.length ? <div className='mt-3'>
                            {progress.topik_progress.map((item, i) => <Grid container spacing={2} key={`topic-${i}`}>
                                <Grid item xs={3}>
                                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                        Topik {i + 1}
                                    </p>
                                </Grid>
                                <Grid item xs={9}>
                                    {item.data_course_peserta_history?.status == 'watched' ?
                                        <p className="small_txt font-weight-bold" style={{ color: '#7D37C3' }}>Done</p> :
                                        item.data_course_peserta_history?.status == 'watched' ?
                                            <p className="small_txt font-weight-bold" style={{ color: '#00AB97' }}>On Progress</p> : '-'}
                                </Grid>
                            </Grid>)}
                        </div> : <></>}
                        {progress.quiz_progress.length ? <div className='mt-3'>
                            {progress.quiz_progress.map((item, i) => <Grid container spacing={2} key={`quiz-${i}`}>
                                <Grid item xs={3}>
                                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                        Quiz
                                    </p>
                                </Grid>
                                <Grid item xs={9}>
                                    {item.data_quiz_jawaban ?
                                        <p className="small_txt font-weight-bold" style={{ color: '#7D37C3' }}>Done</p> : '-'}
                                </Grid>
                            </Grid>)}
                        </div> : <></>}
                    </div> : <></>
                }
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}

export default DetailPesertaCourse
