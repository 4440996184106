import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Button, Snackbar } from '@mui/material'
import { Search } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from '../../../../store/apis'
import SkeletonTable from '../../../../components/base/SkeletonTable'
import EmptyData from '../../../../components/base/EmptyData'
import ViewProctoring from '../testResult/viewProctoring'
const ListPsycotestClient = ({ id }) => {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const [find, setFind] = useState('')
    const [loading, setLoading] = useState(true)
    const [fetching, setFetch] = useState(false)
    const [dataSet, setData] = useState([])
    const [emptyTxt, setEmptyTxt] = useState('')
    const [state, setState] = useState({
        page: 1,
        limit: 5,
        total: null,
        last_page: null,
    })
    const [proctor, setProctor] = useState(null)
    const [dialog, setDialog] = useState(false)
    const viewProctor = (id) => {
        setFetch(true)
        let data = {
            path: `konselor/index-proctoring?id=${id}`
        }
        dispatch(getApi(data)).then(data => {
            setFetch(false)
            setProctor(data)
            setDialog(true)
        }).catch(err => {
            setFetch(false)
            setProctor([])
        })
    }
    const fetchData = () => {
        setLoading(true)
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `konselor/tes-psikologi-klien?filter[transaksi_pelanggan.id_pelanggan]=${id}&filter[transaksi_pelanggan.transaksi]=${find}${page}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setData(data.data)
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            if (err.status == 400) {
                if (find != '') {
                    setEmptyTxt(`${find} tidak cocok untuk data apapun!`)
                } else {
                    setEmptyTxt(`No Data Available`)
                }
            } else {
                setEmptyTxt(`${err.status}: ${err.data.message}`)
            }
            setLoading(false)
            setData([])
        })
    }
    const handlePage = (event, value) => {
        setState({ ...state, page: value })
    }
    useEffect(() => {
        fetchData()
    }, [find, state.page])
    return (
        <Paper className='pa-3 rounded-lg'>
            <Grid container spacing={2} >
                <Grid item xs={8}>
                    <h3>Tes Psikologi</h3>
                </Grid>
                <Grid item xs={4}>
                    <div className="temp-search-keyup mb-3">
                        <input type="text" value={find} onChange={event => setFind(event.target.value)} className='search-keyup' placeholder='Cari catatan' style={{ background: theme.tertiary }} />
                        <Search style={{ marginLeft: '-30px' }} color='primary' />
                    </div>
                </Grid>
            </Grid>
            {!loading ? !dataSet.length ? <EmptyData txt={emptyTxt} /> : <TableContainer component='div'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ borderBottom: 'none' }}>
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>No</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Judul Tes</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tanggal<br />Tes Selesai</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Waktu<br />Tes Selesai</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Status</p>
                            </TableCell>
                            <TableCell style={{ background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.nama_psikotes}</TableCell>
                                <TableCell align="center">{item.date}</TableCell>
                                <TableCell align="center">{item.time}</TableCell>
                                <TableCell align="center">
                                    <p className='font-weight-bold'
                                        style={{ color: item.status === 'Berjalan' ? '#00786A' : item.status === 'Selesai' ? '#6D0BCF' : item.status === 'Tidak Selesai' ? '#B80222' : '' }}
                                    >{item.status}</p>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" onClick={() => viewProctor(item.id)} style={{ background: '#99DDD5' }}>
                                        <p className='font-weight-bold' style={{ color: '#00786A' }}>Proctoring</p>
                                    </Button>
                                    {item.status !== 'Berjalan' ?
                                        <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: '#D0BAFF' }}>
                                            <p className='font-weight-bold' style={{ color: '#6D0BCF' }}>Lihat Hasil</p>
                                        </Button> : <></>}

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> :
                <SkeletonTable />
            }
            {dataSet.length ? <div className="d-flex align-center justify-space-between" style={{ width: '100%' }}>
                <p className='txt_table'>Menampilkan {state.page} - {state.limit} dari {state.total} </p>
                <div>
                    <Pagination sx={{ padding: '0' }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: '#fff' }} color="primary" />
                </div>
            </div> : <></>}
            <div className="pa-2">
                <ViewProctoring v_proctor={dialog} pocket={proctor} close={() => setDialog(false)} />
                <Snackbar
                    open={fetching}
                    message="Memuat data..."
                />
            </div>
        </Paper>
    )
}

export default ListPsycotestClient
