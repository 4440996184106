/* eslint-disable eqeqeq */
import { Grid, Paper, Divider, Button } from '@mui/material'
import React from 'react'
import FlatInput from '../../../../components/base/flatInput'
import styleInput from '../../../../assets/css/search.module.css'
/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { DateRange, DoneAll } from '@mui/icons-material'

const DetailHargaTraining = ({ theme, priceDetail, setPriceDetail }) => {

    const handleInput = (prop) => (event) => {
        let rawData = [...priceDetail]
        let idx = rawData.findIndex(el => el.id == prop.id)
        if (idx > -1) {
            if (prop.label == 'dateEarlyBird') {
                rawData[idx][prop.label] = event
            } else {
                rawData[idx][prop.label] = event.target.value
            }
            setPriceDetail(rawData)
        }
    }

    const fillAll = () => {
        let rawData = [...priceDetail]
        let firstIdx = rawData[0]
        rawData.forEach((el, i) => {
            if (i != 0) {
                el['earlyBird'] = firstIdx.earlyBird
                el['dateEarlyBird'] = firstIdx.dateEarlyBird
                el['hargaNormal'] = firstIdx.hargaNormal
            }
        })
        setPriceDetail(rawData)
    }

    return (
        <Paper className='pa-5 rounded-lg mb-3'>
            <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>Detail Harga</p>
            {priceDetail.length ?
                priceDetail.map((item, i) => <div className='mb-3' key={i}>
                    <div className="d-flex">
                        <div className='d-flex justify-center align-center mr-2' style={{ width: '25px', height: '25px', borderRadius: '50%', background: theme.lighten }}>
                            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>{i + 1}</p>
                        </div>
                        <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>{item.label}</p>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Harga Earlybirds</p>
                            <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila tidak ada</p>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FlatInput
                                        value={item.earlyBird}
                                        handleChange={handleInput}
                                        type='number'
                                        property={{ id: item.id, label: 'earlyBird' }}
                                        placeholder='Harga Earlybird'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                        <DateRange className="mx-2" />
                                        <div className="flexy">
                                            <DatePicker
                                                placeholderText='Tanggal Batas Earlybird'
                                                selected={item.dateEarlyBird}
                                                onChange={handleInput({ id: item.id, label: 'dateEarlyBird' })}
                                                css={emot`
                                                    background: ${item.dateEarlyBird ? '' : theme.tertiary};
                                                    outline: 2px solid ${theme.tertiary};
                                                    border-radius: 5px;
                                                    &:focus {
                                                        background: #fff;
                                                    }`}
                                                dateFormat="dd MMMM yyyy"
                                                className={styleInput.input_psy}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Harga Normal</p>
                        </Grid>
                        <Grid item xs={9}>
                            <FlatInput
                                value={item.hargaNormal}
                                handleChange={handleInput}
                                type='number'
                                property={{ id: item.id, label: 'hargaNormal' }}
                                placeholder='Harga Normal'
                            />
                        </Grid>
                    </Grid>
                    {i == 0 ? <Button className='capitalize  mt-2' onClick={fillAll}><p className="mr-2 small_txt">
                        Terapkan untuk semua</p> <DoneAll /></Button> : <></>}
                    <div className="py-2">
                        <Divider></Divider>
                    </div>
                </div>) : <></>}
        </Paper>
    )
}

export default React.memo(DetailHargaTraining)
