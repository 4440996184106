import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../../pages/auth/Login";
import { ListClient } from "../../pages/dashboard/client/ListClient";
import Main from "../../pages/Main";
import MainOverview from "../../pages/overview/MainOverview";
import PublicRoute from "./PublicRoute";
import AuthRoute from "./AuthRoute";
import { AllNote } from "../overview/notes/allNotes";
import LogActivityClient from "../../pages/dashboard/client/log/logActivityClient";
import ProfileKlien from "../../pages/dashboard/client/profile/profileKlien";
import ListTestResult from "../../pages/dashboard/client/testResult/listTestResult";
import ListScheduleCounseling from "../../pages/dashboard/schedule/listSchedule";
import TestResultDetail from "../../pages/dashboard/client/testResult/testResultDetail";
import ManageScheduleConsultation from "../../pages/dashboard/schedule/ManageScheduleConsultation";
import PageNotFound from "../../pages/auth/PageNotFound";
import MainCounseling from "../../pages/counseling/mainCounseling";
import IndividualUserPage from "../../pages/dashboard/IndividualUser/individualUserPage";
import IndividualUserDetail from "../../pages/dashboard/IndividualUser/individualUserDetail";
import IndividualUserEdit from "../../pages/dashboard/IndividualUser/IndividualUserEdit";
import PsikologPage from "../../pages/dashboard/Psikolog/psikologPage";
import PsikologDetail from "../../pages/dashboard/Psikolog/PsikologDetail";
import PsikologEdit from "../../pages/dashboard/Psikolog/PsikologEdit";
import CompanyPage from "../../pages/dashboard/Company/companyPage";
import CompanyDetail from "../../pages/dashboard/Company/CompanyDetail";
import CompanyEdit from "../../pages/dashboard/Company/CompanyEdit";
import CompanyVoucherDetail from "../../pages/dashboard/Company/companyVoucherDetail";
import PsikotesActivityPage from "../../pages/dashboard/psikotes/psikotesActivityPage";
import PsikotesSettingPage from "../../pages/dashboard/psikotes/psikotesSettingPage";
import PsikotesKategori from "../../pages/dashboard/psikotes/kategori/psikotesKategori";
import PsikotesCreate from "../../pages/dashboard/psikotes/psikotesCreate";
import KonselingActivityPage from "../../pages/dashboard/konselingManagement/konselingActivityPage";
import KonselingKategori from "../../pages/dashboard/konselingManagement/kategori/konselingKategori";
import KonselingSetting from "../../pages/dashboard/konselingManagement/konselingSetting";
import KonselingActivityDetail from "../../pages/dashboard/konselingManagement/KonselingActivityDetail";
import CreateLayananKonsultasi from "../../pages/dashboard/konselingManagement/layanan/createLayananKonsultasi";
import PsikotesDetail from "../../pages/dashboard/psikotes/psikotesDetail";
import LayananKonselingDetail from "../../pages/dashboard/konselingManagement/layanan/layananKonselingDetail";
import TrainingActivityPage from "../../pages/dashboard/trainingAndCourse/trainingActivityPage";
import TrainingKategori from "../../pages/dashboard/trainingAndCourse/kategori/trainingKategori";
import TrainingFormList from "../../pages/dashboard/trainingAndCourse/form/trainingFormList";
import CreateTraining from "../../pages/dashboard/trainingAndCourse/createTraning";
import CreateFormTraining from "../../pages/dashboard/trainingAndCourse/form/createFormTraining";
import CourseActivityPage from "../../pages/dashboard/trainingAndCourse/courseActivityPage";
import CreateCourse from "../../pages/dashboard/trainingAndCourse/createCourse";
import DetailTrainingPage from "../../pages/dashboard/trainingAndCourse/detail/Training/DetailTrainingPage";
import DetailCoursePage from "../../pages/dashboard/trainingAndCourse/detail/Course/DetailCoursePage";
import ListSection from "../../pages/dashboard/trainingAndCourse/section/listSection";
import ManageTopic from "../../pages/dashboard/trainingAndCourse/section/manageTopic";
import ManageQuiz from "../../pages/dashboard/trainingAndCourse/section/manageQuiz";
import UpdateCourse from "../../pages/dashboard/trainingAndCourse/updateCourse";
import PoinActivityPage from "../../pages/dashboard/poin/poinActivityPage";
import PointPackageList from "../../pages/dashboard/poin/PointPackageList";
import PointReport from "../../pages/dashboard/poin/poinReport";
import TrxActivityPage from "../../pages/dashboard/transaction/trxActivityPage";
import TrxReport from "../../pages/dashboard/transaction/trxReport";
import VcrActivityPage from "../../pages/dashboard/Voucher/vcrActivityPage";
import VcrCreate from "../../pages/dashboard/Voucher/vcrCreate";
import VcrDetail from "../../pages/dashboard/Voucher/vcrDetail";
import PopupBannerActivity from "../../pages/dashboard/advertising/popupAndBanner/popupBannerActivity";
import CreatePopup from "../../pages/dashboard/advertising/popupAndBanner/CreatePopup";

const RouterSetup = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route path="/" element={<Main />}>
            <Route index element={<MainOverview />} />
            {/* Konselor */}
            <Route path="/client-list" element={<ListClient />} />
            <Route path="/all-note" element={<AllNote />} />
            <Route path="/log-client/:id" element={<LogActivityClient />} />
            <Route path="/profile-client/:id" element={<ProfileKlien />} />
            <Route path="/client-test-result" element={<ListTestResult />} />
            <Route path="/schedule-list" element={<ListScheduleCounseling />} />
            <Route
              path="/schedule-setting"
              element={<ManageScheduleConsultation />}
            />
            <Route path="/test-result/:id" element={<TestResultDetail />} />
            <Route path="/counseling" element={<MainCounseling />} />
            {/* Admin user management */}
            <Route path="/individual-user" element={<IndividualUserPage />} />
            <Route
              path="/individual-user/detail/:id"
              element={<IndividualUserDetail />}
            />
            <Route
              path="/individual-user/detail/:id/edit"
              element={<IndividualUserEdit />}
            />
            <Route path="/counselor-list" element={<PsikologPage />} />
            <Route path="/counselor/detail/:id" element={<PsikologDetail />} />
            <Route
              path="/counselor/detail/:id/edit"
              element={<PsikologEdit />}
            />
            <Route path="/company-list" element={<CompanyPage />} />
            <Route path="/company/detail/:id" element={<CompanyDetail />} />
            <Route path="/company/detail/:id/edit" element={<CompanyEdit />} />
            <Route
              path="/voucher/detail/:id"
              element={<CompanyVoucherDetail />}
            />
            {/* Admin service management */}
            <Route
              path="/psikotes-activity"
              element={<PsikotesActivityPage />}
            />
            <Route path="/psikotes-setting" element={<PsikotesSettingPage />} />
            <Route path="/psikotes/detail/:id" element={<PsikotesDetail />} />
            <Route
              path="/konseling-activity/detail/:id"
              element={<KonselingActivityDetail />}
            />
            <Route path="/psikotes-create" element={<PsikotesCreate />} />
            <Route path="/psikotes-kategori" element={<PsikotesKategori />} />
            <Route
              path="/konseling-activity"
              element={<KonselingActivityPage />}
            />
            <Route path="/konseling-kategori" element={<KonselingKategori />} />
            <Route path="/konseling-setting" element={<KonselingSetting />} />
            <Route
              path="/konseling-create"
              element={<CreateLayananKonsultasi />}
            />
            <Route
              path="/konseling/detail/:id"
              element={<LayananKonselingDetail />}
            />
            <Route
              path="/training-activity"
              element={<TrainingActivityPage />}
            />
            <Route path="/training-post/:type" element={<CreateTraining />} />
            <Route
              path="/training-detail/:id"
              element={<DetailTrainingPage />}
            />
            <Route path="/training-kategori" element={<TrainingKategori />} />
            <Route path="/training-form" element={<TrainingFormList />} />
            <Route
              path="/training-form/:type"
              element={<CreateFormTraining />}
            />
            <Route path="/course-activity" element={<CourseActivityPage />} />
            <Route path="/course-create" element={<CreateCourse />} />
            <Route path="/course-update/:id" element={<UpdateCourse />} />
            <Route path="/course-detail/:id" element={<DetailCoursePage />} />
            <Route path="/course-section/:id" element={<ListSection />} />
            <Route
              path="/course-section/topic/:idCourse/:idSection/:idModule"
              element={<ManageTopic />}
            />
            <Route
              path="/course-section/quiz/:idCourse/:idSection/:type"
              element={<ManageQuiz />}
            />
            {/* Admin Transaction Management */}
            <Route path="/poin-activity" element={<PoinActivityPage />} />
            <Route path="/poin-package" element={<PointPackageList />} />
            <Route path="/poin-report" element={<PointReport />} />
            <Route path="/transaksi-activity" element={<TrxActivityPage />} />
            <Route path="/transaksi-report" element={<TrxReport />} />
            <Route path="/voucher-activity" element={<VcrActivityPage />} />
            <Route path="/voucher-detail/:id" element={<VcrDetail />} />
            <Route path="/voucher/post/:type" element={<VcrCreate />} />
            {/* Admin advertising Management */}
            <Route
              path="/popup-banner-activity"
              element={<PopupBannerActivity />}
            />
            <Route
              path="/popup-banner-activity/create-popup"
              element={<CreatePopup />}
            />

            {/* etc */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterSetup;
