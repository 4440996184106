import React from "react";
import { useSelector } from "react-redux";
import styleInput from "../../assets/css/search.module.css";
/** @jsxImportSource @emotion/react */
import { css as emot } from "@emotion/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateRange } from "@mui/icons-material";

const FlatDatePicker = ({
  value,
  property,
  handleChange,
  placeholder = "",
  min = ''
}) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <div
      className="d-flex align-center"
      style={{
        background: theme.tertiary,
        borderRadius: "5px",
        padding: "2px",
      }}
    >
      <DateRange className="mx-2" />

      <div className="flexy">
        <DatePicker
          placeholderText={placeholder}
          selected={value}
          onChange={handleChange(property)}
          minDate={min}
          css={emot`
                    background: ${value ? "#ff" : theme.tertiary};
                    outline: 2px solid ${theme.tertiary};
                    border-radius: 5px;
                    &:focus { 
                        background: #fff;
                    }`}
          dateFormat="dd MMMM yyyy"
          className={styleInput.input_psy}
        />
      </div>
    </div>
  );
};

export default FlatDatePicker;
