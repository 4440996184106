/* eslint-disable eqeqeq */
import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
} from "@mui/material";

const PoinActivityList = ({ theme, dataSet, page, limit }) => {
    const endpoint = process.env.REACT_APP_HOST;
    return (
        <div>
            <TableContainer component="div">
                <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                    <TableHead>
                        <TableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell
                                style={{
                                    minWidth: "50px",
                                    background: theme.tertiary,
                                    borderRadius: "10px 0 0 10px",
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    No.
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "80px",
                                    background: theme.tertiary,
                                }}
                                className="py-2"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    ID User
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "200px",
                                    background: theme.tertiary,
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Nama User
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "150px", background: theme.tertiary }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Email
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Nominal
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                align="center"
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Harga Pokok
                                </p>
                            </TableCell>
                            {/* <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Harga Jual
                                </p>
                            </TableCell> */}
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Status
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "150px",
                                    background: theme.tertiary,
                                    borderRadius: "0 10px 10px 0",
                                }}
                                className="py-1"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Metode
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell>{i + 1 + (page - 1) * limit}</TableCell>
                                <TableCell
                                    align="center"
                                >{item.data_pelanggan.id}</TableCell>
                                <TableCell>
                                    <div className="d-flex align-center">
                                        <Avatar alt={item.data_pelanggan.nama} src={`${endpoint}/temanbakat/pelanggan/${item.data_pelanggan.foto}`} className="mr-3" />
                                        <p className="small_txt">
                                            {item.data_pelanggan.nama}
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.data_pelanggan.email}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className="small_txt">
                                        {item.jumlah} Poin
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.harga}
                                    </p>
                                </TableCell>
                                {/* <TableCell align="center">
                                    <p className="small_txt">
                                        {item.harga}
                                    </p>
                                </TableCell> */}
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.status}
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.via}
                                    </p>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div >
    );
};

export default PoinActivityList;
