import React from "react";
import { Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";

const ButtonEdit = ({ onClick }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  return (
    <Button
      onClick={onClick}
      variant="contained"
      className="rounded-lg capitalize ml-3"
      disableElevation
      size="small"
      style={{
        background: theme.primary,
        height: "max-content",
      }}
    >
      <Edit className="mr-1" style={{ color: theme.white }} />
      <p className="font-weight-bold" style={{ color: theme.white }}>
        Ubah
      </p>
    </Button>
  );
};

export default ButtonEdit;
