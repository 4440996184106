import { Avatar, Divider, Skeleton } from '@mui/material'
import { Search } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import LinesEllipsis from 'react-lines-ellipsis'
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Cookies from "js-cookie";
import { getThread, getMsg } from '../../store/chat';

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
require('dayjs/locale/de')

const ListConversation = ({ css, pickThread }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const ava = useSelector(state => state.theme.dummy)
    const thread = useSelector(state => state.chat.thread)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    const skeletonLoader = () => {
        const items = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                <Skeleton variant="rectangular" key={i} className='mb-3 rounded-lg' height={60} />
            );
        }
        return <div>{items}</div>;
    };
    const idUser = Cookies.get('id')
    useEffect(() => {
        dispatch(getThread(idUser)).then(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div className='pr-1'>
            <div className="d-flex align-center">
                <Avatar alt='my' src={ava} sx={{ width: 56, height: 56 }} className="mr-3" />
                <div>
                    <p className='small_txt font-weight-bold mb-0' style={{ color: '#05D3BB' }}>Online</p>
                    <p className='font-weight-bold' style={{ color: theme.primary }}>Marcus Cho, M.Psi., Psikolog</p>
                </div>
            </div>
            <div className="temp-search-keyup my-3">
                <input type="text" className='search-keyup' placeholder='Cari Klien' style={{ background: theme.secondary }} />
                <Search style={{ marginLeft: '-30px' }} color='primary' />
            </div>
            <div className="list_conversation">
                {!loading ?
                    thread.map((item, i) => (
                        <div className={`mb-2 px-2 py-1 rounded-lg ${css.item_conversation}`} onClick={() => pickThread(item)} key={i}>
                            <div className="d-flex align-center mb-2">
                                <Avatar alt={item.pelanggan_nama} src={item.avatar} sx={{ width: 50, height: 50 }} className="mr-3" />
                                <div className="d-flex flexy">
                                    <div className='flexy'>
                                        <LinesEllipsis
                                            className='font-weight-bold' style={{ color: theme.primary, fontSize: '15px' }}
                                            text={item.pelanggan_nama}
                                        />
                                        <p className='small_txt' style={{ fontSize: '12px' }}>{item.title}</p>
                                    </div>
                                    <div style={{ width: '50px' }}>
                                        <p className='small_txt' style={{ fontSize: '10px' }}>{item.wkt_konsultasi} WIB</p>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    )) : skeletonLoader()
                }
            </div>
        </div>
    )
}

export default ListConversation
