import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getApi } from '../../../../store/apis'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import ViewProctoring from './viewProctoring'
const TableTestResult = ({ theme, dataSet, ava }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [proctor, setProctor] = useState(null)
    const [dialog, setDialog] = useState(false)
    const viewProctor = (id) => {
        setLoading(true)
        let data = {
            path: `konselor/index-proctoring?id=${id}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setProctor(data)
            setDialog(true)
            // setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            setLoading(false)
            setProctor([])
        })
    }
    return (
        <div>
            <TableContainer component='div'>
                <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                    <TableHead >
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ minWidth: '30px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>No</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Nama Klien</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tanggal<br />Tes Selesai</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Waktu<br />Tes Selesai</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '150px', background: theme.tertiary }} className='py-1'>
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Judul Psikotes</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Status</p>
                            </TableCell>
                            <TableCell style={{ minWidth: '100px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1' align="center">
                                <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>
                                    <div className="d-flex align-center">
                                        <Avatar alt={item.nama} src={item.foto_link ? item.foto_link : ava} className="mr-3" />
                                        <p className='txt_table'> {item.nama}</p>
                                    </div>
                                </TableCell>
                                <TableCell align="center">{item.date}</TableCell>
                                <TableCell align="center">{item.time} {item.time ? 'WIB' : ''}</TableCell>
                                <TableCell>{item.nama_psikotes}</TableCell>
                                <TableCell align="center">
                                    <p className='font-weight-bold'
                                        style={{ color: item.status === 'Berjalan' ? '#00786A' : item.status === 'Selesai' ? '#6D0BCF' : item.status === 'Tidak Selesai' ? '#B80222' : '' }}
                                    >{item.status}</p>
                                </TableCell>
                                <TableCell>
                                    <div className="d-flex align-center">
                                        <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: theme.act_pink }} onClick={() => navigate(`/profile-client/${item.id_pelanggan}`)}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_pink }}>Profile</p>
                                        </Button>
                                        <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: theme.act_blue }}>
                                            <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }} onClick={() => viewProctor(item.id)}>Proctoring</p>
                                        </Button>
                                        {item.status !== 'Berjalan' ?
                                            <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: theme.act_purple, width: '96px' }} onClick={() => navigate(`/test-result/${item.id_pelanggan}`)}>
                                                <p className='font-weight-bold' style={{ color: theme.act_lbl_purple }}>Lihat Hasil</p>
                                            </Button> : <></>}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pa-2">
                <ViewProctoring v_proctor={dialog} pocket={proctor} close={() => setDialog(false)} />
                <Snackbar
                    open={loading}
                    message="Memuat data..."
                />
            </div>
        </div>
    )
}

export default TableTestResult
