import React from 'react'
import { useSelector } from 'react-redux'
import css from '../../assets/css/search.module.css'
import { Search, Close } from '@mui/icons-material'

const SearchDataTable = ({ value, placeholder = 'Search', setValue }) => {
    const theme = useSelector(state => state.theme.myTheme)
    return (
        <div className='pa-1 d-flex flexy align-center rounded-lg' style={{ background: theme.lighten }}>
            <input
                type='text'
                value={value}
                className={css.dtbl_input}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                style={{ background: theme.lighten }}
            />
            {value.length ? <Close className='cursor_pointer' onClick={() => setValue('')} style={{ color: theme.primary }} /> : <Search style={{ color: theme.primary }} />}
        </div>
    )
}

export default SearchDataTable