import { Grid, Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import { ManageNotePsy } from '../../components/overview/notes/ManageNotePsy'

const OverviewPsy = () => {
    const theme = useSelector(state => state.theme.myTheme)
    return (
        <div>
            <Paper className='pa-3 rounded-lg mb-3' elevation={0} style={{ height: '300px' }}>
                <div className="d-flex justify-space-between">
                    <div>
                        <h3>Aktivitas Hari ini</h3>
                    </div>
                    <div>
                        Semua Jadwal
                    </div>
                </div>
            </Paper>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Paper className='pa-3 rounded-lg mb-3' elevation={0} style={{ height: '300px' }}>

                    </Paper>
                </Grid>
            <Grid item xs={4}>
                    <Paper className='pa-3 rounded-lg mb-3' elevation={0} style={{ background: theme.primary, height: '70px' }}>

                    </Paper>
                    <ManageNotePsy theme={theme} />
                </Grid>
            </Grid>
        </div>
    )
}

export default OverviewPsy
