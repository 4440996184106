/* eslint-disable eqeqeq */
import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
    Button,
} from "@mui/material";
import day from 'dayjs'
import LinesEllipsis from "react-lines-ellipsis";

const TrxActivityList = ({ theme, dataSet, page, limit }) => {
    return (
        <div>
            <TableContainer component="div">
                <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                    <TableHead>
                        <TableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell
                                style={{
                                    minWidth: "50px",
                                    background: theme.tertiary,
                                    borderRadius: "10px 0 0 10px",
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Invoice
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "100px",
                                    background: theme.tertiary,
                                }}
                                className="py-2"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Tanggal & Jam
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "200px",
                                    background: theme.tertiary,
                                }}
                                className="py-2"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    User
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Kategori
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                align="center"
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Sub Total
                                </p>
                            </TableCell>
                            <TableCell
                                style={{ minWidth: "100px", background: theme.tertiary }}
                                className="py-1"
                                align="center"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Status
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "150px",
                                    background: theme.tertiary,
                                }}
                                align="center"
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Metode
                                </p>
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: "150px",
                                    background: theme.tertiary,
                                    borderRadius: "0 10px 10px 0",
                                }}
                                align="center"
                                className="py-1"
                            >
                                <p
                                    className="font-weight-bold"
                                    style={{ color: theme.primary }}
                                >
                                    Tindakan
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSet.map((item, i) => (
                            <TableRow
                                key={i}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell
                                    align="center"
                                >{item.id_invoice}</TableCell>
                                <TableCell
                                    align="center"
                                >{day(item.tgl_checkout).format('DD/MM/YY')} {item.wkt_checkout.slice(0, -3)}</TableCell>
                                <TableCell>
                                    <p className="small_txt" style={{ fontWeight: 500 }}>
                                        {item.data_pelanggan.nama}
                                    </p>
                                    <p className="small_txt">
                                        {item.data_pelanggan.email}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <div className="d-flex">
                                        {item.data_detail_transaksi.length ?
                                            <div className="box_pemateri flexy mr-1">
                                                <LinesEllipsis className="small_txt text-center" text={item.data_detail_transaksi[0].tipe ?? '-'} />
                                            </div> : <></>}
                                        {item.data_detail_transaksi.length > 1 ? <div className='box_pemateri small_txt'> +{item.data_detail_transaksi.length - 1} </div> : <></>}
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.harga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.status}
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="small_txt">
                                        {item.metode_pembayaran_decode?.deskripsi ?? item.metode_pembayaran_decode?.metode}
                                    </p>
                                </TableCell>
                                <TableCell align="center">
                                    <Button variant='contained' className='rounded-xl capitalize mr-3' disableElevation size="small" style={{ background: theme.act_blue }}>
                                        <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }}>Detail</p>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div >
    );
};

export default TrxActivityList;
