import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import SkeletonTable from "../../../components/base/SkeletonTable";
import { ArrowDropDown } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import {
  Button,
  Grid,
  Pagination,
  Paper,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import day from "dayjs";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
const CompanyVoucher = ({ id }) => {
  const [dialog, setDialog] = useState({
    post: false,
    view: false,
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const ava = useSelector((state) => state.theme.dummy);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [pocket, setPocket] = useState(null);
  const [formateDate, setDate] = useState(null);

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/company/voucher/voucher-pelanggan?id=${id}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data.data);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };
  const listLimit = [10, 50, 100];

  useEffect(() => {
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Paper className="pa-3 rounded-lg mt-3">
        <Grid container spacing={2} className="mb-5">
          <Grid item xs={6}>
            <div className="d-flex align-center">
              <h3>List Voucher</h3>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex align-center">
              <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
              <Menu
                menuClassName="my_menu"
                menuButton={
                  <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                    <p className="small_txt font-weight-bold mr-3">
                      {state.limit} Data
                    </p>
                    <ArrowDropDown />
                  </Card>
                }
                transition
              >
                {listLimit.map((lm, i) => (
                  <MenuItem
                    key={`limit-${i}`}
                    onClick={() => setState({ ...state, limit: lm })}
                  >
                    <p className="font-weight-bold txt_table">{lm} Data</p>
                  </MenuItem>
                ))}
              </Menu>
              <SearchDataTable value={find} setValue={setFind} />
            </div>
          </Grid>
        </Grid>
        {!loading ? (
          !dataSet.length ? (
            <EmptyData />
          ) : (
            <TableContainer component="div">
              <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{
                        minWidth: "50px",
                        background: theme.tertiary,
                        borderRadius: "10px 0 0 10px",
                      }}
                      className="py-2"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Tgl. Pembuatan Voucher
                      </p>
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "100px", background: theme.tertiary }}
                      className="py-1"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Kode Voucher
                      </p>
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "200px", background: theme.tertiary }}
                      className="py-1"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Nama Layanan
                      </p>
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "100px", background: theme.tertiary }}
                      className="py-1"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Jml. Voucher
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "150px",
                        background: theme.tertiary,
                        borderRadius: "0 10px 10px 0",
                      }}
                      className="py-1"
                      align="center"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Tindakan
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSet.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        {day(item.tgl_pembuatan).format("DD/MM/YY | HH:mm")}
                      </TableCell>
                      <TableCell className="text-center">
                        {item.kode ?? "-"}
                      </TableCell>
                      <TableCell>{item.nama ?? "-"}</TableCell>
                      <TableCell className="text-center">
                        {item.jumlah_voucher ?? "-"}
                      </TableCell>
                      <TableCell align="center" className="text-center">
                        <Button
                          variant="contained"
                          className="rounded-xl capitalize mr-3"
                          onClick={() =>
                            navigate(`/voucher/detail/${item.id_voucher}`)
                          }
                          disableElevation
                          size="small"
                          style={{ background: theme.act_blue }}
                        >
                          <p
                            className="font-weight-bold"
                            style={{ color: theme.act_lbl_blue }}
                          >
                            Detail
                          </p>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}
        {dataSet.length ? (
          <div
            className="d-flex align-center justify-space-between"
            style={{ width: "100%" }}
          >
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination
                sx={{ padding: "0" }}
                count={state.last_page}
                page={state.page}
                shape="rounded"
                onChange={handlePage}
                style={{ background: "#fff" }}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>

      <div className="pa-2"></div>
    </div>
  );
};

export default CompanyVoucher;
