/* eslint-disable eqeqeq */
import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material'
import { postApi } from '../../../store/apis'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux'
import Calendar from 'react-calendar';
import '../../../assets/css/reactCalendar.css';
import '../../../assets/css/reschedule.css';
export const Reschedule = ({ d_rscdl = false, close, refetch, selected, setDate, pocket }) => {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const [state, setState] = useState({
        loading: false,
        judul: '',
        deskripsi: ''
    })
    const [picked, setPick] = useState('')
    // const handleChange = (prop) => (event) => {
    //     setState({ ...state, [prop]: event.target.value })
    // }
    const jam = ['08:00', '10:00', '11:00', '13:00']

    const postData = () => {
        setState({ ...state, loading: true })
        let data = {
            path: 'konselor/tambah-catatan',
            body: {
                judul: state.judul,
                deskripsi: state.deskripsi
            }
        }
        let variant = ''

        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setState({ ...state, loading: false, judul: '', deskripsi: '' })
            close()
            refetch()
        }).catch(err => {
            setState({ ...state, loading: false })
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }

    return (
        <Dialog
            open={d_rscdl}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
            PaperProps={{
                style: { borderRadius: 8 }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <p className="font-weight-bold">Reschedule Konsultasi</p>
            </DialogTitle>
            <DialogContent>
                <div className='borbox' style={{ minWidth: '650px', padding: '5px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <p className="font-weight-bold txt_table">Tanggal Reschedule</p>
                            {selected && pocket ? <Calendar locale='id' onChange={setDate} value={selected} /> : <></>}
                        </Grid>
                        <Grid item xs={6}>
                            <p className="font-weight-bold txt_table mb-5">Jam Aktif</p>
                            <Grid container spacing={1}>
                                {jam.map((item, i) => (
                                    <Grid item xs={4} key={i}>
                                        <div className={picked == item ? 'pick' : 'unpick'} style={{ background: picked == item ? theme.primary : '' }} onClick={() => setPick(item)}>
                                            <p className='txt_table' style={{ color: picked == item ? '#fff' : theme.primary }}>{item} WIB</p>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                {!state.loading ?
                    <Button onClick={postData} variant='contained' autoFocus>
                        Add
                    </Button> :
                    <Button variant='contained' autoFocus>
                        Loading
                    </Button>}
            </DialogActions>
        </Dialog>
    )
}
