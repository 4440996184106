import { Skeleton, Grid, Paper, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getApi } from '../../../store/apis'
// import LinesEllipsis from 'react-lines-ellipsis'
import { Search, Add as AddIcon, } from '@mui/icons-material'
import Masonry from '@mui/lab/Masonry';
import { useState } from 'react'
import { useEffect } from 'react'
import { PostNotePsy } from './postNotePsy'
import { ViewNotePsy } from './viewNote'
import day from 'dayjs'
import { DialogDelete } from '../../base/dialogDelete'
export const AllNote = () => {

    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const [dataSet, setDataSet] = useState([])
    const [state, setState] = useState({
        loading: false,
        page: 1,
        limit: 20,
        find: '',
        lainya: false,
    })
    const [dialog, setDialog] = useState({
        post: false,
        view: false,
        del: false
    })
    const [inventory, setInventory] = useState(null)
    const viewNote = (item) => {
        setInventory(item)
        setDialog({ ...dialog, view: true })
    }
    const [find, setFind] = useState('')
    const fetchData = () => {
        setState({
            ...state, loading: true
        })
        let data = {
            path: `konselor/catatan?page[number]=${state.page}&page[size]=${state.limit}&filter[judul]=${find}`,
        }
        dispatch(getApi(data)).then(data => {
            setState({
                ...state, loading: false
            })
            setDataSet(data.data)
        }).catch(err => {
            setDataSet([])
            setState({
                ...state, loading: false
            })
        })
    }

    const deleteItem = item => {
        const data = {
            path: 'konselor/delete-catatan',
            body: {
                id: item.id
            }
        }
        setInventory(data)
        setDialog({ ...dialog, del: true })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [find])

    return (
        <div className='pa-3 rounded-lg mb-3' style={{ position: 'relative' }}>
            <div className="d-flex justify-space-between">
                <h3 style={{ color: theme.primary }}>Catatan</h3>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <div className="temp-search-keyup mb-3">
                        <input type="text" value={find} onChange={event => setFind(event.target.value)} className='search-keyup' placeholder='Cari catatan' style={{ background: theme.secondary }} />
                        <Search style={{ marginLeft: '-30px' }} color='primary' />
                    </div>
                </Grid>
            </Grid>

            {state.loading ?
                <Masonry columns={2} spacing={2}>
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={50} />
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={100} />
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={30} />
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={200} />
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={50} />
                </Masonry>
                :
                dataSet.length ?
                    <Masonry columns={3} spacing={2}>
                        {
                            dataSet.map((item, i) =>
                                <Paper key={i} className='pa-3  rounded-lg'>
                                    <p className="font-weight-bold mb-2 cursor_pointer" onClick={() => viewNote(item)}>{item.judul}</p>
                                    <p className="small_txt mr-3"> {day(item.created_at).format('DD/MM/YY')} </p>
                                    <p>{item.deskripsi}</p>
                                    <div className="d-flex align-center">
                                        <Button onClick={() => deleteItem(item)} color='error' variant='contained'>Delete</Button>
                                    </div>
                                </Paper>
                            )
                        }
                    </Masonry>

                    : <div>No data</div>}
            <div style={{ height: '35px' }}>
                <PostNotePsy d_cnote={dialog.post} close={() => setDialog({ ...dialog, post: false })} refetch={fetchData} />
                <ViewNotePsy d_vnote={dialog.view} close={() => setDialog({ ...dialog, view: false })} inventory={inventory} />
                <DialogDelete d_del={dialog.del} close={() => setDialog({ ...dialog, del: false })} refetch={fetchData} pocket={inventory} />
            </div>
            <button className='add-note' onClick={() => setDialog({ ...dialog, post: true })} style={{ background: theme.primary }}>
                <AddIcon color='white' ></AddIcon>
            </button>

        </div >
    )
}
