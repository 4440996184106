import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
  tooltipClasses,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";

const KonselingActivityList = ({ theme, dataSet, ava, page, limit }) => {
  const [dialog, setDialog] = useState({
    post: false,
    view: false,
  });
  const [pocket, setPocket] = useState(null);
  const [formateDate, setDate] = useState(null);
  const navigate = useNavigate();

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-2"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  No.
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "200px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Nama User
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "150px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Kategori
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "150px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Nama Layanan
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "150px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Nama Konselor
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "130px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Waktu Pembayaran
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "130px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Waktu Konsultasi
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Waktu
                  Reschedule
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => {
              console.log(item);
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{i + 1 + (page - 1) * limit}</TableCell>
                  <TableCell>
                    {/* <div className="d-flex align-center">
                                        <Avatar alt={item.nama} src={item.foto_link ? item.foto_link : ava} className="mr-3" />
                                    </div> */}
                    <p className=""> {item.nama_pelanggan} </p>
                  </TableCell>
                  <TableCell>{item.konsultasi_kategori}</TableCell>
                  <TableCell>{item.konsultasi_layanan}</TableCell>
                  <TableCell align="center">{item.nama_konselor}</TableCell>
                  <TableCell>{item.tgl_bayar}</TableCell>
                  <TableCell align="center">{item.tgl_konsultasi}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell align="center">{item.reschedule}</TableCell>
                  <TableCell>
                    <div className="d-flex">
                      <Button
                        variant="contained"
                        className="rounded-xl capitalize mr-3"
                        onClick={() =>
                          navigate(
                            `/konseling-activity/detail/${item.konsultasi_thread_id}`
                          )
                        }
                        disableElevation
                        size="small"
                        style={{ background: theme.act_blue }}
                      >
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_blue }}
                        >
                          Aktivitas
                        </p>
                      </Button>
                      <Button
                        variant="contained"
                        className="rounded-xl capitalize mr-3"
                        onClick={() =>
                          navigate(
                            `/konseling-activity/detail/${item.konsultasi_thread_id}`
                          )
                        }
                        disableElevation
                        size="small"
                        style={{ background: theme.act_blue }}
                      >
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_blue }}
                        >
                          Reschedule
                        </p>
                      </Button>
                      <Button
                        variant="contained"
                        className="rounded-xl capitalize mr-3"
                        onClick={() =>
                          navigate(
                            `/konseling-activity/detail/${item.konsultasi_thread_id}`
                          )
                        }
                        disableElevation
                        size="small"
                        style={{ background: theme.act_blue }}
                      >
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_blue }}
                        >
                          Cancel
                        </p>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2"></div>
    </div>
  );
};

export default KonselingActivityList;
