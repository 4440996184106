import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
export const ViewNotePsy = ({ d_vnote = false, close, inventory }) => {

    return (
        <Dialog
            open={d_vnote}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <p className='font-weight-bold'>{inventory?.judul}</p>
            </DialogTitle>
            <DialogContent >
                <DialogContentText id="alert-dialog-description" style={{ width: '400px' }} >
                    {inventory?.deskripsi}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Tutup</Button>
            </DialogActions>
        </Dialog>
    )
}
