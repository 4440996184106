import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material'
import { postApi } from '../../../store/apis'
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux'
export const PostNotePsy = ({ d_cnote = false, close, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const [state, setState] = useState({
        loading: false,
        judul: '',
        deskripsi: ''
    })
    const handleChange = (prop) => (event) => {
        setState({ ...state, [prop]: event.target.value })
    }

    const postData = () => {
        setState({ ...state, loading: true })
        let data = {
            path: 'konselor/tambah-catatan',
            body: {
                judul: state.judul,
                deskripsi: state.deskripsi
            }
        }
        let variant = ''

        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setState({ ...state, loading: false, judul: '', deskripsi: '' })
            close()
            refetch()
        }).catch(err => {
            setState({ ...state, loading: false })
            variant = 'error'
            if (err.message) {
                let msg = Object.values(err.message).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }

    return (
        <Dialog
            open={d_cnote}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Tambah Catatan"}
            </DialogTitle>
            <DialogContent >
                <div className='borbox' style={{ width: '400px', padding: '5px' }}>
                    <TextField label="Judul" className='mb-3' fullWidth value={state.judul} onChange={handleChange('judul')} variant="standard" />
                    <TextField label="Deskripsi" rows={4} multiline fullWidth value={state.deskripsi} onChange={handleChange('deskripsi')} variant="standard" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                {!state.loading ?
                    <Button onClick={postData} variant='contained' autoFocus>
                        Add
                    </Button> :
                    <Button variant='contained' autoFocus>
                        Loading
                    </Button>}
            </DialogActions>
        </Dialog>
    )
}
