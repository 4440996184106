/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getApi } from '../../../../../store/apis'
import { CHANGE_TITLE } from '../../../../../store/theming'
import InfoCoursePage from './InfoCoursePage'
import ListPesertaCourse from './listPesertaCourse'

const DetailCoursePage = () => {
    const theme = useSelector((state) => state.theme.myTheme);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tab, setTab] = useState(1)
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    const [emptyTxt, setEmptyTxt] = useState("");
    const [dataSet, setData] = useState(null);
    const fetchData = () => {
        setLoading(true);
        let data = {
            path: `admin/course/detail?id=${id}`,
        };
        dispatch(getApi(data))
            .then((data) => {
                setLoading(false);
                if (data) {
                    setData(data);
                }
            })
            .catch((err) => {
                if (err.status == 400) {
                    setEmptyTxt(`No Data Available`);
                } else {
                    setEmptyTxt(`${err.status}: ${err.data.message}`);
                }
                setLoading(false);
                setData(null);
            });
    };
    useEffect(() => {
        dispatch(CHANGE_TITLE('Detail Course'))
        fetchData()
    }, [])
    return (
        <div>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg  mb-3"
                onClick={() => navigate('/course-activity')}
            >
                <ChevronLeft /> Kembali
            </Button>
            <div className="d-flex mt-2"
            >
                <Button variant="contained" className='mr-3 rounded-lg'
                    color={tab == 1 ? 'primary' : 'white'}
                    disableElevation
                    onClick={() => setTab(1)}
                >
                    <p className="font-weight-bold capitalize" style={{ color: tab == 1 ? 'white' : theme.primary }}>
                        Informasi Course
                    </p>
                </Button>
                <Button variant="contained" className='mr-3 rounded-lg'
                    color={tab == 2 ? 'primary' : 'white'}
                    disableElevation
                    onClick={() => setTab(2)}
                >
                    <p className="font-weight-bold capitalize" style={{ color: tab == 2 ? 'white' : theme.primary }}>
                        List Peserta
                    </p>
                </Button>
            </div>
            <div className="mt-3">
                {tab == 1 ? <InfoCoursePage dataSet={dataSet} theme={theme} id={id} /> : <ListPesertaCourse id={id}></ListPesertaCourse>}
            </div>
        </div>
    )
}

export default DetailCoursePage
