import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { useSnackbar } from 'notistack';


import { doc, deleteDoc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";


export const DeleteMsg = ({ d_del, thread, close, msg, }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false
    })

    const deleteData = async () => {
        setState({ ...state, loading: true })
        let variant = ''
        await deleteDoc(doc(db, 'messages', `${thread.id}`, thread.code, msg._id))
        setState({ ...state, loading: false })
        variant = 'success'
        enqueueSnackbar('Pesan Terhapus', { variant });
        close()
    }

    return (
        <Dialog
            open={d_del}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            </DialogTitle>
            <DialogContent style={{ width: '300px' }}>
                <h3 className='text-center'>Hapus Pesan?</h3>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                {!state.loading ?
                    <Button onClick={deleteData} variant='contained' autoFocus>
                        delete
                    </Button> :
                    <Button variant='contained' autoFocus>
                        Loading
                    </Button>}
            </DialogActions>
        </Dialog>
    )
}
