// import { Card } from '@mui/material'
// import React from 'react'
// import MyBtn from '../../components/base/myBtn'
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { CHANGE_TITLE } from "../../store/theming"
import OverviewPsy from "./OverviewPsy"

const MainOverview = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(CHANGE_TITLE('Selamat Datang!'))
    }, [])
    const overvewPage = <OverviewPsy />
    return (
        <div>
            {overvewPage}
        </div>
    )
}

export default MainOverview
