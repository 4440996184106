/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid, Paper, Button } from "@mui/material";
import { Close, Event } from "@mui/icons-material";
import { useSelector } from "react-redux";
import day from "dayjs";

const ViewKonsultasi = ({ data, onReschedule, onCancel }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  return (
    <Paper
      className="pa-3 rounded-lg"
      style={{
        width: "75%",
      }}
      elevation={0}
    >
      <div className="d-flex justify-space-between mb-2">
        <div>
          <p
            className="small_txt font-weight-700"
            style={{ color: theme.primary }}
          >
            Nama User
          </p>
          <p className="small_txt">{data.nama_pelanggan ?? " - "}</p>
        </div>
        <div className="d-flex flex-row align-center">
          <Button
            onClick={() => onReschedule()}
            variant="contained"
            className="rounded-lg capitalize ml-3"
            disableElevation
            size="small"
            style={{
              background: theme.act_yellow,
              height: "max-content",
            }}
          >
            <Event className="mr-2" style={{ color: theme.act_lbl_yellow }} />
            <p
              className="font-weight-700"
              style={{ color: theme.act_lbl_yellow }}
            >
              Reschedule
            </p>
          </Button>
          <Button
            onClick={() => onCancel()}
            variant="contained"
            className="rounded-lg capitalize ml-3"
            disableElevation
            size="small"
            style={{
              background: theme.act_red,
              height: "max-content",
            }}
          >
            <Close className="mr-2" style={{ color: theme.act_lbl_red }} />
            <p className="font-weight-700" style={{ color: theme.act_lbl_red }}>
              Cancel
            </p>
          </Button>
        </div>
      </div>
      <div className="d-flex mb-2">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p
              className="small_txt font-weight-700"
              style={{ color: theme.primary }}
            >
              User ID
            </p>
            <p className="small_txt">{data.pelanggan_id ?? " - "}</p>
          </Grid>
          <Grid item xs={10}>
            <p
              className="small_txt font-weight-700"
              style={{ color: theme.primary }}
            >
              Email
            </p>
            <p className="small_txt">{data.email ?? " - "}</p>
          </Grid>
        </Grid>
      </div>
      <div
        className="d-flex mb-2 rounded-lg"
        style={{
          border: `1.5px solid ${theme.lighten}`,
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            right: "30%",
          }}
        >
          <div
            className="pa-2"
            style={{
              background: theme.act_lbl_blue,
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            }}
          >
            <p
              className="font-weight-700 small_txt"
              style={{ color: theme.white }}
            >
              Scheduled
            </p>
          </div>
        </Grid>
        <Grid className="pa-3" container spacing={2}>
          <Grid item xs={6}>
            <div className="mb-2">
              <p
                className="small_txt font-weight-700"
                style={{ color: theme.primary }}
              >
                Layanan
              </p>
              <p className="small_txt">{data.konsultasi_layanan ?? " - "}</p>
            </div>
            <div className="mb-2">
              <p
                className="small_txt font-weight-700"
                style={{ color: theme.primary }}
              >
                Topik Konsultasi
              </p>
              <p className="small_txt">{data.topik ?? " - "}</p>
            </div>
            <div className="mb-2">
              <p
                className="small_txt font-weight-700"
                style={{ color: theme.primary }}
              >
                Nama Konselor
              </p>
              <p className="small_txt">{data.nama_konselor ?? " - "}</p>
            </div>
            <div className="mb-2">
              <p
                className="small_txt font-weight-700"
                style={{ color: theme.primary }}
              >
                Waktu Pembayaran
              </p>
              <p className="small_txt">
                {day(data.tgl_bayar).locale("id").format("dddd, DD MMMM YYYY")}
                <br />
                {day(data.tgl_bayar).locale("id").format(`HH:mm WIB`)}
              </p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                marginTop: "40px",
              }}
            >
              {data.tgl_konsultasi && (
                <div
                  className="d-flex flex-row justify-space-between rounded-lg mb-2 pa-2"
                  style={{
                    background: theme.tertiary,
                    opacity: data.tgl_reschedule ? 0.7 : 1,
                  }}
                >
                  <div
                    style={{
                      flex: 0.7,
                    }}
                  >
                    <p
                      className="small_txt font-weight-700"
                      style={{ color: theme.primary }}
                    >
                      Waktu Konsultasi
                    </p>
                    <p className="small_txt">
                      {day(data.tgl_konsultasi)
                        .locale("id")
                        .format("dddd, DD MMMM YYYY")}
                      <br />
                      {day(data.tgl_konsultasi)
                        .locale("id")
                        .format(`HH:mm WIB`)}
                    </p>
                  </div>
                  <div
                    style={{
                      flex: 0.3,
                    }}
                  >
                    <p
                      className="small_txt font-weight-700"
                      style={{ color: theme.primary }}
                    >
                      Author
                    </p>
                    <p className="small_txt">User</p>
                  </div>
                </div>
              )}
              {data.tgl_reschedule && (
                <div
                  className="d-flex flex-row justify-space-between rounded-lg mb-2 pa-2"
                  style={{
                    background: theme.tertiary,
                  }}
                >
                  <div
                    style={{
                      flex: 0.7,
                    }}
                  >
                    <p
                      className="small_txt font-weight-700"
                      style={{ color: theme.primary }}
                    >
                      Waktu Reschedule
                    </p>
                    <p className="small_txt">
                      {day(data.tgl_reschedule)
                        .locale("id")
                        .format(`dddd, DD MMMM YYYY`)}
                      <br />
                      {day(data.tgl_reschedule)
                        .locale("id")
                        .format(`HH:mm WIB`)}
                    </p>
                  </div>
                  <div
                    style={{
                      flex: 0.3,
                    }}
                  >
                    <p
                      className="small_txt font-weight-700"
                      style={{ color: theme.primary }}
                    >
                      Author
                    </p>
                    <p className="small_txt">Psikolog</p>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default ViewKonsultasi;
