import React from 'react'
import { useSelector } from 'react-redux';
/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'

const BlankInput = ({
    value,
    property,
    handleChange,
    handleKeyup = () => { },
    idEl = (Math.random() + 1).toString(36).substring(7),
    placeholder = "",
    type = 'text'
}) => {
    const theme = useSelector((state) => state.theme.myTheme);
    const blockAllTxt = () => {
        let el = document.getElementById(idEl)
        if (el) {
            el.select()
        }
    }
    return (
        <div>
            <input
                placeholder={placeholder}
                onChange={handleChange(property)}
                onKeyUp={handleKeyup}
                onFocus={blockAllTxt}
                value={value}
                type={type}
                id={idEl}
                css={emot`
                    outline: none;
                    border-radius: 5px;
                    width: 100%;
                    padding: 5px;
                    border: none;
                    box-sizing: border-box;
                    flex: 1;
                    &:focus {
                        outline: 2px solid ${theme.tertiary};
                    }`}
            />
        </div>
    )
}

export default BlankInput
