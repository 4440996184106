/* eslint-disable react-hooks/exhaustive-deps */
import { Circle, Dashboard, Group, KeyboardArrowDown, KeyboardArrowUp, CalendarMonth, FormatListBulleted, Forum, ManageAccounts, Engineering, AccountBalanceWallet, Campaign } from '@mui/icons-material'
import { Collapse } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'


export const SidebarAdmin = ({ path = '/', theme }) => {
    const menuUser = ['/company-list', '/counselor-list', 'individual-user']
    const menuService = ['/psikotes-activity', '/psikotes-setting', 'konseling-activity', '/konseling-setting', '/training-activity', '/course-activity']
    const menuTrx = ['/poin-activity', '/poin-report', '/trx-activity', '/trx-report', '/voucher-activity', '/voucher-report']
    const menuAd = ['/popup-banner-activity', '/katalog', '/home-setting']
    const menuSchedule = ['/schedule-list', '/schedule-setting']
    const menuActivity = ['/activity-portfolio', '/activity-feed']
    const menuSetting = ['/setting-profile', '/setting-service']
    const [open, setOpen] = useState({
        user: false,
        service: false,
        jadwal: false,
        activity: false,
        setting: false,
        psikotes: false,
        konseling: false,
        training: false,
        trx: false,
        poin: false,
        trx_sub: false,
        vcr: false,
        ad: false,
        popup: false,
        katalog: false,
        home: false,
    })

    const navigate = useNavigate()
    const goTo = path => {
        navigate(path)
    }
    const handleOpener = type => {
        setOpen({
            ...open,
            [type]: !open[type],
        })
    }

    useEffect(() => {
        switch (true) {
            case menuUser.includes(path):
                handleOpener('user')
                break;
            case menuSchedule.includes(path):
                handleOpener('jadwal')
                break;
            case menuActivity.includes(path):
                handleOpener('activity')
                break;
            case menuSetting.includes(path):
                handleOpener('setting')
                break;
            default:
                break;
        }
    }, [])
    return (
        <div>
            {/* menu dashboard */}
            <div className={path === '/' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/')}>
                <Dashboard fontSize='small' color={path === '/' ? 'primary' : 'white'} className='mr-3' />
                <p className={path === '/' ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: path === '/' ? theme.primary : '#fff' }}>Dashboard</p>
            </div>

            {/* menu client */}
            <div className='expandable' style={{ background: menuUser.includes(path) || open.user ? theme.lighten : '' }} >
                <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener('user')}>
                    <div className="d-flex align-center">
                        <Group fontSize='small' color={menuUser.includes(path) || open.user ? 'primary' : 'white'} className='mr-3' />
                        <p className={menuUser.includes(path) || open.user ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: menuUser.includes(path) || open.user ? theme.primary : '#fff' }}>User Management</p>
                    </div>
                    {open.user ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='white' />}
                </div>

                <Collapse in={open.user} timeout="auto" unmountOnExit className='mt-3'>
                    <div className={path === '/company-list' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/company-list')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Company/Institution</p>
                    </div>
                    <div className={path === '/counselor-list' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/counselor-list')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Psychologist & Coach</p>
                    </div>
                    <div className={path === '/individual-user' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/individual-user')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Individual User</p>
                    </div>
                </Collapse>
            </div>

            {/* menu Service */}
            <div className='expandable' style={{ background: menuService.includes(path) || open.service ? theme.lighten : '' }}>
                <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener('service')}>
                    <div className="d-flex align-center">
                        <Engineering fontSize='small' color={menuService.includes(path) || open.service ? 'primary' : 'white'} className='mr-3' />
                        <p className={menuService.includes(path) || open.service ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: menuService.includes(path) || open.service ? theme.primary : '#fff' }}>Service Management</p>
                    </div>
                    {open.service ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color={menuService.includes(path) || open.service ? 'primary' : 'white'} />}
                </div>

                <Collapse in={open.service} timeout="auto" unmountOnExit className='mt-3'>
                    <div className="expandable_2">
                        <div className='item_unactive_2' onClick={() => handleOpener('psikotes')}>
                            <div className="d-flex align-center">
                                <Circle fontSize='small' color='primary' className='mr-1' />
                                <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Psikotes</p>
                            </div>
                            {open.psikotes ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='primary' />}
                        </div>
                        <Collapse in={open.psikotes} timeout="auto" unmountOnExit className='mt-3'>
                            <div className={path === '/psikotes-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/psikotes-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Psikotes Activity</p>
                            </div>
                            <div className={path === '/psikotes-setting' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/psikotes-setting')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Psikotes Setting</p>
                            </div>
                        </Collapse>
                    </div>

                    <div className="expandable_2">
                        <div className='item_unactive_2' onClick={() => handleOpener('konseling')}>
                            <div className="d-flex align-center">
                                <Circle fontSize='small' color='primary' className='mr-1' />
                                <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Konseling</p>
                            </div>
                            {open.konseling ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='primary' />}
                        </div>
                        <Collapse in={open.konseling} timeout="auto" unmountOnExit className='mt-3'>
                            <div className={path === '/konseling-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/konseling-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Konseling Activity</p>
                            </div>
                            <div className={path === '/konseling-setting' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/konseling-setting')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Konseling Setting</p>
                            </div>
                        </Collapse>
                    </div>

                    <div className="expandable_2">
                        <div className='item_unactive_2' onClick={() => handleOpener('training')}>
                            <div className="d-flex align-center">
                                <Circle fontSize='small' color='primary' className='mr-1' />
                                <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Training & Course</p>
                            </div>
                            {open.training ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='primary' />}
                        </div>
                        <Collapse in={open.training} timeout="auto" unmountOnExit className='mt-3'>
                            <div className={path === '/training-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/training-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Training Activity</p>
                            </div>
                            <div className={path === '/course-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/course-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Course Activity</p>
                            </div>
                        </Collapse>
                    </div>
                </Collapse>
            </div>

            {/* menu Transaction */}
            <div className='expandable' style={{ background: menuTrx.includes(path) || open.trx ? theme.lighten : '' }}>
                <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener('trx')}>
                    <div className="d-flex align-center">
                        <AccountBalanceWallet fontSize='small' color={menuTrx.includes(path) || open.trx ? 'primary' : 'white'} className='mr-3' />
                        <p className={menuTrx.includes(path) || open.trx ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: menuTrx.includes(path) || open.trx ? theme.primary : '#fff' }}>Transaction</p>
                    </div>
                    {open.trx ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color={menuTrx.includes(path) || open.trx ? 'primary' : 'white'} />}
                </div>

                <Collapse in={open.trx} timeout="auto" unmountOnExit className='mt-3'>
                    <div className="expandable_2">
                        <div className='item_unactive_2' onClick={() => handleOpener('poin')}>
                            <div className="d-flex align-center">
                                <Circle fontSize='small' color='primary' className='mr-1' />
                                <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Poin</p>
                            </div>
                            {open.poin ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='primary' />}
                        </div>
                        <Collapse in={open.poin} timeout="auto" unmountOnExit className='mt-3'>
                            <div className={path === '/poin-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/poin-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Poin Activity</p>
                            </div>
                            <div className={path === '/poin-report' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/poin-report')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Poin Report</p>
                            </div>
                        </Collapse>
                    </div>
                    <div className="expandable_2">
                        <div className='item_unactive_2' onClick={() => handleOpener('trx_sub')}>
                            <div className="d-flex align-center">
                                <Circle fontSize='small' color='primary' className='mr-1' />
                                <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Transaksi</p>
                            </div>
                            {open.trx_sub ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='primary' />}
                        </div>
                        <Collapse in={open.trx_sub} timeout="auto" unmountOnExit className='mt-3'>
                            <div className={path === '/transaksi-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/transaksi-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Transaksi Activity</p>
                            </div>
                            <div className={path === '/transaksi-report' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/transaksi-report')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Transaksi Report</p>
                            </div>
                        </Collapse>
                    </div>
                    <div className="expandable_2">
                        <div className='item_unactive_2' onClick={() => handleOpener('vcr')}>
                            <div className="d-flex align-center">
                                <Circle fontSize='small' color='primary' className='mr-1' />
                                <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Voucher</p>
                            </div>
                            {open.vcr ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='primary' />}
                        </div>
                        <Collapse in={open.vcr} timeout="auto" unmountOnExit className='mt-3'>
                            <div className={path === '/voucher-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/voucher-activity')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Voucher Activity</p>
                            </div>
                            <div className={path === '/voucher-report' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/voucher-report')}>
                                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                                <p className='font-weight-bold txt_menu ml-3' style={{ color: theme.primary }}>Voucher Report</p>
                            </div>
                        </Collapse>
                    </div>
                </Collapse>
            </div>

            {/* menu Advertising */}
            <div className='expandable' style={{ background: menuAd.includes(path) || open.ad ? theme.lighten : '' }}>
                <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener('ad')}>
                    <div className="d-flex align-center">
                        <Campaign fontSize='small' color={menuAd.includes(path) || open.ad ? 'primary' : 'white'} className='mr-3' />
                        <p className={menuAd.includes(path) || open.ad ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: menuAd.includes(path) || open.ad ? theme.primary : '#fff' }}>Advertising</p>
                    </div>
                    {open.ad ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color={menuAd.includes(path) || open.ad ? 'primary' : 'white'} />}
                </div>

                <Collapse in={open.ad} timeout="auto" unmountOnExit className='mt-3'>
                    <div className={path === '/popup-banner-activity' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/popup-banner-activity')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Pop Up & Banner</p>
                    </div>
                    <div className={path === '/katalog' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/katalog')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Katalog</p>
                    </div>
                    <div className={path === '/home-setting' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/home-setting')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Home Aplikasi</p>
                    </div>
                </Collapse>
            </div>

            {/* menu jadwal */}
            <div className='expandable' style={{ background: menuSchedule.includes(path) || open.jadwal ? theme.lighten : '' }} >
                <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener('jadwal')}>
                    <div className="d-flex align-center">
                        <CalendarMonth fontSize='small' color={menuSchedule.includes(path) || open.jadwal ? 'primary' : 'white'} className='mr-3' />
                        <p className={menuSchedule.includes(path) || open.jadwal ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: menuSchedule.includes(path) || open.jadwal ? theme.primary : '#fff' }}>Jadwal</p>
                    </div>
                    {open.jadwal ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='white' />}
                </div>

                <Collapse in={open.jadwal} timeout="auto" unmountOnExit className='mt-3'>
                    <div className={path === '/schedule-list' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/schedule-list')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Jadwal Konsultasi</p>
                    </div>
                    <div className={path === '/schedule-setting' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/schedule-setting')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Setting Jadwal Konsultasi</p>
                    </div>
                </Collapse>
            </div>


            {/* menu pengaturan akun */}
            <div className='expandable' style={{ background: menuSetting.includes(path) || open.setting ? theme.lighten : '' }}>
                <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener('setting')}>
                    <div className="d-flex align-center">
                        <ManageAccounts fontSize='small' color={menuSetting.includes(path) || open.setting ? 'primary' : 'white'} className='mr-3' />
                        <p className={menuSetting.includes(path) || open.setting ? 'font-weight-bold txt_menu' : 'txt_menu'} style={{ color: menuSetting.includes(path) || open.setting ? theme.primary : '#fff' }}>Pengaturan Akun</p>
                    </div>
                    {open.setting ? <KeyboardArrowUp fontSize='small' color='primary' /> : <KeyboardArrowDown fontSize='small' color='white' />}
                </div>

                <Collapse in={open.setting} timeout="auto" unmountOnExit className='mt-3'>
                    <div className={path === '/setting-profile' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/setting-profile')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Profile</p>
                    </div>
                    <div className={path === '/setting-service' ? 'item_active' : 'item_unactive'} onClick={() => goTo('/setting-service')}>
                        <Circle fontSize='small' color='primary' className='mr-1' />
                        <p className='font-weight-bold txt_menu' style={{ color: theme.primary }}>Setting Layanan</p>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
