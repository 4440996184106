/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatePickerSchedule from '../../schedule/datePickerSchedule'
import { useSnackbar } from "notistack";
import Select from 'react-select';
import { getApi, postApi } from '../../../../store/apis';
import { useEffect } from 'react';

const CreateJadwal = ({ dialog = false, close, refetch, }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const { enqueueSnackbar } = useSnackbar();

    const [selectedHour, setSelectedHour] = useState([])
    const [selectedDate, setSelectDate] = useState([])
    const availHour = ['08.00', '09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00', '20.00']
    const pickHour = (h) => {
        if (selectedHour.includes(h)) {
            let spliced = [...selectedHour]
            let index = spliced.indexOf(h)
            if (index > -1) {
                spliced.splice(index, 1)
            }
            setSelectedHour(spliced)
        } else {
            setSelectedHour([...selectedHour, h])
        }
    }
    const [state, setState] = useState({
        limit: 50,
        page: 1
    })
    const [loading, setLoading] = useState(false)
    const [dataSet, setData] = useState([])
    const dispatch = useDispatch()
    const [konselor, setKonselor] = useState(null)
    const [find, setFind] = useState('')
    const getKonselor = () => {
        setLoading(true);
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
        let data = {
            path: `admin/psikolog-coach?filter[nama]=${find}${page}`,
        };
        dispatch(getApi(data))
            .then((data) => {
                console.log(data.data)
                let res = data.data
                let raw = []
                if (res.length) {
                    res.forEach(el => {
                        raw.push({
                            value: el.id,
                            label: el.nama ?? '',
                        })
                    })
                    setLoading(false);
                    setData(raw);
                    setState({ ...state, total: data.total, last_page: data.last_page });
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
                setData([]);
            });
    }
    const saveSchedule = () => {
        setLoading(true);
        let body = {
            konselor_id: konselor.value,
            waktu: JSON.stringify(selectedHour),
            tanggal: JSON.stringify(selectedDate)
        }
        let data = {
            path: 'admin/konseling-setting/jadwal-konsultasi/create',
            body: body
        }
        dispatch(postApi(data)).then(res => {
            let variant = 'success'
            enqueueSnackbar(res.message ?? "Jadwal telah di tambahkan", {
                variant,
            });
            refetch()
            close()
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);

        })


    }

    useEffect(() => {
        getKonselor()
    }, [])
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: state.isFocused ? '' : '#FBF4FE',
            borderRadius: '5px',
            border: 'none',
            outline: state.isFocused ? `2px solid ${theme.tertiary}` : '',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            padding: '3px',
        }),
        input: (provided, state) => ({
            ...provided,
            outline: state.isFocused ? '2px solid #FBF4FE' : 'none',
        }),
        multiValue: (provided) => ({
            ...provided,
            background: theme.tertiary
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: '5px',
            background: state.isSelected ? '#FBF4FE' : '',
            color: state.isSelected ? '#000' : '',
            ':hover': {
                background: '#FBF4FE',
            },
        }),
    }
    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
            PaperProps={{
                style: { borderRadius: 8 }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <p className="font-weight-bold">Tambah Jadwal</p>
            </DialogTitle>
            <DialogContent>
                <div className='borbox' style={{ minWidth: '650px', padding: '5px' }}>
                    <Card className='pa-3 rounded-lg' >
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <div>
                                    <p className='font-weight-bold'>Pilih Konselor</p>
                                    <p className='small_txt'>Pilih satu konselor yang ingin ditambahkan jadwal aktifnya.</p>
                                </div>
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    styles={customStyles}
                                    classNamePrefix="select"
                                    name="color"
                                    options={dataSet}
                                    defaultValue={konselor}
                                    onChange={(e) => setKonselor(e)}
                                />
                            </Grid>
                        </Grid>
                        <div className="py-3">
                            <Divider />
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div className="pr-2">
                                    <div style={{ minHeight: '90px' }}>
                                        <p className='font-weight-bold'>Jam Aktif Tersedia</p>
                                        <p className='small_txt mb-3'>Jam aktif bisa dipilih lebih dari satu, dan berlaku
                                            untuk semua layanan aktif.</p>
                                    </div>
                                    <Grid container spacing={1}>
                                        {availHour.map((item, i) => (
                                            <Grid item xs={4} key={i}>
                                                <Button className='capitalize' style={{ width: '100%' }} onClick={() => pickHour(item)} variant={selectedHour.includes(item) ? 'contained' : 'outlined'} >
                                                    {item}
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ minHeight: '90px' }}>
                                    <p className='font-weight-bold'>Tanggal Aktif Tersedia</p>
                                    <p className='small_txt mb-3'>Tanggal aktif bisa dipilih lebih dari satu, dan berlaku
                                        untuk semua jam dan layanan aktif.</p>
                                </div>
                                <DatePickerSchedule picked={selectedDate} setPicked={setSelectDate} />
                            </Grid>
                        </Grid>
                    </Card>

                </div>
            </DialogContent>
            <DialogActions>
                {!loading ?
                    <Button variant='contained' className='capitalize' onClick={saveSchedule}>Simpan</Button> :
                    <Button variant='contained' className='capitalize'>Loading...</Button>
                }

                <Button onClick={close}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateJadwal
